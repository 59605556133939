import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { User } from "@thrive-web/ui-api";
import { ensure_id_is_iri } from "@thrive-web/ui-common";
import {
  DefaultErrorView,
  DefaultPendingView,
  LocalProvider,
  PageBody,
  PageContent,
  passPropsToLocalContext,
} from "@thrive-web/ui-components";
import { useApiRequest, useRecordForDetailPage } from "@thrive-web/ui-hooks";
import {
  CommunityMemberDetailAboutPage,
  CommunityMemberDetailConnectionsPage,
  CommunityMemberDetailGroupsPage,
  CommunityMemberDetailHeader,
  MEMBER_DETAIL_CONTEXTS,
} from "~/view/components";

export const CommunityMemberDetail: Preact.FunctionComponent<{
  user: User;
}> = ({ user }) => {
  passPropsToLocalContext("user", user, MEMBER_DETAIL_CONTEXTS.dispatch);

  return (
    <Preact.Fragment>
      <CommunityMemberDetailAboutPage path="/people/:id" />
      <CommunityMemberDetailGroupsPage path="/people/:id/groups" />
      <CommunityMemberDetailConnectionsPage path="/people/:id/connections/:action?" />
    </Preact.Fragment>
  );
};

const params = {
  query: {
    include: ["profile_picture"],
  },
} as const;
export const CommunityMemberDetailPage: Preact.FunctionComponent<RoutePageProps> =
  ({ matches = {} }) => {
    const id = useMemo(
      () => ensure_id_is_iri(matches.id || "", "User"),
      [matches.id]
    );

    const [get_user, { error, pending }] = useApiRequest("getUser", id, params);
    const [user, fetch] = useRecordForDetailPage(id, get_user);

    const initial_ctx = useMemo(() => ({ user, fetch }), [user, fetch]);

    if (!user) {
      return null;
    }

    if (error) {
      return <DefaultErrorView error={error} />;
    }

    return (
      <PageContent
        id="member-detail-page"
        className={`user-page detail-page${matches.tab ? "" : " has-sidebar"}`}
      >
        <LocalProvider
          contexts={MEMBER_DETAIL_CONTEXTS}
          initialValues={initial_ctx}
        >
          <CommunityMemberDetailHeader />
          <PageBody>
            {pending || !user ? (
              <DefaultPendingView />
            ) : (
              <CommunityMemberDetail user={user} />
            )}
          </PageBody>
        </LocalProvider>
      </PageContent>
    );
  };
