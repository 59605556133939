import { ComplexKeysOf } from "@thrive-web/core";
import { Group, Post } from "@thrive-web/ui-api";
import {
  ACTIVITY_FEED_POST_ID,
  ActivityFeed,
  asSubroute,
  GROUP_DETAIL_LIST_CONTEXTS,
  GROUP_GROUP,
  PostListLoading,
  useRenderDynamicListWithPagedFetch,
} from "@thrive-web/ui-components";
import { useApiFetchPaged, useDocumentTitle } from "@thrive-web/ui-hooks";
import { get_url_for_entity, make_title } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";

const POST_DETAIL_PATH = "/groups/:id/activity/:post";

const PostIdProvider = ACTIVITY_FEED_POST_ID.Provider;

const can_never_interact = () => false;
export const CommunityGroupDetailActivity: Preact.FunctionComponent<
  RoutePageProps & { post_id?: string }
> = ({ post_id }) => {
  const group = useContext(GROUP_GROUP) || ({} as Group);
  useDocumentTitle(
    () => make_title(group?.name ? ["Activity", group.name] : ["Groups"]),
    [group?.name]
  );

  const req_params = useMemo(
    () => ({
      query: {
        include: [
          "has_touchpoint",
          "has_reaction",
          "has_expense",
          "mood",
          "photo",
          "event.Event:cover_image",
          "created_by.User:profile_picture",
          "has_tagged_user.User:profile_picture",
          "has_comment.Comment:created_by.User:profile_picture",
        ] as ComplexKeysOf<Post>[],
        filter: [
          ["=", ["this", "Post:posted_to"], ["id", group.id as string]],
        ] as const,
        sort: [{ by: "created_at" as const, dir: "desc" as const }],
      },
    }),
    [group?.id]
  );
  const getPosts = useApiFetchPaged("getPosts", req_params);

  const detailExitPath = useMemo(
    () => `${get_url_for_entity(group)}/activity`,
    [group]
  );
  const { list, dispatch } = useContext(GROUP_DETAIL_LIST_CONTEXTS.posts);
  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more) => (
      <ActivityFeed
        posts={result}
        detailPath={POST_DETAIL_PATH}
        detailExitPath={detailExitPath}
        dynamicListCtx={GROUP_DETAIL_LIST_CONTEXTS.posts}
        loadMoreElem={load_more}
        canInteract={can_never_interact}
      />
    ),
    [group],
    getPosts,
    undefined,
    { PendingView: PostListLoading }
  );

  if (!group || !group.id) {
    return null;
  }

  return (
    <div className="group-activity__content">
      <PostIdProvider value={post_id}>{content}</PostIdProvider>
    </div>
  );
};

export const CommunityGroupDetailActivityPage = asSubroute(
  CommunityGroupDetailActivity
);
