import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { Community, User } from "@thrive-web/ui-api";
import {
  Card,
  EmptyList,
  LazyListSection,
  PageBody,
  PageContent,
  PageHeader,
  SearchBar,
  useLazyList,
  useRenderDynamicListWithPagedFetch,
} from "@thrive-web/ui-components";
import {
  useAppUser,
  useDynamicListVariable,
  useStateIfMounted,
  useStateRef,
} from "@thrive-web/ui-hooks";
import { get_user_community_role } from "~/utils";
import {
  ACTIVE_COMMUNITY,
  useMemberSearch,
  MemberListLoading,
  useMemberRemove,
  UserMemberListItem,
  CommunityMemberAdd,
  get_role_label,
  SET_ACTIVE_COMMUNITY,
  useMemberRoleUpdate,
} from "~/view/components";

export const CommunityMembersList: Preact.FunctionComponent<{
  community: Community;
  users: readonly User[];
  loadMoreElem?: Preact.VNode | null;
  onClickRemove: (user: User) => void;
  onClickChangeRole: (user: User) => void;
  search?: string;
}> = ({
  community,
  users,
  loadMoreElem,
  onClickRemove,
  onClickChangeRole,
  search,
}) => {
  const content = useLazyList(
    users,
    user => (
      <UserMemberListItem
        user={user}
        role={get_role_label(get_user_community_role(user, community))}
        onClickRemove={onClickRemove}
        onClickChangeRole={onClickChangeRole}
      />
    ),
    [community, onClickRemove, onClickChangeRole],
    20
  );

  const displayed_search = useRef<string | undefined>(search);
  const prev_results = useRef(users);
  useLayoutEffect(() => {
    if (prev_results.current !== users) {
      displayed_search.current = search;
    }
  }, [users]);
  useLayoutEffect(() => {
    if (displayed_search.current !== search) {
      prev_results.current = users;
    }
  }, [search]);

  if (!users.length) {
    return (
      <EmptyList className="member-list__empty">
        No Members in this community
        {displayed_search.current ? (
          <Preact.Fragment>
            {" "}
            with the name <strong>"{displayed_search.current}"</strong>
          </Preact.Fragment>
        ) : (
          ""
        )}
        . Add a Member by clicking Add Member.
      </EmptyList>
    );
  }

  return (
    <Card className="member-list community__member-list user-list">
      {content.map((s, i) => (
        <LazyListSection key={i}>{s}</LazyListSection>
      ))}
      {loadMoreElem && <div className="load-more">{loadMoreElem}</div>}
    </Card>
  );
};

export const CommunityMembers: Preact.FunctionComponent<RoutePageProps> =
  () => {
    const self = useAppUser();
    const comm = useContext(ACTIVE_COMMUNITY);
    const set_active_comm = useContext(SET_ACTIVE_COMMUNITY);
    const [search, set_search] = useStateIfMounted("");
    const [list, dispatch] = useDynamicListVariable<User>(null);
    const [fetchMembers, force_refresh] = useMemberSearch(comm?.id, search);
    const [pending, set_pending, pending_ref] = useStateRef(false);

    const [delete_target, set_delete_target] = useStateIfMounted<User | null>(
      null
    );
    const onRemove = useCallback(
      id => dispatch.remove(m => m.id === id),
      [dispatch]
    );
    const [onClickRemove, removeMemberModal] = useMemberRemove(
      comm,
      delete_target,
      set_delete_target,
      onRemove
    );

    const [role_target, set_role_target] = useStateIfMounted<User | null>(null);
    const [onClickChangeRole, changeRoleModal] = useMemberRoleUpdate(
      comm,
      role_target,
      set_role_target,
      set_active_comm
    );
    const is_admin = useMemo(
      () =>
        self && comm
          ? get_user_community_role(self, comm) === "has_admin"
          : false,
      [self?.id, comm]
    );

    const passthrough_props = useMemo(
      () => ({
        community: comm,
        onClickRemove,
        onClickChangeRole: is_admin ? onClickChangeRole : undefined,
        search,
      }),
      [onClickRemove, onClickChangeRole, is_admin, comm, search]
    );
    const content = useRenderDynamicListWithPagedFetch(
      list,
      dispatch,
      (result, load_more_elem, pending_, passthrough) => {
        if (pending_ !== undefined && pending_ !== pending_ref.current) {
          set_pending(pending_);
        }
        return (
          // @ts-expect-error:
          <CommunityMembersList
            users={result}
            loadMoreElem={load_more_elem}
            {...passthrough}
          />
        );
      },
      [],
      fetchMembers,
      passthrough_props,
      { PendingView: MemberListLoading, limit: 20 }
    );

    return (
      <PageContent
        id="community-members"
        data-page="community-members-list"
        className="list-page community-page no-separate-header"
      >
        <PageHeader
          title={<h1>Members</h1>}
          button={<CommunityMemberAdd afterAdd={force_refresh} />}
        >
          <SearchBar
            placeholder="Search"
            onSubmit={set_search}
            value={search}
            pending={pending}
          />
        </PageHeader>
        <PageBody>
          {content}
          {removeMemberModal}
          {is_admin && changeRoleModal}
        </PageBody>
      </PageContent>
    );
  };
