import { User } from "@thrive-web/ui-api";
import { useAppUser } from "@thrive-web/ui-hooks";
import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import { get_user_community_role } from "~/utils";
import { ACTIVE_COMMUNITY, get_role_label } from "~/view/components";

export const RoleButton: Preact.FunctionComponent<{
  user: User;
  onClick: (user: User) => void;
}> = ({ user, onClick }) => {
  const comm = useContext(ACTIVE_COMMUNITY);
  const self = useAppUser();
  const self_role = useMemo(
    () => (self && comm ? get_user_community_role(self, comm) : undefined),
    [self?.id, comm]
  );
  const role = useMemo(
    () =>
      comm ? get_role_label(get_user_community_role(user, comm)) : undefined,
    [user.id, comm]
  );

  if (!self || !comm) {
    return null;
  }

  if (self_role !== "has_admin") {
    return (
      <div className="button filled gray static member-profile__role">
        <span>{role}</span>
        <span />
      </div>
    );
  }

  return (
    <button
      className="filled gray member-profile__role"
      onClick={() => onClick(user)}
    >
      <span>{role}</span>
      <span className="plain-link">Edit</span>
    </button>
  );
};
