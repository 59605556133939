import { FilterSpec } from "@thrive-web/core";
import { Community, User } from "@thrive-web/ui-api";
import { ScopeRole } from "~/view/utils";

const ADMIN_UI_STORAGE_KEY = "admin_ui_storage";
let stored_data: any = localStorage.getItem(ADMIN_UI_STORAGE_KEY);
try {
  stored_data = JSON.parse(stored_data || "");
} catch (e) {
  stored_data = {};
}
export const store_current_community_id = (user_id: string, id: string) => {
  if (!stored_data[user_id]) {
    stored_data[user_id] = {};
  }
  stored_data[user_id].last_community = id;
  localStorage.setItem(ADMIN_UI_STORAGE_KEY, JSON.stringify(stored_data));
};
export const get_stored_community_id = (user_id: string) => {
  return stored_data?.[user_id]?.last_community as string | undefined;
};

export const user_is_community_admin = (user: User, comm: Community) =>
  !!comm.has_admin?.find(u => u.id === user.id) ||
  !!comm.has_manager?.find(u => u.id === user.id);

export const get_user_community_role = (
  user: User,
  comm: Community
): ScopeRole<Community> =>
  !!comm.has_admin?.find(u => u.id === user.id)
    ? "has_admin"
    : !!comm.has_manager?.find(u => u.id === user.id)
    ? "has_manager"
    : !!comm.has_moderator?.find(u => u.id === user.id)
    ? "has_moderator"
    : "has_member";

export const mngd_communities_filter = (
  user: User | null,
  search?: string
): FilterSpec => {
  let filter: FilterSpec = [
    [
      "or",
      // ["=", ["this", "Community:has_moderator"], ["id", user!.id as string]],
      ["=", ["this", "Community:has_manager"], ["id", user!.id as string]],
      ["=", ["this", "Community:has_admin"], ["id", user!.id as string]],
    ],
  ];
  if (search) {
    filter = filter.concat([["match", ["this", "Group:name"], search, "i"]]);
  }
  return filter;
};
