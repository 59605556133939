import { Community } from "@thrive-web/ui-api";
import { pick } from "@thrive-web/ui-common";
import { ButtonWithIcon, DefaultModalContent } from "@thrive-web/ui-components";
import { useApiMethod, useAppUser, useModal } from "@thrive-web/ui-hooks";
import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { get_user_community_role } from "~/utils";
import {
  ACTIVE_COMMUNITY,
  CommMemberForm,
  MEMBER_DETAIL_CONTEXTS,
  MemberCreateFormData,
  SET_ACTIVE_COMMUNITY,
} from "~/view/components";
import { ScopeRole } from "~/view/utils";

export const CommunityMemberDetailEditModal: Preact.FunctionComponent<ModalBodyProps> =
  ({ closeButton, dismiss }) => {
    const self = useAppUser();
    const comm = useContext(ACTIVE_COMMUNITY);
    const c_dispatch = useContext(SET_ACTIVE_COMMUNITY);
    const user = useContext(MEMBER_DETAIL_CONTEXTS.user);
    const dispatch = useContext(MEMBER_DETAIL_CONTEXTS.dispatch);

    const is_admin = useMemo(
      () =>
        self && comm
          ? get_user_community_role(self, comm) === "has_admin"
          : false,
      [self?.id, comm]
    );
    const cur_role = useMemo(
      () => (user && comm ? get_user_community_role(user, comm) : "has_member"),
      [user?.id, comm]
    );

    const update_user = useApiMethod("updateUser");
    const update_role = useApiMethod("updateCommunity");
    const update_user_and_role = useCallback(
      (form_data: MemberCreateFormData) => {
        if (!comm || !user) {
          return Promise.reject("No active community or user");
        }

        const { role = "has_member", ...attributes } = form_data;

        return update_user(user.id, { body: { data: { attributes } } })
          .then(({ data }) => {
            if (!data) {
              return Promise.reject({
                message: "No data returned from user update request.",
              });
            }
            dispatch("user", data);
            if (role !== cur_role) {
              const rels: any = {};
              if (cur_role !== "has_member") {
                rels[cur_role] = {
                  data: comm[cur_role]?.filter(u => u.id !== user.id),
                };
              }
              if (role !== "has_member") {
                rels[role] = {
                  data: comm[role as ScopeRole<Community>]?.concat({
                    id: user.id,
                  }),
                };
              }
              return update_role(comm.id, {
                body: { data: { attributes: {}, relationships: rels } },
              }).then(({ data: new_comm }) => {
                c_dispatch(new_comm);
                return data;
              });
            }
            return data;
          })
          .then(data => {
            setTimeout(() => {
              dismiss();
            }, 1200);
            return data;
          });
      },
      [comm?.id, user?.id, dispatch]
    );

    const initial_data = useMemo(
      () => ({
        role: cur_role,
        ...(user ? pick(["first_name", "last_name", "email"], user) : {}),
      }),
      [user]
    );

    if (!comm || !user) {
      return null;
    }

    return (
      <DefaultModalContent
        title="Edit Unregistered User Info"
        closeButton={closeButton}
      >
        <CommMemberForm
          canChangeRole={is_admin}
          initialData={initial_data}
          onSubmit={update_user_and_role}
        />
      </DefaultModalContent>
    );
  };

export const CommunityMemberDetailEdit: Preact.FunctionComponent = () => {
  const [modal, set_open] = useModal({
    body: CommunityMemberDetailEditModal,
    id: "member-edit",
    className: "member-add__modal modal-form",
    showCloseButton: true,
  });

  return (
    <ButtonWithIcon
      icon="edit"
      side="left"
      className="filled pill gray"
      onClick={() => set_open(true)}
    >
      Edit Unregistered User Info
      {modal}
    </ButtonWithIcon>
  );
};
