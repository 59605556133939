import { ApiMethodParameters } from "@thrive-web/core";
import { moment } from "@thrive-web/ui-common";

// get count of touchpoints posted to groups inside this community
export const community_touchpoint_count_query = (
  id: string
): ApiMethodParameters<"GET", "Touchpoint"> => ({
  query: {
    filter: [
      [
        "=",
        [
          "this",
          ["/", "Touchpoint:posted_to", "Post:posted_to", "Group:in_community"],
        ],
        ["id", id],
      ],
    ],
    limit: 1,
    include_count: true,
  },
});

// get count of users that are members of this community and have created a touchpoint
// in the last 30 days
export const community_engagement_query = (
  id: string
): ApiMethodParameters<"GET", "User"> => {
  const month_ago = moment(Date.now() - 1000 * 60 * 60 * 24 * 30).toISOString();
  return {
    query: {
      filter: [
        ["=", ["this", ["^", "Community:has_member"]], ["id", id]],
        [
          ">=",
          [
            "this",
            ["/", ["^", "Touchpoint:created_by"], "Touchpoint:created_at"],
          ],
          month_ago,
        ],
      ],
      limit: 1,
      include_count: true,
    },
  };
};
