import {
  ApiMethod,
  ApiMethodParameters,
  Community,
  Group,
  User,
} from "@thrive-web/ui-api";
import { entity_has_type } from "@thrive-web/ui-common";
import { cache_record } from "@thrive-web/ui-utils";
import { route } from "preact-router";
import { useContext } from "preact/hooks";
import * as hooks from "preact/hooks";
import {
  useApiFetch,
  useApiMethod,
  useAppUser,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { AppUser } from "@thrive-web/ui-model";
import {
  get_stored_community_id,
  get_user_community_role,
  mngd_communities_filter,
  store_current_community_id,
  user_is_community_admin,
} from "~/utils";
import { ACTIVE_COMMUNITY } from "~/view/components";

export type ScopeRole<T extends Community | Group> =
  | "has_member"
  | (T extends Community
      ? "has_admin" | "has_manager" | "has_moderator"
      : "has_admin");

export const useCommunitySelector = (user?: AppUser | null) => {
  const initial_active = hooks.useMemo(
    () => (user?.id ? get_stored_community_id(user.id) : undefined),
    [user?.id]
  );
  const [active_community_id, set_active_community_id] = useStateIfMounted<
    string | undefined
  >(initial_active);
  const [active_community, set_active_community_] =
    useStateIfMounted<Community | null>(null);

  hooks.useEffect(() => {
    set_active_community_id(initial_active);
  }, [initial_active]);

  const fetchActiveCommunity = useApiMethod("getCommunity");
  const fetchDefault = useApiMethod("getCommunities");
  hooks.useEffect(() => {
    if (!active_community_id || !user) {
      return;
    }
    fetchActiveCommunity(active_community_id)
      .then(({ data }) => {
        if (user_is_community_admin(user, data)) {
          return data;
        }
        return fetchDefault({
          query: {
            filter: mngd_communities_filter(user),
            limit: 1,
          },
        }).then(({ data: list }) => {
          if (list.length === 0) {
            route("/logout");
            return Promise.reject(
              "No managed communities found, logging user out..."
            );
          }
          return list[0];
        });
      })
      .then(comm => {
        cache_record(comm, true);
        set_active_community_(comm);
      });
  }, [user?.id, active_community_id]);

  const set_active_community = hooks.useCallback(
    (community: Community | null, fetch: boolean = true) => {
      if (!user?.id || !community) {
        return Promise.reject();
      }
      return (
        fetch
          ? fetchActiveCommunity(community.id)
          : Promise.resolve({ data: community })
      ).then(({ data }) => {
        set_active_community_(data);
        set_active_community_id(data?.id);
        store_current_community_id(user.id, data.id);
        cache_record(data, true);
      });
    },
    [
      fetchActiveCommunity,
      set_active_community_,
      set_active_community_id,
      user?.id,
    ]
  );

  return [active_community, set_active_community] as const;
};

export const useMemberRoleUpdateRequest = <
  T extends Community | Group,
  M extends ApiMethod
>(
  scope: T | null,
  user: User | null,
  target_role: ScopeRole<T>
): (() => Promise<T>) => {
  const self = useAppUser();
  const default_scope = useContext(ACTIVE_COMMUNITY);
  const type = hooks.useMemo(
    () => (entity_has_type(scope, "Group") ? "Group" : "Community"),
    [scope]
  );
  // @ts-expect-error:
  const [method, args] = hooks.useMemo<[M, ApiMethodParameters<M>]>(() => {
    if (!scope) {
      return ["getCommunity", default_scope!.id];
    }

    if (type === "Group") {
      return [
        target_role === "has_member" ? "removeGroupAdmin" : "addGroupAdmin",
        [scope.id, { body: { data: user ? [{ id: user.id }] : [] } }],
      ];
    }

    if (!user) {
      return ["getCommunity", [scope.id]];
    }
    const cur_role = get_user_community_role(user, scope);
    if (cur_role === target_role) {
      return ["getCommunity", [scope.id]];
    }

    const rels: any = {};
    if (cur_role !== "has_member") {
      rels[cur_role] = { data: scope[cur_role]?.filter(u => u.id !== user.id) };
    }
    if (target_role !== "has_member") {
      rels[target_role] = {
        data: scope[target_role as ScopeRole<Community>]?.concat({
          id: user.id,
        }),
      };
    }
    return [
      "updateCommunity",
      [scope.id, { body: { data: { attributes: {}, relationships: rels } } }],
    ];
  }, [scope, target_role, user, type]);

  const send_request = useApiFetch(method, ...args);
  return hooks.useCallback((): Promise<T> => {
    if (!self) {
      return Promise.reject("Not logged in");
    }
    if (!scope) {
      return Promise.reject("No scope provided for role change");
    }
    if (
      type === "Community" &&
      get_user_community_role(self, scope) !== "has_admin"
    ) {
      return Promise.reject(
        "You do not have permission to perform this action."
      );
    }
    return send_request().then(({ data }) => {
      if (type === "Group") {
        return { ...scope, has_admin: data };
      } else {
        return scope;
      }
    });
  }, [send_request, scope]);
};
