import { useStateIfMounted } from "@thrive-web/ui-hooks";
import * as Preact from "preact";
import {
  getCurrentUrl,
  route,
  Router,
  RouterOnChangeArgs,
} from "preact-router";

import {
  NotFoundPage,
  PrivacyPolicy,
  Redirect,
  TermsOfUse,
} from "@thrive-web/ui-components";
import { Context, EVENTS, LOGIN_LOCATION } from "@thrive-web/ui-model";
import { useContext, useEffect } from "preact/hooks";
import authWrapper from "~/auth/auth";
import { onRouteChange } from "~/model";

import { setPageAttr } from "@thrive-web/ui-utils";
import {
  CommunityGroups,
  CommunityGroupDetailPage,
  CommunityMembers,
  Login,
  Logout,
  CommunityMemberDetailPage,
  ACTIVE_COMMUNITY,
  HomePage,
  CommunityEditPage,
  ReimbursementsPage,
  ReimbursementsDetailPage,
} from "~/view/components";

export class RoutedApp extends Preact.Component<Context<"auth", true>, {}> {
  skip_first_change: boolean = true;

  componentDidMount(): void {
    this.checkAuthRoute();
    setPageAttr(getCurrentUrl());
  }

  componentDidUpdate(previousProps: Readonly<Context<"auth", true>>) {
    this.checkAuthRoute(previousProps);
    setPageAttr(getCurrentUrl());
  }

  // check if the auth ctx is forcing a route or if the forced route has changed
  checkAuthRoute = (prevProps?: Readonly<Context<"auth", true>>) => {
    const cur_url = getCurrentUrl();
    if (
      !this.props.auth ||
      !this.props.auth.route ||
      this.props.auth.route === cur_url ||
      (prevProps &&
        prevProps.auth &&
        prevProps.auth.route === this.props.auth.route)
    ) {
      return;
    }
    const { route: new_route } = this.props.auth;
    this.props
      .dispatch({
        type: EVENTS.UPDATE_AUTH_CTX,
        payload: { ...this.props.auth, route: undefined },
      })
      .then(() => route(new_route, ["/login", "/register"].includes(cur_url)));
  };

  onRouteChange = e => {
    const { skip_first_change } = this;
    this.skip_first_change = false;
    const { auth } = this.props;
    // if navigated to logout, invoke logout call and return to login page
    if (e.url === "/logout") {
      if (auth && (auth.user || auth.firebaseUser)) {
        authWrapper.logout().then(() => {
          route(LOGIN_LOCATION, true);
          window.location.reload();
        });
      }
    } else {
      const cur_url = getCurrentUrl();
      setPageAttr(cur_url);
      if (skip_first_change && e.url === cur_url) {
        return;
      }
      // check if the new route is allowed
      onRouteChange(e.url).then(new_route => {
        this.props.dispatch({
          type: EVENTS.UPDATE_LOCATION,
          payload: new_route || e.url,
        });
        // if not, go to route specified by the auth ctx
        if (new_route && new_route !== e.url) {
          route(new_route, ["/login", "/register"].includes(cur_url));
        }
      });
    }
  };

  render() {
    const { auth } = this.props;
    return <RoutedAppInner auth={auth} onRouteChange={this.onRouteChange} />;
  }
}

const RoutedAppInner: Preact.FunctionComponent<
  Context<"auth", false> & { onRouteChange: (e: RouterOnChangeArgs) => void }
> = ({ auth, onRouteChange }) => {
  const comm = useContext(ACTIVE_COMMUNITY);
  const [changed, set_changed] = useStateIfMounted(false);
  useEffect(() => () => set_changed(true), [comm?.id]);
  useEffect(() => {
    if (changed) set_changed(false);
  }, [changed]);

  // force a remount when community changes to refresh all views/lists
  return changed ? null : (
    <Router onChange={onRouteChange}>
      {auth && auth.userProfileBuilderComplete && (
        <Redirect path="/profile-builder" to="/" />
      )}
      <HomePage path="/" />
      <Login path="/login" default={!auth || !auth.firebaseUser} />
      <Logout path="/logout" />
      <PrivacyPolicy path="/privacy-policy" />
      <TermsOfUse path="terms-of-use" />
      <CommunityGroups path="/groups" />
      <Redirect path="/groups/:id" to="/groups/:id/activity" />
      <CommunityGroupDetailPage path="/groups/:id/:tab/:item?" />
      <CommunityMembers path="/people" />
      <CommunityMemberDetailPage path="/people/:id/:tab?/:action?" />
      <CommunityEditPage path="/settings" />
      <ReimbursementsPage path="/reimbursements" />
      <ReimbursementsDetailPage path="/reimbursements/:id" />
      <NotFoundPage default={true} />
    </Router>
  );
};
