import { GroupLabel, ReportType } from "@thrive-web/ui-api";
import { moment } from "@thrive-web/ui-common";
import {
  DivWithIcon,
  FooterError,
  ModalHeader,
  ModalTitle,
  RequestButton,
  Tooltip,
  TransitionOneWay,
} from "@thrive-web/ui-components";
import {
  useApiRequest,
  useModal,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { format_day_range } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { useCallback, useMemo, useRef } from "preact/hooks";
import { EXPENSE_DATE_PROP_OPTIONS, ExpenseDateProp } from "~/view/components";

export const REPORT_TYPE_LABELS: { [K in ReportType]: string } = {
  expense: "Reimbursement",
};

export interface GenerateReportParams {
  type: ReportType;
  date: Partial<DateRange>;
  dateType?: ExpenseDateProp;
  label?: GroupLabel;
  status?: boolean;
}

export const GenerateReportModalBody: Preact.FunctionComponent<
  ModalBodyProps & GenerateReportParams
> = ({
  closeButton,
  dismiss,
  type,
  date,
  dateType = "created_at",
  label,
  status,
}) => {
  const link_ref = useRef<HTMLAnchorElement>();

  const name = REPORT_TYPE_LABELS[type];
  const date_label = EXPENSE_DATE_PROP_OPTIONS[dateType].label;
  const params = useMemo(
    () => ({
      report: type,
      to: date.to?.toISOString() || new Date().toISOString(),
      from: date.from?.toISOString(),
      date_type: status === false && !!date ? "created_at" : dateType,
      label,
      is_approved: status,
    }),
    [
      date.to?.getTime(),
      date.from?.getTime(),
      dateType,
      label?.id,
      status,
      type,
    ]
  );

  const [report, set_report] = useStateIfMounted<string | undefined>(undefined);
  const [get_report_req, req_status] = useApiRequest("getReport", params);

  const get_report = useCallback(() => {
    get_report_req().then(csv => {
      set_report(`data:text/csv;charset=utf-8,${encodeURIComponent(`${csv}`)}`);
      setTimeout(() => link_ref.current?.click(), 500);
    });
  }, [get_report_req]);

  const filename = useMemo(() => {
    const parts = [name];
    if (status != null) {
      parts.push(status ? "Approved" : "Submitted");
    }
    if (date.to || date.from) {
      const date_parts = [date_label.replace(" ", "")];
      if (date.from) {
        date_parts.push(
          `${!date.to ? "After" : ""}${moment(date.from).format("MMDDYYYY")}`
        );
      }
      if (date.to) {
        date_parts.push(
          `${!date.from ? "Before" : ""}${moment(date.to).format("MMDDYYYY")}`
        );
      }
      parts.push(date_parts.join("-"));
    }
    if (label?.label) {
      parts.push(label.label);
    }

    return `${parts.filter(p => !!p).join("_")}.csv`;
  }, [params]);

  const content_start = (
    <Preact.Fragment>
      <div className="modal__body">
        <div className="expenses__export__body">
          <p>Generate a report with the following parameters?</p>
          <ul>
            <li>
              <strong>Report Type</strong>: {name}
            </li>
            {(params.from || params.to) && (
              <li>
                <strong>{date_label}</strong>: {format_day_range(date)}
              </li>
            )}
            {label && (
              <li>
                <strong>Group Label</strong>: {label.label}
              </li>
            )}
            {status != null && (
              <li>
                <strong>Status</strong>: {status ? "Approved" : "Submitted"}
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="modal__footer">
        <div className="modal__footer__left">
          <button className="filled gray" onClick={dismiss}>
            Cancel
          </button>
        </div>
        <div className="modal__footer__right">
          <FooterError error={req_status.error} />
          <RequestButton
            {...req_status}
            className="filled gray"
            onClick={get_report}
          >
            Generate
          </RequestButton>
        </div>
      </div>
    </Preact.Fragment>
  );

  const content_end = (
    <div className="modal__body">
      <div className="expenses__export__download">
        <p>
          <DivWithIcon icon="checked-solid-circle" side="left">
            <strong>Report generated!</strong>
          </DivWithIcon>
        </p>
        <p className="subtext">
          Click{" "}
          <a
            ref={link_ref}
            className="plain-link"
            href={report}
            download={filename}
          >
            here
          </a>{" "}
          if the download didn't start automatically.
        </p>
      </div>
    </div>
  );

  return (
    <Preact.Fragment>
      <ModalHeader button={closeButton}>
        <ModalTitle>{`Generate ${name} Report`}</ModalTitle>
      </ModalHeader>
      <TransitionOneWay
        from={content_start}
        to={content_end}
        start={!!report}
      />
    </Preact.Fragment>
  );
};

const popover_props = {
  defaultDirection: "bottom",
} as const;
export const GenerateReportModal: Preact.FunctionComponent<GenerateReportParams> =
  ({ type, date, dateType, label, status }) => {
    const body_props = useMemo(
      () => ({ type, date, dateType, label, status }),
      [type, date.to?.getTime(), date.from?.getTime(), label?.id, status]
    );
    const [modal, set_open] = useModal({
      id: "expense-report-modal",
      body: GenerateReportModalBody,
      bodyProps: body_props,
      showCloseButton: true,
    });

    return (
      <Tooltip
        text="You must specify a start date to generate a report."
        disabled={!!date.from}
        popoverProps={popover_props}
      >
        <div>
          <button
            className="filled gray"
            onClick={!!date.from ? () => set_open(true) : undefined}
            disabled={!date.from}
          >
            Export
            {modal}
          </button>
        </div>
      </Tooltip>
    );
  };
