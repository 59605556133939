import { User } from "@thrive-web/ui-api";
import { asSubroute } from "@thrive-web/ui-components";
import { useStateIfMounted } from "@thrive-web/ui-hooks";
import * as Preact from "preact";
import { useContext } from "preact/hooks";
import {
  ACTIVE_COMMUNITY,
  CommunityMemberDetailInfo,
  CommunityMemberDetailSidebar,
  SET_ACTIVE_COMMUNITY,
  useMemberRoleUpdate,
} from "~/view/components";

const CommunityMemberDetailAboutBase: Preact.FunctionComponent<RoutePageProps> =
  () => {
    const comm = useContext(ACTIVE_COMMUNITY);
    const dispatch = useContext(SET_ACTIVE_COMMUNITY);
    const [role_open, set_role_open] = useStateIfMounted<User | null>(null);
    const [on_click_role_button, role_modal] = useMemberRoleUpdate(
      comm,
      role_open,
      set_role_open,
      dispatch
    );

    return (
      <Preact.Fragment>
        <div className="member-profile">
          <CommunityMemberDetailInfo onClickChangeRole={on_click_role_button} />
          {role_modal}
        </div>
        <CommunityMemberDetailSidebar
          onClickChangeRole={on_click_role_button}
        />
      </Preact.Fragment>
    );
  };

export const CommunityMemberDetailAboutPage = asSubroute(
  CommunityMemberDetailAboutBase
);
