import * as Preact from "preact";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import { Group, GroupLabel } from "@thrive-web/ui-api";
import {
  Breadcrumb,
  ButtonWithIcon,
  DefaultDropdownButtonDiv,
  DropdownMenu,
  GROUP_DETAIL_CONTEXTS,
  Icon,
  PageHeader,
  TabLinks,
  useGroupEditModal,
} from "@thrive-web/ui-components";
import { display_text, get_url_for_entity } from "@thrive-web/ui-utils";
import {
  ACTIVE_COMMUNITY,
  useGroupArchive,
  CommunityGroupLabelButton,
  CommunityGroupLabelItem,
} from "~/view/components";

const createGroupTabLinks = (group: Group): NavLinkSpec[] => {
  if (!group || !group.id) {
    return [];
  }
  const url = get_url_for_entity(group);
  const links: NavLinkSpec[] = [
    {
      href: `${url}/activity`,
      icon: "activity",
      text: "Activity",
      activePathMatch: `${url}/activity/:post?/:whatever?`,
    },
    { href: `${url}/members`, icon: "people", text: "Members" },
  ];
  if (group.has_goals) {
    links.push({
      href: `${url}/goals`,
      icon: "goal",
      text: "Goals",
      activePathMatch: `${url}/goals/:goal?/:whatever?`,
    });
  }
  links.push({
    href: `${url}/about`,
    icon: "info-solid-circle",
    text: "About",
  });
  return links;
};

const noop = () => {};
export const CommunityGroupDetailHeader: Preact.FunctionComponent = () => {
  const comm = useContext(ACTIVE_COMMUNITY);
  const group = useContext(GROUP_DETAIL_CONTEXTS.group);
  const dispatch = useContext(GROUP_DETAIL_CONTEXTS.dispatch);

  const links = useMemo(
    () => (group ? createGroupTabLinks(group) : []),
    [group?.id, group?.has_goals]
  );

  const onArchive = useCallback(
    (updated: Group) => {
      dispatch("group", updated);
    },
    [dispatch]
  );

  const [onClickArchive, archiveGroupModal] = useGroupArchive(
    group,
    noop,
    onArchive
  );

  const onCloseEdit = useCallback(() => {
    if (window.location.hash === "#edit") {
      window.history.replaceState(undefined, "", window.location.pathname);
    }
  }, []);

  const [editModal, setEditModalOpen] = useGroupEditModal(group!, onCloseEdit);
  const openEditModal = useCallback(
    () => setEditModalOpen(true),
    [setEditModalOpen]
  );

  useEffect(() => {
    if (window.location.hash === "#edit") {
      openEditModal();
    }
  }, []);

  const breadcrumbs = useMemo(
    () => [{ label: "Groups", href: "/groups" }, { label: group?.name || "" }],
    [group?.name]
  );

  if (!group || !comm) {
    return null;
  }

  return (
    <PageHeader
      className="group-detail__header"
      breadcrumb={<Breadcrumb items={breadcrumbs} />}
    >
      <div className="page-header__content__main">
        <div className="page-header__left">
          <div className="page-header__icon">
            <div
              className="group-dot"
              data-size="lg"
              style={{ backgroundColor: group.background_color }}
            />
          </div>
          <div className="page-header__text">
            <div className="page-header__title">
              {display_text(group.name)}
              {group.is_archived ? <Icon name="lock" /> : undefined}
            </div>
          </div>
        </div>
        <div className="page-header__right">
          <DropdownMenu
            listClassName="card pill-card"
            button={
              <DefaultDropdownButtonDiv
                icon="more"
                className="button filled gray all-gray"
              />
            }
            items={[
              <ButtonWithIcon
                icon="edit"
                side="left"
                className="filled pill gray"
                onClick={openEditModal}
              >
                Edit Group
                {editModal}
              </ButtonWithIcon>,
              <ButtonWithIcon
                icon="lock"
                side="left"
                className="filled pill negative"
                onClick={() => onClickArchive(group)}
              >
                {group.is_archived ? "Unarchive" : "Archive"} Group
              </ButtonWithIcon>,
            ]}
          />
        </div>
      </div>
      <div className="page-header__content__children">
        <div className="group-detail__header__labels group-label__list">
          {group.has_label && group.has_label.length > 0 ? (
            group.has_label.map((label: GroupLabel) => (
              <CommunityGroupLabelItem
                key={label.id}
                label={label}
                groupId={group.id}
              />
            ))
          ) : (
            <span className="group-label__list__empty">No Label</span>
          )}
          <CommunityGroupLabelButton group={group} communityId={comm.id} />
        </div>
      </div>
      <TabLinks links={links} />
      {archiveGroupModal}
    </PageHeader>
  );
};
