import { ConnectionMapped, User } from "@thrive-web/ui-api";
import { replace_item_in } from "@thrive-web/ui-common";
import {
  asSubroute,
  ButtonWithIcon,
  CONNECTION_LIST_USER,
  ConnectionListPage,
  ModalHeader,
  ModalTitle,
  new_connection_search_filter,
  SelectUserModal,
  UserInviteListItem,
  RequestButton,
  UserCard,
  Carousel,
  DEFAULT_USER_FIELDS,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useDocumentTitle,
  useModal,
  useValueRef,
  useStateIfMounted,
  useRequest,
} from "@thrive-web/ui-hooks";
import { display_text, make_title } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import { MEMBER_DETAIL_CONTEXTS } from "~/view/components";

export const CommunityMemberDetailConnectionsModalItem: Preact.FunctionComponent<{
  user: User;
  onConnect?: (connection: ConnectionMapped) => void;
  setTarget: (user?: User) => void;
}> = ({ user, onConnect, setTarget }) => {
  const self = useContext(MEMBER_DETAIL_CONTEXTS.user);
  const create_connection_req = useApiMethod("createConnection");

  const create_connection = useCallback(() => {
    if (!self || !onConnect) {
      return Promise.reject("Not logged in");
    }
    setTarget(user);
    return create_connection_req({
      body: {
        data: {
          attributes: {},
          relationships: {
            users: {
              data: [{ id: self?.id || "" }, { id: user.id }],
            },
          },
        },
      },
      // @ts-expect-error:
      query: {
        include: ["users.User:profile_picture"],
        fields: {
          User: DEFAULT_USER_FIELDS,
        },
      },
    })
      .then(({ data }) => {
        onConnect({
          ...data,
          other_user: data.users!.find(u => u.id === user.id) || user,
        });
      })
      .catch(err => {
        setTarget(undefined);
        return Promise.reject(err);
      });
  }, [self?.id, user.id, onConnect, setTarget]);

  const [on_click, status] = useRequest(create_connection);

  return (
    <UserInviteListItem
      key={user.id}
      user={user}
      button={
        <RequestButton
          {...status}
          disabled={!onConnect}
          className="filled gray"
          showError={true}
          onClick={onConnect ? on_click : undefined}
        >
          Connect
        </RequestButton>
      }
    />
  );
};

export const CommunityMemberDetailConnectionsModal: Preact.FunctionComponent<
  ModalBodyProps & {
    onConnect: (
      _: ConnectionMapped[] | null,
      updated?: ConnectionMapped
    ) => void;
  }
> = ({ closeButton, onConnect }) => {
  const user = useContext(MEMBER_DETAIL_CONTEXTS.user);

  const [page, set_page] = useStateIfMounted<"before" | "after">("before");
  const [target, set_target] = useStateIfMounted<User | undefined>(undefined);
  const [connection, set_connection] = useStateIfMounted<
    ConnectionMapped | undefined
  >(undefined);
  const [coach, set_coach] = useStateIfMounted<User | undefined>(undefined);

  const update_connection = useApiMethod("updateConnection");
  const set_coach_action = useCallback(
    (u: User) => {
      if (!connection) {
        return Promise.reject();
      }
      set_coach(u);
      return update_connection(connection?.id, {
        query: {
          include: ["users.User:profile_picture"],
        },
        body: {
          data: {
            attributes: {},
            relationships: {
              has_coach: {
                data: {
                  id: u.id,
                },
              },
            },
          },
        },
      }).then(({ data }) => {
        set_connection({
          ...data,
          other_user:
            data.users!.find(ou => ou.id === connection.other_user.id) ||
            connection.other_user,
        });
      });
    },
    [connection?.id]
  );
  const [on_set_coach, status] = useRequest(set_coach_action);

  useEffect(() => {
    set_page(!!connection ? "after" : "before");
  }, [!!connection]);

  useEffect(() => {
    if (connection) {
      onConnect(null, connection);
    }
  }, [connection]);

  // user search
  const sendSearchRequest = useApiMethod("getUsers");
  const getUsers = useCallback(
    (search: string, offset: number, limit?: number) =>
      sendSearchRequest({
        query: {
          filter: new_connection_search_filter(user?.id, search),
          sort: [{ by: "full_name", dir: "asc" }],
          limit,
          offset,
          include: ["profile_picture"],
          include_count: true,
        },
      }),
    [sendSearchRequest, user?.id]
  );

  const empty_label = useCallback(
    search => `No users found that match "${search}"`,
    []
  );

  const renderUser = useCallback(
    (u: User) =>
      !user || user.id == u.id ? null : (
        <CommunityMemberDetailConnectionsModalItem
          user={u}
          key={user.id}
          onConnect={
            !target || target?.id === u.id ? set_connection : undefined
          }
          setTarget={set_target}
        />
      ),
    [user?.id, target?.id]
  );

  const items = {
    before: (
      <Preact.Fragment>
        <ModalHeader button={closeButton}>
          <ModalTitle>Add a Connection</ModalTitle>
        </ModalHeader>
        <SelectUserModal
          className="modal__body"
          getUsers={getUsers}
          emptyLabel={empty_label}
          renderUser={renderUser}
          removeOnSelect={false}
        />
      </Preact.Fragment>
    ),
    after: connection ? (
      <Preact.Fragment>
        <ModalHeader button={closeButton}>
          <ModalTitle>New Connection Added</ModalTitle>
        </ModalHeader>
        <div className="modal__body people-create__modal__success">
          <UserCard
            user={connection.other_user}
            avatarIsLink={false}
            nameIsLink={false}
            size="xl"
          />
          <div className="modal__footer">
            {user &&
              connection &&
              [connection.other_user, user].map(u => (
                <RequestButton
                  key={u.id}
                  {...(u.id === coach?.id
                    ? status
                    : { pending: false, disabled: status.pending })}
                  className="filled gray"
                  onClick={() => on_set_coach(u)}
                  successText="Success!"
                >
                  Make {u?.id === user?.id ? "Coachee" : "Coach"}
                </RequestButton>
              ))}
          </div>
        </div>
      </Preact.Fragment>
    ) : null,
  } as const;

  return <Carousel page={page} items={items} />;
};

export const CommunityMemberDetailConnections: Preact.FunctionComponent =
  () => {
    const user = useContext(MEMBER_DETAIL_CONTEXTS.user);
    const dispatch = useContext(MEMBER_DETAIL_CONTEXTS.dispatch);

    const user_ref = useValueRef(user);
    const on_update = useCallback(
      (_: ConnectionMapped[] | null, updated?: ConnectionMapped) => {
        if (!updated || !user_ref.current) {
          return;
        }
        dispatch("user", {
          ...user_ref.current,
          has_connection: replace_item_in(
            user_ref.current.has_connection || [],
            c => c.id === updated.id,
            updated,
            "end"
          ),
        });
      },
      [dispatch]
    );

    const modal_props = useMemo(() => ({ onConnect: on_update }), [on_update]);

    const [create_conn_modal, setOpen] = useModal({
      id: "create-request",
      body: CommunityMemberDetailConnectionsModal,
      className: "people-create__modal invite-users-modal",
      bodyProps: modal_props,
      showCloseButton: true,
    });
    const openModal = useCallback(() => {
      setOpen(true);
    }, [setOpen]);

    return (
      <div className="list-page people-page page-tab__section">
        <div className="page-tab__section__header">
          <div className="page-tab__section__title">People</div>
          <div className="page-tab__section__button">
            <ButtonWithIcon
              className="button filled gray"
              icon="add"
              collapse={true}
              side="right"
              onClick={() => setOpen(true)}
            >
              New Connection
            </ButtonWithIcon>
          </div>
        </div>
        <ButtonWithIcon
          className="button filled gray"
          side="left"
          icon="add"
          onClick={() => setOpen(true)}
        >
          New Connection
        </ButtonWithIcon>
        <div className="page-tab__record-list">
          <ConnectionListPage
            openModal={openModal}
            user={user}
            onUpdate={on_update}
          />
        </div>
        {create_conn_modal}
      </div>
    );
  };

export const CommunityMemberDetailConnectionsPageBase: Preact.FunctionComponent =
  () => {
    const { Provider } = CONNECTION_LIST_USER;
    const user = useContext(MEMBER_DETAIL_CONTEXTS.user);
    useDocumentTitle(
      () => make_title([display_text(user?.full_name) || "", "Connections"]),
      [user?.full_name]
    );
    return (
      <Provider value={user}>
        <CommunityMemberDetailConnections />
      </Provider>
    );
  };

export const CommunityMemberDetailConnectionsPage = asSubroute(
  CommunityMemberDetailConnectionsPageBase
);
