import * as Preact from "preact";
import { route } from "preact-router";
import { useCallback, useMemo } from "preact/hooks";
import { Community, Group } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  DefaultModalContent,
  GroupCreate,
} from "@thrive-web/ui-components";
import { useModal } from "@thrive-web/ui-hooks";
import { cache_record, get_url_for_entity } from "@thrive-web/ui-utils";

export const CommunityGroupCreateModal: Preact.FunctionComponent<
  ModalBodyProps & {
    onCreate?: (group: Group) => void;
  }
> = ({ closeButton, dismiss, onCreate }) => {
  const onFinishPageOne = useCallback(
    (group: Group) => {
      if (!group || !group["id"]) {
        return;
      }
      onCreate?.(group);
      cache_record(group);

      route(get_url_for_entity(group));
    },
    [onCreate]
  );

  return (
    <DefaultModalContent title="Create a Group" closeButton={closeButton}>
      <div className="group-create__body">
        <GroupCreate onFinish={onFinishPageOne} dismiss={dismiss} />
      </div>
    </DefaultModalContent>
  );
};

export const CommunityGroupCreate: Preact.FunctionComponent<{
  record: Community;
  onAdd: (new_group: Group) => void;
  onClose?: () => void;
}> = ({ record, onAdd, onClose }) => {
  const bodyProps = useMemo(() => ({ onCreate: onAdd }), [onAdd]);
  const [modal, set_open] = useModal(
    {
      id: "community-group-create",
      className: "member-invite__modal modal-form group-create__modal",
      body: CommunityGroupCreateModal,
      showCloseButton: true,
      dismissOnClickBackdrop: true,
      bodyProps,
    },
    onClose,
    true
  );

  return (
    <ButtonWithIcon
      icon="add"
      side="left"
      className="filled gray"
      onClick={() => set_open(true)}
    >
      Create Group
      {modal}
    </ButtonWithIcon>
  );
};
