import { make_title } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { useCallback, useContext, useMemo, useRef } from "preact/hooks";
import { Group, User } from "@thrive-web/ui-api";
import {
  asSubroute,
  Card,
  EmptyList,
  GROUP_DETAIL_CONTEXTS,
  GROUP_DETAIL_LIST_CONTEXTS,
  LazyListSection,
  SearchBar,
  useDynamicList,
  useLazyList,
  useRenderDynamicListWithPagedFetch,
} from "@thrive-web/ui-components";
import { useDocumentTitle, useStateIfMounted } from "@thrive-web/ui-hooks";
import {
  ACTIVE_COMMUNITY,
  MemberListLoading,
  useMemberRemove,
  useMemberRoleUpdate,
  useMemberSearch,
  UserMemberInvite,
  UserMemberListItem,
} from "~/view/components";

type UserWithRole = User & {
  role?: string;
};

export const CommunityGroupDetailMembersList: Preact.FunctionComponent<{
  users: readonly UserWithRole[];
  loadMoreElem?: Preact.VNode | null;
  onClickRemove: (user: User) => void;
  onClickChangeRole: (user: User) => void;
}> = ({ users, loadMoreElem, onClickRemove, onClickChangeRole }) => {
  const content = useLazyList(
    users,
    user => (
      <UserMemberListItem
        user={user}
        role={user.role}
        onClickRemove={onClickRemove}
        onClickChangeRole={onClickChangeRole}
      />
    ),
    [],
    20
  );

  if (!users.length) {
    return (
      <EmptyList className="member-list__empty">
        This Group has no Members. To add a Member, click Add Member.
      </EmptyList>
    );
  }

  return (
    <Card className="member-list community__member-list user-list">
      {content.map((s, i) => (
        <LazyListSection key={i}>{s}</LazyListSection>
      ))}
      {loadMoreElem && <div className="load-more">{loadMoreElem}</div>}
    </Card>
  );
};

export const CommunityGroupDetailMembers: Preact.FunctionComponent<{
  group: Group;
}> = ({ group }) => {
  const comm = useContext(ACTIVE_COMMUNITY);
  const g_dispatch = useContext(GROUP_DETAIL_CONTEXTS.dispatch);

  const [search, set_search] = useStateIfMounted("");
  const [list, dispatch] = useDynamicList(GROUP_DETAIL_LIST_CONTEXTS.members);
  const [fetchMembers, force_refresh] = useMemberSearch(group.id, search);

  const cur_list = useRef(group.has_member || []);
  const onInvite = useCallback(
    (user: User) => {
      cur_list.current = [...cur_list.current, { id: user.id }];
      force_refresh();
      g_dispatch("group", { ...group, has_member: cur_list.current });
    },
    [g_dispatch, group, g_dispatch]
  );

  const [delete_target, set_delete_target] = useStateIfMounted<User | null>(
    null
  );
  const onRemove = useCallback(
    id => {
      dispatch.remove(m => m.id === id);
      cur_list.current = cur_list.current.filter(u => u.id !== id);
      g_dispatch("group", { ...group, has_member: cur_list.current });
    },
    [dispatch]
  );
  const [onClickRemove, removeMemberModal] = useMemberRemove(
    group,
    delete_target,
    set_delete_target,
    onRemove
  );

  const [role_target, set_role_target] = useStateIfMounted<User | null>(null);
  const onChangeRole = useCallback(
    updated_group => g_dispatch("group", updated_group),
    [dispatch]
  );
  const [onClickChangeRole, changeRoleModal] = useMemberRoleUpdate(
    group,
    role_target,
    set_role_target,
    onChangeRole
  );

  const users_with_roles = useMemo<UserWithRole[] | null>(
    () =>
      list
        ? list.map(u => ({
            ...u,
            role: group.has_admin?.find(a => a.id === u.id)
              ? "Admin"
              : "Member",
          }))
        : null,
    [list, group]
  );
  const passthrough_props = useMemo(
    () => ({ onClickRemove, onClickChangeRole }),
    [onClickRemove, onClickChangeRole]
  );
  const content = useRenderDynamicListWithPagedFetch(
    users_with_roles,
    dispatch,
    (result, load_more_elem, _, passthrough) => (
      // @ts-expect-error:
      <CommunityGroupDetailMembersList
        users={result}
        loadMoreElem={load_more_elem}
        {...passthrough}
      />
    ),
    [],
    fetchMembers,
    passthrough_props,
    { PendingView: MemberListLoading, limit: 20 }
  );

  if (!comm || !group) {
    return null;
  }
  return (
    <div className="page-tab__section group-members__list">
      <div className="page-tab__section__header">
        <div className="page-tab__section__title">Members</div>
        <div className="page-tab__section__button">
          <UserMemberInvite record={group} onInvite={onInvite} scope={comm} />
        </div>
      </div>

      <SearchBar placeholder="Search" onSubmit={set_search} value={search} />
      {content}
      {removeMemberModal}
      {changeRoleModal}
    </div>
  );
};

const CommunityGroupDetailMembersPageBase: Preact.FunctionComponent = () => {
  const group = useContext(GROUP_DETAIL_CONTEXTS.group);
  useDocumentTitle(
    () => make_title(group?.name ? [`Members of ${group.name}`] : ["Groups"]),
    [group?.name]
  );
  if (!group) {
    return null;
  }
  return <CommunityGroupDetailMembers group={group} />;
};

export const CommunityGroupDetailMembersPage = asSubroute(
  CommunityGroupDetailMembersPageBase
);
