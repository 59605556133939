import * as Preact from "preact";
import { useContext, useEffect } from "preact/hooks";
import { PureComponent } from "preact/compat";
import { SiteModal, UserSidebar } from "@thrive-web/ui-components";
import { PO_EDGE_BUFFER_LG, PO_EDGE_BUFFER_SM } from "@thrive-web/ui-constants";
import {
  RoutedApp,
  WithGlobalData,
  SiteHeader,
  NAV_LINKS,
} from "~/view/components";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  useCallbackRef,
  useStateIfMounted,
  useWindowEventListener,
} from "@thrive-web/ui-hooks";
import { setPageAttr } from "@thrive-web/ui-utils";
import { getCurrentUrl } from "preact-router";
import { NavSidebarAdmin } from "~/view/components/layout/navigation/NavSidebar";

export const View: Preact.FunctionComponent = () => {
  const modal = useContext(CONTEXTS.modal);
  const login_modal = useContext(CONTEXTS.login_modal);
  const auth = useContext(CONTEXTS.auth);
  const dispatch = useContext(CONTEXTS.dispatch);

  useEffect(() => {
    setPageAttr(getCurrentUrl());
  }, []);

  const [is_touch_screen, set_is_touch_screen] =
    useStateIfMounted<boolean>(false);
  const touch_listener = useCallbackRef(
    e => {
      set_is_touch_screen(true);
      window["is_touch_screen"] = true;
    },
    [set_is_touch_screen]
  );
  const enable_touch_listener = useWindowEventListener(
    "touchstart",
    touch_listener
  );
  useEffect(() => {
    enable_touch_listener(!is_touch_screen);
  }, [is_touch_screen]);

  return (
    <div
      className="site admin-site"
      data-touch-screen={`${is_touch_screen}`}
      style={{
        "--po-edge-buffer-lg": `${PO_EDGE_BUFFER_LG}px`,
        "--po-edge-buffer-sm": `${PO_EDGE_BUFFER_SM}px`,
      }}
    >
      <fieldset
        id="site-main"
        {...((modal && modal.open) || (login_modal && login_modal.open)
          ? {
              disabled: true,
              tabIndex: -1,
            }
          : {})}
      >
        <div className="site__scroll-override-container">
          <WithGlobalData>
            <SiteHeader navLinks={NAV_LINKS} />
            <div className="row site-body">
              <div id="popover-intersect" />
              <NavSidebarAdmin navLinks={NAV_LINKS} />
              <RoutedApp auth={auth} dispatch={dispatch} />
              <UserSidebar onlyLogout={true} />
            </div>
          </WithGlobalData>
        </div>
      </fieldset>
      <SiteModal />
    </div>
  );
};

export class ViewWrapper extends PureComponent<{}, { mounted: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
    };
  }
  componentDidMount() {
    this.setState({ mounted: true });
  }
  render() {
    return this.state.mounted ? this.props.children : null;
  }
}
