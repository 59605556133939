import * as hooks from "preact/hooks";
import { FilterSpec } from "@thrive-web/core";
import type { FilterClause } from "@swymbase/sparql-rest";
import { useApiMethod, useStateRef } from "@thrive-web/ui-hooks";
import { get_guid_from_iri } from "@thrive-web/ui-utils";
import { ACTIVE_COMMUNITY } from "~/view/components";

export const useGroupSearch = (
  id?: string,
  search?: string,
  other_filters?: FilterClause[]
) => {
  const comm = hooks.useContext(ACTIVE_COMMUNITY);
  const fetch_groups_req = useApiMethod("getGroups");
  const type = hooks.useMemo(
    () => (id ? get_guid_from_iri(id)[1] : undefined),
    [id]
  );
  const [num, set_num, num_ref] = useStateRef(0);
  const force_refresh = hooks.useCallback(
    () => set_num(num_ref.current + 1),
    []
  );

  const get_groups = hooks.useCallback(
    (offset: number, limit?: number) => {
      if (!id || !comm) {
        return Promise.reject("No user/community id provided.");
      }
      if (!type) {
        return Promise.reject(`Could not get record type from id '${id}'`);
      }
      let filter: FilterSpec = [
        ["=", ["this", ["^", `Community:has_group`]], ["id", comm.id]],
        ...(other_filters || []),
      ];
      if (type === "User") {
        filter = [...filter, ["=", ["this", `Group:has_member`], ["id", id]]];
      }
      if (search) {
        filter = [
          ...filter,
          ["match", ["this", "Group:name"], search, "i"],
        ] as FilterSpec;
      }
      return fetch_groups_req({
        query: {
          filter,
          offset,
          limit,
          include_count: true,
          include: ["has_label", "background_image"],
        },
      });
    },
    [id, type, search, num, other_filters]
  );

  return [get_groups, force_refresh] as const;
};
