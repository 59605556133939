import * as Preact from "preact";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import { map_record_to_json_resource, TYPES } from "@thrive-web/core";
import { Community, WriteCommunity } from "@thrive-web/ui-api";
import { URL_REGEX } from "@thrive-web/ui-common";
import {
  ButtonWithIcon,
  Card,
  ColorPicker,
  DefaultFileUploadButton,
  ErrorMessage,
  ExpandableImage,
  FileUpload,
  InputWithFormHelpers,
  PageBody,
  PageContent,
  PageHeader,
  RequestButtonWithIcon,
  TextAreaWithFormHelpers,
  WithFloatingTitle,
} from "@thrive-web/ui-components";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";
import {
  useApiMethod,
  useCallbackRef,
  useDirtyFormWithDiff,
  useRequestWithMedia,
  useStateIfMounted,
  useStateRef,
  useUntrackedRequestWithMedia,
} from "@thrive-web/ui-hooks";
import { maybeClassName } from "@thrive-web/ui-utils";
import { ACTIVE_COMMUNITY, SET_ACTIVE_COMMUNITY } from "~/view/components";

export const CommunityEdit: Preact.FunctionComponent<{
  community: Community;
  onFinish: (comm: Community, fetch?: boolean) => void;
}> = ({ community, onFinish }) => {
  const {
    name,
    purpose,
    website_url,
    accent_color,
    cover_image_url,
    avatar_image_url,
  } = community;

  const initial_cover = useMemo<FileUploadData | undefined>(
    () =>
      cover_image_url
        ? { data: cover_image_url, name: "", mime: "" }
        : undefined,
    []
  );
  const initial_avatar = useMemo<FileUploadData | undefined>(
    () =>
      avatar_image_url
        ? { data: avatar_image_url, name: "", mime: "" }
        : undefined,
    []
  );

  const prepare_for_diffing = useCallback(
    data => ({
      ...data,
      cover_image: cover_file_ref,
      avatar_image: avatar_file_ref,
      website_url: data.website_url?.id,
    }),
    []
  );

  const [
    form_data,
    updated_form_data,
    deleted_form_data_keys,
    set_field,
    on_input_change,
    ,
    reset_form,
  ] = useDirtyFormWithDiff<WriteCommunity>(
    {
      name,
      purpose,
      website_url,
      accent_color,
    },
    "CommunityEdit",
    prepare_for_diffing,
    true
  );

  const [cover_file, on_change_cover_file, cover_file_ref] = useStateRef<
    FileUploadData | undefined
  >(undefined);
  const [avatar_file, on_change_avatar_file, avatar_file_ref] = useStateRef<
    FileUploadData | undefined
  >(undefined);
  if (
    initial_cover &&
    !cover_file_ref.current &&
    form_data.cover_image !== null
  ) {
    cover_file_ref.current = initial_cover;
  }
  if (
    initial_avatar &&
    !avatar_file_ref.current &&
    form_data.avatar_image !== null
  ) {
    avatar_file_ref.current = initial_avatar;
  }

  const on_change_cover = useCallback((data: FileUploadData | undefined) => {
    on_change_cover_file(data);
    // @ts-expect-error:
    set_field("cover_image", initial_cover && !data ? null : undefined);
  }, []);

  const on_change_avatar = useCallback((data: FileUploadData | undefined) => {
    on_change_avatar_file(data);
    // @ts-expect-error:
    set_field("avatar_image", initial_avatar && !data ? null : undefined);
  }, []);

  const update_community = useApiMethod("updateCommunity");
  const [update_comm_and_cover, cover_pending, cover_progress] =
    useUntrackedRequestWithMedia(
      "Community",
      "cover_image",
      update_community,
      cover_file,

      !initial_cover || updated_form_data?.cover_image === null,
      true
    );

  const [
    submit_form,
    { pending, success, error },
    avatar_pending,
    avatar_progress,
  ] = useRequestWithMedia(
    "Community",
    "avatar_image",
    update_comm_and_cover,
    avatar_file,
    !initial_avatar || updated_form_data?.avatar_image === null,
    true
  );

  const on_success = useCallbackRef(
    (new_comm: Community) => {
      reset_form(new_comm);
      setTimeout(() => onFinish(new_comm, false), 1200);
    },
    [reset_form, onFinish]
  );
  const required_fields_are_valid = !!form_data.name && !!form_data.purpose;

  const on_submit = useCallback(
    e => {
      e.preventDefault();
      if (!e.target.checkValidity() || !required_fields_are_valid) {
        e.target.reportValidity();
        return;
      }
      // cancel if already submitted
      if (pending) {
        return;
      }

      const new_data: any = { ...updated_form_data };

      if (
        new_data.website_url?.id &&
        !/^https?:\/\//i.test(new_data.website_url.id)
      ) {
        new_data.website_url.id = `https://${new_data.website_url.id.trim()}`;
      }
      deleted_form_data_keys?.forEach(k => {
        new_data[k] = null;
      });

      const {
        id: _,
        type,
        ...data
      } = map_record_to_json_resource(
        {
          ...new_data,
          id: "",
        },
        TYPES.Community
      );
      delete data.relationships?.has_group;
      delete data.relationships?.has_admin;
      delete data.relationships?.has_manager;
      delete data.relationships?.has_moderator;
      delete data.relationships?.has_member;

      if (initial_cover && cover_file === null) {
        data.relationships = {
          ...(data.relationships || {}),
          cover_image: { data: null },
        };
      }
      if (initial_avatar && avatar_file === null) {
        data.relationships = {
          ...(data.relationships || {}),
          avatar_image: { data: null },
        };
      }

      submit_form(community.id, {
        body: { data },
      }).then(({ data: new_comm }) => {
        on_success.current?.(new_comm);
      });
    },
    [
      updated_form_data,
      deleted_form_data_keys,
      submit_form,
      on_success,
      required_fields_are_valid,
      cover_file,
      avatar_file,
    ]
  );

  return (
    <PageContent
      id="community-edit-page"
      className="community-page edit-page no-separate-header"
    >
      <div className="community__update">
        <form onSubmit={on_submit}>
          <PageHeader title={<h1>Edit Community</h1>} />
          <PageBody>
            <Card>
              <h3 className="user-profile__card__title">Details</h3>
              <div className="form__input-row" data-row="name-url">
                <WithFloatingTitle title="Name">
                  <InputWithFormHelpers
                    name="name"
                    onChange={on_input_change("name")}
                    value={form_data.name}
                    required={true}
                    placeholder="Name"
                    submitOnEnter={false}
                  />
                </WithFloatingTitle>
                <WithFloatingTitle title="Website URL">
                  <InputWithFormHelpers
                    name="website_url"
                    onChange={e =>
                      set_field(
                        "website_url",
                        e.target.value ? { id: e.target.value } : undefined
                      )
                    }
                    validate={(value, input) => {
                      if (value && !URL_REGEX.test(value)) {
                        input?.setCustomValidity("Please enter a valid url.");
                        return false;
                      }
                      input?.setCustomValidity("");
                      return true;
                    }}
                    value={form_data.website_url?.id}
                    required={false}
                    placeholder="Website URL"
                    submitOnEnter={false}
                    pattern={URL_REGEX.source}
                  />
                </WithFloatingTitle>
              </div>
              <div className="form__input-row">
                <WithFloatingTitle
                  className="textarea__container"
                  title="Purpose"
                >
                  <TextAreaWithFormHelpers
                    name="purpose"
                    onChange={on_input_change("purpose")}
                    value={form_data.purpose}
                    required={true}
                    placeholder="Purpose"
                    submitOnEnter={false}
                  />
                </WithFloatingTitle>
              </div>
              <h3 className="user-profile__card__title">Appearance</h3>
              <div className="form__input-row" data-row="color-background">
                <ColorPicker
                  onChange={value => set_field("accent_color", value)}
                  value={form_data?.accent_color}
                  required={false}
                  placeholder="Accent Color"
                />
                <FileUpload
                  value={cover_file_ref.current}
                  Button={CoverFileUploadButton}
                  onChange={on_change_cover}
                  accept="image/*"
                  required={true}
                />
              </div>
              <div className="form__input-row">
                <FileUpload
                  className="file-input__avatar"
                  value={avatar_file_ref.current}
                  Button={AvatarFileUploadButton}
                  onChange={on_change_avatar}
                  accept="image/*"
                  required={true}
                />
              </div>
              <div className="form__footer">
                {error ? <ErrorMessage>{error.message}</ErrorMessage> : <div />}
                <RequestButtonWithIcon
                  className="filled blue"
                  icon="checked"
                  side="left"
                  successText="Changes Saved!"
                  pending={pending}
                  success={success && !updated_form_data}
                  disabled={!updated_form_data}
                  progress={
                    cover_pending
                      ? cover_progress
                      : avatar_pending
                      ? avatar_progress
                      : undefined
                  }
                >
                  Save Changes
                </RequestButtonWithIcon>
              </div>
            </Card>
          </PageBody>
        </form>
      </div>
    </PageContent>
  );
};

export const CommunityEditPage: Preact.FunctionComponent<RoutePageProps> =
  () => {
    const comm = useContext(ACTIVE_COMMUNITY);
    const set_comm = useContext(SET_ACTIVE_COMMUNITY);

    const [changed, set_changed] = useStateIfMounted(false);
    useEffect(() => () => set_changed(true), [comm]);
    useEffect(() => {
      if (changed) set_changed(false);
    }, [changed]);

    if (!comm || !set_comm || changed) {
      return null;
    }
    return <CommunityEdit community={comm} onFinish={set_comm} />;
  };

export const CoverFileUploadButton: Preact.FunctionComponent<
  HTMLButtonProps & FileUploadButtonProps
> = props => {
  return (
    <DefaultFileUploadButton
      {...props}
      className={`filled gray${maybeClassName(props.className)}`}
      name="Cover Image"
    >
      Choose a Cover Image
    </DefaultFileUploadButton>
  );
};

export const AvatarFileUploadButton: Preact.FunctionComponent<
  HTMLButtonProps & FileUploadButtonProps
> = ({ file, clickInput, clearInput, onClick, className, ...props }) => {
  return (
    <Preact.Fragment>
      {file ? (
        <ExpandableImage src={file.data} />
      ) : (
        <img
          className="community-card__avatar__placeholder"
          src={`${IMAGES_PREFIX}/icon-groups.svg`}
        />
      )}
      <ButtonWithIcon
        {...props}
        side="left"
        icon="image"
        className={`filled gray${maybeClassName(className)}`}
        onClick={e => {
          clickInput(e);
          // @ts-ignore
          onClick && onClick(e);
        }}
      >
        {file ? "Change" : "Choose an"} Avatar Image
      </ButtonWithIcon>
    </Preact.Fragment>
  );
};
