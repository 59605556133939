import { Group, User } from "@thrive-web/ui-api";
import {
  asSubroute,
  Card,
  EmptyList,
  LazyListSection,
  SearchBar,
  useLazyList,
  useRenderDynamicListWithPagedFetch,
} from "@thrive-web/ui-components";
import {
  useDynamicListVariable,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import {
  ACTIVE_COMMUNITY,
  GroupListItem,
  MEMBER_DETAIL_CONTEXTS,
  MemberListLoading,
  useGroupSearch,
  useMemberRemove,
  useMemberRoleUpdate,
  MemberGroupAdd,
} from "~/view/components";

const noop = () => {};
export const CommunityMemberDetailGroupsList: Preact.FunctionComponent<{
  groups: readonly Group[];
  loadMoreElem?: Preact.VNode | null;
  onClickRemove: (group: Group) => void;
  onClickChangeRole: (group: Group) => void;
  member: User;
}> = ({ groups, loadMoreElem, onClickRemove, onClickChangeRole, member }) => {
  const content = useLazyList(
    groups,
    group => (
      <GroupListItem
        group={group}
        onClickRemoveMember={onClickRemove}
        onClickChangeRole={onClickChangeRole}
        member={member}
      />
    ),
    [],
    20
  );

  if (!groups.length) {
    return (
      <EmptyList className="member-list__empty">
        This Member is not in any Groups. To add them to a Group, click Add to
        Group.
      </EmptyList>
    );
  }

  return (
    <Card className="member-list community__member-list group-list">
      {content.map((s, i) => (
        <LazyListSection key={i}>{s}</LazyListSection>
      ))}
      {loadMoreElem && <div className="load-more">{loadMoreElem}</div>}
    </Card>
  );
};

// todo: Add to Group button

export const CommunityMemberDetailGroups: Preact.FunctionComponent<{
  user: User;
}> = ({ user }) => {
  const comm = useContext(ACTIVE_COMMUNITY);

  const [search, set_search] = useStateIfMounted("");
  const [list, dispatch] = useDynamicListVariable<Group>(null);
  // todo: this may need to include comm.id
  const [fetch_groups, force_refresh] = useGroupSearch(user.id, search);

  const [delete_target, set_delete_target] = useStateIfMounted<Group | null>(
    null
  );

  const [onClickRemove, removeMemberModal] = useMemberRemove(
    delete_target,
    user,
    set_delete_target,
    force_refresh,
    true
  );

  const [role_target, set_role_target] = useStateIfMounted<Group | null>(null);
  const on_change_role = useCallback((group: Group) => {
    dispatch.update(g => g.id === group.id, group);
    set_role_target(null);
  }, []);
  const [open_role_modal, role_modal] = useMemberRoleUpdate(
    role_target,
    user,
    noop,
    on_change_role
  );
  const on_click_change_role = useCallback(
    (group: Group) => {
      set_role_target(group);
      // @ts-expect-error:
      open_role_modal();
    },
    [open_role_modal]
  );

  const passthrough_props = useMemo(
    () => ({
      onClickRemove,
      onClickChangeRole: on_click_change_role,
      member: user,
    }),
    [onClickRemove, user?.id]
  );
  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more_elem, _, passthrough) => (
      // @ts-expect-error:
      <CommunityMemberDetailGroupsList
        groups={result}
        loadMoreElem={load_more_elem}
        {...passthrough}
      />
    ),
    [],
    fetch_groups,
    passthrough_props,
    { PendingView: MemberListLoading, limit: 20 }
  );

  if (!comm) {
    return null;
  }

  return (
    <div className="page-tab__section group-members__list">
      <div className="page-tab__section__header">
        <div className="page-tab__section__title">Groups</div>
        <div className="page-tab__section__button">
          <MemberGroupAdd user={user} onFinish={force_refresh} />
        </div>
      </div>
      <SearchBar placeholder="Search" onSubmit={set_search} value={search} />
      {content}
      {role_modal}
      {removeMemberModal}
    </div>
  );
};

const CommunityMemberDetailGroupsPageBase: Preact.FunctionComponent = () => {
  const user = useContext(MEMBER_DETAIL_CONTEXTS.user);
  if (!user) {
    return null;
  }
  return <CommunityMemberDetailGroups user={user} />;
};

export const CommunityMemberDetailGroupsPage = asSubroute(
  CommunityMemberDetailGroupsPageBase
);
