import { get_guid_from_iri } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import { FilterSpec } from "@thrive-web/core";
import { Group, GroupLabel, MappedApiResponse } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  DefaultModalContent,
  FloatingTitle,
  FooterError,
  GROUP_DETAIL_CONTEXTS,
  InputWithDropdown,
  Popover,
  RequestButton,
  RequestButtonWithIcon,
  Tag,
  usePopoverTrigger,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useDynamicListVariable,
  useModal,
  useRequest,
  useRequestChain,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";

export const getGroupLabelText = l => l.label;
const create_opt = t => ({
  id: "",
  label: t.trim().replace(/\s+/g, "-").toLowerCase(),
});
const create_text = t => `Create Label "${t}"`;
export const renderGroupLabelTag = (opt, clear) => (
  <Tag className="filled gray all-gray group-label" onRemove={clear}>
    {opt.label}
  </Tag>
);
export const compareGroupLabels = (opt, selected) =>
  !selected.find(o => (opt.id ? opt.id === o.id : opt.label === o.label));

const pass_req1_params = (
  r1: MappedApiResponse<"createGroupLabel">
): [GroupLabel] => [r1.data];
const combine_results = (r1, r2) => r1.data;

export const CommunityGroupLabelModal: Preact.FunctionComponent<
  {
    communityId: string;
    group: Group;
    onFinish: (labels: GroupLabel[]) => void;
  } & ModalBodyProps
> = ({ group, onFinish, communityId, closeButton, dismiss }) => {
  const [labels, set_labels, labels_ref] = useDynamicListVariable<GroupLabel>(
    group.has_label || []
  );
  const onRemove = useCallback(
    l => set_labels.remove(la => la.id === l.id),
    []
  );

  const onAdd = useCallback(l => {
    set_labels.add(l, "end");
  }, []);

  const [label_value, set_label] = useStateIfMounted<GroupLabel | undefined>(
    undefined
  );

  const get_labels_req = useApiMethod("getGroupLabels");
  const get_labels = useCallback(
    (search: string | undefined, offset: number, limit?: number) => {
      let filter: FilterSpec = [
        ["=", ["this", "CommunityGroupLabel:community"], ["id", communityId]],
        ["not", ["=", ["this", ["^", "Group:has_label"]], ["id", group.id]]],
      ];
      if (search) {
        filter = [
          ...filter,
          ["match", ["this", "CommunityGroupLabel:label"], search, "i"],
        ] as FilterSpec;
      }
      return get_labels_req({
        query: {
          filter,
          offset,
          limit,
          include_count: true,
        },
      });
    },
    []
  );

  const create_label_req = useApiMethod("createGroupLabel");
  const create_label = useCallback(
    (label: GroupLabel) => {
      return create_label_req({
        body: {
          data: {
            attributes: {
              label: label.label,
            },
            relationships: {
              community: {
                data: {
                  id: communityId,
                },
              },
            },
          },
        },
      });
    },
    [communityId]
  );

  const add_label_req = useApiMethod("addGroupLabels");
  const add_label = useCallback(
    (label: GroupLabel) => {
      if (!label) {
        return Promise.reject("No label value (for existing label)");
      }
      if (!label.id) {
        return Promise.reject(
          "Custom labels must be created before they can be added to a group."
        );
      }
      return add_label_req(group.id, {
        body: {
          data: [{ id: label.id }],
        },
      });
    },
    [group.id]
  );

  const create_and_add_label_req = useRequestChain<
    (label: GroupLabel) => Promise<MappedApiResponse<"createGroupLabel">>,
    (label: GroupLabel) => Promise<MappedApiResponse<"addGroupLabels">>,
    GroupLabel
  >(create_label, add_label, combine_results, pass_req1_params);
  const create_and_add_label = useCallback(
    (label: GroupLabel): Promise<GroupLabel> => {
      if (label.id) {
        if (labels_ref.current?.find(l => l.id === label.id)) {
          return Promise.reject("This label is already added to the group.");
        }
        return add_label(label).then(() => label);
      } else {
        // @ts-expect-error:
        return create_and_add_label_req([label], []);
      }
    },
    [add_label, set_labels]
  );

  const on_submit = useCallback(() => {
    if (!label_value) {
      return Promise.reject("No value for label");
    }
    return create_and_add_label(label_value).then(res => {
      setTimeout(() => {
        onAdd(res);
        set_label(undefined);
      }, 1200);
    });
  }, [create_and_add_label, onAdd, label_value]);
  const [submit_form, status] = useRequest(on_submit, true);

  const filter_opts = useCallback((opt: GroupLabel) => {
    return compareGroupLabels(opt, labels_ref.current);
  }, []);

  useEffect(
    () => () => {
      onFinish(labels_ref.current || []);
    },
    []
  );

  return (
    <DefaultModalContent
      title="Labels"
      closeButton={closeButton}
      footer={
        <div className="modal__footer">
          <div className="modal__footer__left">
            <FooterError error={status.error} />
          </div>
          <div className="modal__footer__right">
            <ButtonWithIcon
              icon="checked"
              className="filled gray"
              onClick={dismiss}
            >
              Done
            </ButtonWithIcon>
          </div>
        </div>
      }
    >
      <div className="group-label__modal__body">
        <div className="form__input-row">
          <InputWithDropdown
            className="tag-input"
            allowCustom={true}
            async={true}
            onChange={set_label}
            value={label_value}
            createOption={create_opt}
            createCustomOptText={create_text}
            getTextFromSelected={getGroupLabelText}
            renderSelected={renderGroupLabelTag}
            placeholder="Labels"
            getOptions={get_labels}
            filterOption={filter_opts}
          >
            <FloatingTitle>Group Labels</FloatingTitle>
          </InputWithDropdown>
          <RequestButtonWithIcon
            pending={status.pending}
            success={status.success}
            successIcon="checked"
            icon="add"
            side="left"
            className="filled gray"
            disabled={!label_value}
            onClick={label_value ? submit_form : undefined}
          />
        </div>
        {labels && labels.length > 0 ? (
          <div className="group-label__list">
            {labels.map((label: GroupLabel) => (
              <CommunityGroupLabelItem
                key={label.id}
                label={label}
                groupId={group.id}
                onRemove={onRemove}
              />
            ))}
          </div>
        ) : null}
      </div>
    </DefaultModalContent>
  );
};

export const CommunityGroupLabelItem: Preact.FunctionComponent<{
  label: GroupLabel;
  groupId: string;
  onRemove?: (label: GroupLabel) => void;
}> = ({ label, groupId, onRemove }) => {
  const id = `label-${get_guid_from_iri(label.id)[0]}-remove`;
  const [open, set_open, trigger_props] = usePopoverTrigger(
    { click: true, focus: false, hover: false },
    id
  );

  const remove_label_req = useApiMethod("removeGroupLabels");
  const remove_label = useCallback(() => {
    return remove_label_req(groupId, {
      body: {
        data: [
          {
            id: label.id,
          },
        ],
      },
    }).then(() => {
      setTimeout(() => {
        set_open(false);
      }, 800);
      setTimeout(() => {
        if (!onRemove) {
          return;
        }
        onRemove(label);
      }, 1200);
    });
  }, [onRemove, groupId, label]);
  const [on_click_remove, status] = useRequest(remove_label);

  const tag = (
    <Tag
      className="filled gray all-gray group-label"
      onRemove={onRemove ? trigger_props.onClick : undefined}
      removeButtonProps={
        onRemove ? { ...trigger_props, onClick: undefined } : {}
      }
    >
      {label.label}
    </Tag>
  );

  if (!onRemove) {
    return tag;
  }

  return (
    <Popover
      id={id}
      key={id}
      triggerComponent={tag}
      show={open || status.pending || status.success}
      defaultOffset="left"
    >
      <div className="group-touchpoint__remove-warning">
        <h5>Do you want to remove the tag "{label.label}" from this group?</h5>
        <div className="group-touchpoint__remove-warning__buttons">
          <button
            className="bordered pill"
            type="button"
            onClick={() => set_open(false)}
          >
            Cancel
          </button>
          <RequestButton
            className="filled negative pill"
            type="button"
            successText="Success!"
            onClick={on_click_remove}
            {...status}
            showError={true}
          >
            Confirm
          </RequestButton>
        </div>
      </div>
    </Popover>
  );
};

export const CommunityGroupLabelButton: Preact.FunctionComponent<{
  group: Group;
  communityId: string;
}> = ({ group, communityId }) => {
  const dispatch = useContext(GROUP_DETAIL_CONTEXTS.dispatch);
  const onFinish = useCallback(
    (labels: GroupLabel[]) => {
      dispatch("group", {
        ...group,
        has_label: labels,
      });
    },
    [dispatch, group]
  );

  const bodyProps = useMemo(
    () => ({ group, communityId, onFinish }),
    [onFinish, group, communityId]
  );

  const [modal, set_open] = useModal({
    body: CommunityGroupLabelModal,
    id: "group-labels",
    className: "group-label__modal",
    showCloseButton: true,
    bodyProps,
  });

  return (
    <Preact.Fragment>
      <ButtonWithIcon
        icon="label"
        className="group-label__icon transparent filled pill"
        onClick={() => set_open(true)}
      />
      {modal}
    </Preact.Fragment>
  );
};
