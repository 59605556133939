import { entity_has_type } from "@thrive-web/ui-common";
import * as Preact from "preact";
import { StateUpdater, useCallback, useMemo } from "preact/hooks";
import {
  useApiMethod,
  useApiRelationshipMethod,
  useModal,
  useRenderPropsFunction,
} from "@thrive-web/ui-hooks";
import { Community, Group, User } from "@thrive-web/ui-api";
import {
  Avatar,
  ButtonWithIcon,
  DefaultDropdownButtonDiv,
  DefaultModalContent,
  DeleteModalBody,
  DropdownMenu,
  LinkWithIcon,
  UserCard,
} from "@thrive-web/ui-components";
import {
  get_guid_from_iri,
  get_url_for_entity,
  user_is_group_admin,
} from "@thrive-web/ui-utils";
import { get_user_community_role } from "~/utils";
import { MemberListItem, RoleSelectorModal } from "~/view/components";
import { ScopeRole } from "~/view/utils";

export const UserMemberListItem: Preact.FunctionComponent<{
  user: User;
  role?: string;
  onClickRemove: (user: User) => void;
  onClickChangeRole?: (user: User) => void;
}> = ({ user, role, onClickRemove, onClickChangeRole }) => {
  return (
    <MemberListItem
      icon={<Avatar user={user} size="md" isLink={false} />}
      title={user.full_name || ""}
      entity={user}
      status={role}
      actions={
        <DropdownMenu
          listClassName="card pill-card"
          button={
            <DefaultDropdownButtonDiv
              icon="more"
              className="button filled transparent all-gray"
            />
          }
          items={[
            onClickChangeRole ? (
              <ButtonWithIcon
                icon="edit"
                side="left"
                className="filled pill gray"
                onClick={() => onClickChangeRole(user)}
              >
                Change Role
              </ButtonWithIcon>
            ) : null,
            <LinkWithIcon
              icon="profile"
              side="left"
              className="filled pill button gray"
              href={get_url_for_entity(user)}
            >
              View Profile
            </LinkWithIcon>,
            <ButtonWithIcon
              icon="trash"
              side="left"
              className="filled pill negative"
              onClick={() => onClickRemove(user)}
            >
              Remove Member
            </ButtonWithIcon>,
          ]}
        />
      }
    />
  );
};

export const useMemberRemove = <T extends Community | Group>(
  scope: T | null,
  targetUser: User | null,
  setTargetUser: StateUpdater<User | null>,
  onRemove?: (id: string) => void,
  highlightScope?: boolean
) => {
  const from_type = useMemo(
    () => (scope ? get_guid_from_iri(scope.id)[1] : undefined),
    [scope]
  );
  const removeMemberReq = useApiMethod(
    from_type === "Group" ? "removeGroupMember" : "removeCommunityMember"
  );
  const remove_admin_req = useApiMethod("removeGroupAdmin");
  const remove_role_req = useApiRelationshipMethod("Community", "DELETE");
  const removeMember = useCallback(() => {
    if (!targetUser || !scope || !scope) {
      return Promise.resolve();
    }
    const params = {
      body: { data: [{ id: targetUser.id }] },
    };
    return removeMemberReq(scope.id, params).then(res => {
      if (from_type !== "Community" && user_is_group_admin(targetUser, scope)) {
        return remove_admin_req(scope.id, params).then(() => res);
      }
      const role = get_user_community_role(targetUser, scope);
      if (role !== "has_member") {
        return remove_role_req(
          role,
          scope.id,
          // @ts-expect-error:
          params
        ).then(() => res);
      }
      return res;
    });
  }, [removeMemberReq, scope, targetUser]);

  const afterRemove = useCallback(() => {
    setTargetUser(null);
    targetUser && onRemove && onRemove(targetUser.id);
  }, [targetUser, setTargetUser, onRemove]);
  const MemberRemoveModalBody = useRenderPropsFunction<ModalBodyProps>(
    ({ closeButton, ...props }) =>
      targetUser && scope && from_type ? (
        <DefaultModalContent
          title={`Remove ${from_type} Member`}
          closeButton={closeButton}
        >
          <DeleteModalBody
            deleteRecord={removeMember}
            afterDelete={afterRemove}
            hideWarning={true}
            {...props}
          >
            <div className="modal__delete-record__user">
              {highlightScope && from_type === "Group" ? (
                <div className="group-card">
                  <div
                    className="group-dot"
                    data-size="xl"
                    style={
                      (scope as Group).background_color
                        ? { backgroundColor: (scope as Group).background_color }
                        : undefined
                    }
                  />
                  <h1>{scope.name}</h1>
                </div>
              ) : (
                <UserCard
                  user={targetUser}
                  avatarIsLink={false}
                  nameIsLink={false}
                  size="xl"
                />
              )}
            </div>
            Are you sure you want to remove this {targetUser.first_name} from
            the {from_type.toLowerCase()} {scope.name}?
          </DeleteModalBody>
        </DefaultModalContent>
      ) : null,
    "MemberRemoveModal-Bound",
    [self, removeMember, targetUser, afterRemove]
  );

  const [removeMemberModal, openRemoveMemberModal] = useModal({
    id: "remove-member-modal",
    innerClassName: "card card-stack modal-form member-list__remove__modal",
    body: MemberRemoveModalBody,
    giveTabFocus: true,
    dismissOnClickBackdrop: true,
  });

  const onClickRemove = useCallback(
    (user: User) => {
      setTargetUser(user);
      openRemoveMemberModal(true);
    },
    [setTargetUser, openRemoveMemberModal]
  );

  return [onClickRemove, removeMemberModal] as const;
};

export const useMemberRoleUpdate = <T extends Community | Group>(
  scope: T | null,
  targetUser: User | null,
  setTargetUser: StateUpdater<User | null>,
  onChange?: (scope: T) => void
) => {
  /*const from_type = useMemo<"Group" | "Community">(() => entity_has_type(scope, "Group") ? "Group" : "Community", [scope.id]);
  const removeMemberReq = useApiMethod(
    from_type === "Group" ? "removeGroupMember" : "removeCommunityMember"
  );
  const removeMember = useCallback(() => {
    if (!targetUser) {
      return Promise.resolve();
    }
    return removeMemberReq(scope.id, {
      body: { data: [{ id: targetUser.id }] },
    });
  }, [removeMemberReq, scope.id, targetUser]);*/

  const onFinish = useCallback(
    updated_scope => {
      setTargetUser(null);
      onChange?.(updated_scope);
    },
    [targetUser, setTargetUser, onChange]
  );

  const initialRole = useMemo<ScopeRole<T>>(
    // @ts-expect-error:
    () => {
      if (!scope || !targetUser) {
        return "has_member";
      }
      if (entity_has_type(scope, "Group")) {
        return scope.has_admin?.find(u => u.id === targetUser?.id)
          ? "has_admin"
          : "has_member";
      }
      return get_user_community_role(targetUser, scope);
    },
    [targetUser?.id, scope?.id]
  );

  const bodyProps = useMemo(
    () => ({ scope, targetUser, onFinish, initialRole }),
    [scope, targetUser, onFinish, initialRole]
  );

  const [updateMemberRoleModal, openMemberRoleModal] = useModal({
    id: "update-member-role-modal",
    innerClassName: "card card-stack modal-form member-list__role__modal",
    body: RoleSelectorModal,
    giveTabFocus: true,
    dismissOnClickBackdrop: true,
    bodyProps,
  });

  const onClickChangeRole = useCallback(
    (user: User) => {
      setTargetUser(user);
      openMemberRoleModal(true);
    },
    [setTargetUser, openMemberRoleModal]
  );

  return [onClickChangeRole, updateMemberRoleModal] as const;
};
