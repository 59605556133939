import { FilterSpec } from "@thrive-web/core";
import * as Preact from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { Community, Group, User } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  DefaultModalContent,
  GroupMemberInviteLinkButton,
  RemovableListItem,
  RemovableListItemComponentProps,
  RequestButton,
  SelectUserModal,
  UserInviteListItem,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useApiRequest,
  useModal,
  useRenderPropsFunction,
  useStateRef,
} from "@thrive-web/ui-hooks";
import { get_guid_from_iri } from "@thrive-web/ui-utils";

export const UserMemberInvite: Preact.FunctionComponent<{
  record: Group | Community | null;
  allowLink?: boolean;
  onInvite?: (user: User) => void;
  scope?: Community;
}> = ({ record, scope, allowLink, onInvite }) => {
  const bodyProps = useMemo(
    () => ({
      record,
      allowLink,
      onInvite,
      scope,
    }),
    [record, allowLink, onInvite, scope]
  );

  const [modal, set_open] = useModal({
    body: UserMemberInviteModal,
    id: "member-invite",
    className: "member-invite__modal modal-form",
    showCloseButton: true,
    bodyProps,
  });

  if (!record) {
    return null;
  }

  return (
    <ButtonWithIcon
      icon="add"
      side="left"
      className="filled gray"
      onClick={() => set_open(true)}
    >
      Add Member
      {modal}
    </ButtonWithIcon>
  );
};

export const UserMemberInviteModal: Preact.FunctionComponent<
  ModalBodyProps & {
    allowLink?: boolean;
    record: Group | Community;
    onInvite?: (user: User) => void;
    scope?: Community;
  }
> = ({ dismiss, closeButton, onInvite, allowLink, record, scope }) => {
  const id = record?.id;
  const fetchUsersReq = useApiMethod("getUsers");
  const type = useMemo(() => (id ? get_guid_from_iri(id)[1] : undefined), [id]);

  const [selected, set_selected, selected_ref] = useStateRef<User[]>([]);
  const onAddMember = useCallback((user: User) => {
    set_selected(selected_ref.current.concat(user));
    onInvite?.(user);
  }, []);

  const search_members = useCallback(
    (search: string, offset: number, limit?: number) => {
      if (!id) {
        return Promise.reject("No group/community id provided");
      }
      if (!type) {
        return Promise.reject(`Could not get record type from id '${id}'`);
      }
      let filter_clause: FilterSpec = [
        ["not", ["=", ["this", ["^", `${type}:has_member`]], ["id", id]]],
      ];
      if (scope) {
        filter_clause = [
          ...filter_clause,
          ["=", ["this", ["^", `Community:has_member`]], ["id", scope.id]],
        ];
      }
      if (search) {
        filter_clause = [
          ...filter_clause,
          ["match", ["this", "User:full_name"], search, "i"],
        ];
      }

      return fetchUsersReq({
        query: {
          filter: filter_clause,
          offset,
          limit,
          include: ["profile_picture"],
          include_count: true,
        },
      });
    },
    [id, type]
  );

  const renderUser = useRenderPropsFunction(
    (u: User, _, remove_on_select?: (user: User) => void) => (
      <MemberInviteListItem
        key={u.id}
        user={u}
        onInvite={onAddMember}
        removeOnInvite={remove_on_select}
        id={id}
        invited={!!selected.find(m => m.id === u.id)}
      />
    ),
    "MemberInviteListItem-User",
    [onAddMember, id, selected]
  );

  return (
    <DefaultModalContent title="Invite a Member" closeButton={closeButton}>
      <div className="member-invite__modal__body">
        <SelectUserModal
          selected={selected}
          getUsers={search_members}
          renderUser={renderUser}
          emptyLabel="No users available to invite"
        >
          {allowLink && type === "Group" ? (
            <div className="member-invite__modal__share">
              <GroupMemberInviteLinkButton groupId={id} />
            </div>
          ) : undefined}
        </SelectUserModal>
        <div className="modal__footer">
          <div />
          <ButtonWithIcon
            className="filled gray"
            icon="checked"
            side="right"
            type="submit"
            onClick={dismiss}
          >
            Save & Finish
          </ButtonWithIcon>
        </div>
      </div>
    </DefaultModalContent>
  );
};

type MemberInviteProps = {
  user: User;
  onInvite: (user: User) => void;
  removeOnInvite?: (user: User) => void;
  id: string;
  invited: boolean;
};
export const MemberInviteListItemBase: Preact.FunctionComponent<
  RemovableListItemComponentProps<MemberInviteProps>
> = ({ user, id, invited, onRemoveItem }) => {
  const type = useMemo(() => (id ? get_guid_from_iri(id)[1] : undefined), [id]);
  const req_params = useMemo(
    () => ({
      body: { data: [{ id: user.id }] },
    }),
    [user?.id]
  );
  const [send_request, { pending, success, error }] = useApiRequest(
    type === "Group" ? "addGroupMember" : "addCommunityMember",
    id,
    req_params
  );
  const addMember = useCallback(() => {
    if (invited) {
      return;
    }
    send_request().then(onRemoveItem);
  }, [send_request, user, onRemoveItem, invited]);

  return (
    <UserInviteListItem
      user={user}
      button={
        <RequestButton
          className={`filled ${success ? "success" : "gray"}`}
          pending={pending}
          success={success || invited}
          successText="Invited"
          error={error}
          showError={true}
          onClick={addMember}
        >
          Invite
        </RequestButton>
      }
    />
  );
};

export const MemberInviteListItem: Preact.FunctionComponent<MemberInviteProps> =
  props => {
    const onRemoveItem = useCallback(() => {
      props.removeOnInvite?.(props.user);
      props.onInvite(props.user);
      return Promise.resolve();
    }, [props.onInvite, props.user, props.removeOnInvite]);
    return (
      <RemovableListItem<
        RemovableListItemComponentProps<MemberInviteProps>,
        User
      >
        {...props}
        delay={1000}
        Component={MemberInviteListItemBase}
        onRemoveItem={onRemoveItem}
      />
    );
  };
