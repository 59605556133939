import { RELATIONSHIP_GROUP_MEMBER_LIMIT } from "@thrive-web/ui-constants";
import * as Preact from "preact";
import {
  GROUP_DETAIL_CONTEXTS,
  PageSidebar,
  SidebarSection,
  GroupHealthGraph,
  GroupConnectionScoreStatic,
} from "@thrive-web/ui-components";
import { useContext, useMemo } from "preact/hooks";

export const CommunityGroupDetailSidebar: Preact.FunctionComponent = () => {
  const group = useContext(GROUP_DETAIL_CONTEXTS.group);
  const items = useMemo(() => {
    const items: any[] = [];
    if (!group) {
      return items;
    }
    if (group.has_relationship_management) {
      items.push({
        name: "Group Connection Score",
        children: (
          <div className="group-sidebar__score card">
            <div className="group-sidebar__score__left">
              <GroupConnectionScoreStatic score={group.score || 0} />
            </div>
            <div className="group-sidebar__score__right">
              <h4>Keep it up!</h4>
              <p>
                Keep connecting and updating this group to continue to level up.
              </p>
            </div>
          </div>
        ),
      });
    }
    if (
      group.has_member?.length &&
      group.has_member.length > 1 &&
      group.has_member.length <= RELATIONSHIP_GROUP_MEMBER_LIMIT
    ) {
      items.push({
        name: "Relationship Health",
        // moreLink: <AppLink href={`${url}/members`}>View All</AppLink>,
        children: <GroupHealthGraph group={group} />,
      });
    }
    return items;
  }, [group]);
  return (
    <PageSidebar className="group-sidebar">
      {items.map((props, i) => (
        <SidebarSection {...props} key={i} />
      ))}
    </PageSidebar>
  );
};
