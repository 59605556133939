import { User } from "@thrive-web/ui-api";
import {
  Card,
  PageSidebar,
  SidebarSection,
  UserProfileContactItem,
} from "@thrive-web/ui-components";
import * as Preact from "preact";
import { useContext, useMemo } from "preact/hooks";
import {
  ACTIVE_COMMUNITY,
  MEMBER_DETAIL_CONTEXTS,
  RoleButton,
} from "~/view/components";

export const CommunityMemberDetailSidebar: Preact.FunctionComponent<{
  onClickChangeRole: (user: User) => void;
}> = ({ onClickChangeRole }) => {
  const comm = useContext(ACTIVE_COMMUNITY);
  const user = useContext(MEMBER_DETAIL_CONTEXTS.user);

  if (!user || !comm) {
    return null;
  }
  return (
    <PageSidebar className="user-profile__sidebar">
      <SidebarSection name="Community Role">
        <RoleButton user={user} onClick={onClickChangeRole} />
      </SidebarSection>
    </PageSidebar>
  );
};

export const CommunityMemberDetailInfo: Preact.FunctionComponent<{
  onClickChangeRole: (user: User) => void;
}> = ({ onClickChangeRole }) => {
  const comm = useContext(ACTIVE_COMMUNITY);
  const user = useContext(MEMBER_DETAIL_CONTEXTS.user);

  const linkProps = useMemo(
    () => ({
      user: user!,
      noInteraction: true,
    }),
    [user?.id]
  );

  if (!user || !comm) {
    return null;
  }
  return (
    <div className="member-profile__info">
      <SidebarSection
        name="Community Role"
        className="member-profile__info__role"
      >
        <RoleButton user={user} onClick={onClickChangeRole} />
      </SidebarSection>
      <Card className="user-profile__card user-profile__contact__card">
        <div className="user-profile__card__header">
          <h3 className="user-profile__card__title">Contact Info</h3>
        </div>
        <div className="user-profile__contact__card__list">
          {user.email && (
            <UserProfileContactItem type="email" linkProps={linkProps}>
              {user.email}
            </UserProfileContactItem>
          )}
          {user.phone_number && (
            <UserProfileContactItem type="phone" linkProps={linkProps}>
              {user.phone_number}
            </UserProfileContactItem>
          )}
        </div>
      </Card>
    </div>
  );
};
