import { Community, MappedApiResponse } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  DefaultErrorView,
  DefaultModalContent,
  DefaultPendingView,
  DropdownSelectInputAsync,
  DropdownSelectInputDefaultTextInput,
  DynamicListDispatch,
  EmptyList,
  InfiniteScrollLoader,
  LazyListSection,
  RadioButton,
  SearchBar,
  useLazyList,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useAppUser,
  useDynamicListVariable,
  useModal,
  useResettableRequest,
  useStateIfMounted,
  useStateRef,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { RequestStatus, should_leave } from "@thrive-web/ui-model";
import { maybeClassName } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import { mngd_communities_filter } from "~/utils";
import {
  ACTIVE_COMMUNITY,
  MNGD_COMMUNITIES,
  SET_ACTIVE_COMMUNITY,
} from "~/view/components";

export interface CommunitySelectorComponentProps extends MaybeClass {
  value: Community | undefined;
  onChange: (comm: Community) => void;
  status: RequestStatus;
  fetch: (
    offset: number,
    limit?: number
  ) => Promise<MappedApiResponse<"getCommunities">>;
  list: readonly Community[] | null;
  dispatch: DynamicListDispatch<Community, false, [number, number?]>;
  search: string;
  onChangeSearch: (search: string) => void;
  total: number;
}

export const CommunitySelector: Preact.FunctionComponent<
  MaybeClass & {
    Component: Preact.ComponentType<CommunitySelectorComponentProps>;
  }
> = ({ Component, className }) => {
  const self = useAppUser();
  const { list: ctx_list, dispatch: ctx_dispatch } =
    useContext(MNGD_COMMUNITIES);
  const comm = useContext(ACTIVE_COMMUNITY);
  const set_comm = useContext(SET_ACTIVE_COMMUNITY);
  const on_change = useCallback(
    (new_comm: Community) => {
      if (should_leave()) {
        set_comm(new_comm);
      }
    },
    [set_comm]
  );
  const [search, set_search, search_ref] = useStateRef("");
  const [total, set_total] = useStateIfMounted(0);
  const [list, search_dispatch] = useDynamicListVariable(ctx_list);

  const fetch_comms_req = useApiMethod("getCommunities");
  const fetch_comms = useCallback(
    (offset: number, limit?: number) =>
      fetch_comms_req({
        query: {
          filter: mngd_communities_filter(self, search),
          include_count: true,
          // include: ["has_member"],
          offset,
          limit,
        },
      }).then(res => {
        if (res.meta?.total_result_count) {
          set_total(res.meta.total_result_count as number);
        }
        return res;
      }),
    [self?.id, search]
  );

  const [get_comms, status, reset] = useResettableRequest(fetch_comms);

  useEffect(() => reset, [search]);

  const selected = useMemo(
    () => (comm?.name ? comm : list?.find(c => c.id === comm?.id)),
    [comm, list]
  );

  const dispatch = useMemo(
    () =>
      ({
        reset: (c: Community[] | readonly Community[] | null) =>
          !search_ref.current
            ? ctx_dispatch.reset(c)
            : search_dispatch.reset(c),
        concat: (c: Community[] | readonly Community[]) =>
          !search_ref.current
            ? ctx_dispatch.concat(c)
            : search_dispatch.concat(c),
      } as DynamicListDispatch<Community, false, [number, number?]>),
    [search_dispatch, ctx_dispatch]
  );

  return (
    <Component
      value={selected}
      onChange={on_change}
      search={search}
      onChangeSearch={set_search}
      total={total}
      fetch={get_comms}
      list={!search ? ctx_list : list}
      dispatch={dispatch}
      status={status}
      className={className}
    />
  );
};

const get_community_text = (comm: Community) => comm.name || comm.id;
const popover_props: Partial<PopoverProps> = {
  forceDirection: "bottom",
  id: "community-selector-dropdown",
};

export const CommunitySelectorDropdown: Preact.FunctionComponent<CommunitySelectorComponentProps> =
  ({ value, onChange, fetch, list, dispatch, total, className }) => {
    if (total === 1) {
      return value ? (
        <div
          className={`fake-input community-select__dropdown${maybeClassName(
            className
          )}`}
        >
          <div>{value?.name}</div>
        </div>
      ) : null;
    }

    return (
      <DropdownSelectInputAsync
        className={`community-select__dropdown${maybeClassName(className)}`}
        value={value}
        options={(list || []) as Community[]}
        onSelect={onChange}
        getValueLabel={get_community_text}
        popoverProps={popover_props}
        mountLocal={true}
        label="Select a community"
        Button={DropdownSelectInputDefaultTextInput}
        fetch={fetch}
        resetList={dispatch.reset}
        concatList={dispatch.concat}
      />
    );
  };

export const CommunitySelectorModalBody: Preact.FunctionComponent<
  ModalBodyProps & CommunitySelectorComponentProps
> = ({
  dismiss,
  closeButton,
  value,
  onChange,
  status,
  fetch,
  list,
  dispatch,
  search,
  onChangeSearch,
  total,
}) => {
  const list_ref = useValueRef(list);
  const [offset, set_offset] = useStateIfMounted(0);
  useEffect(() => () => set_offset(0), [fetch]);
  useEffect(() => {
    fetch(offset).then(res => {
      offset === 0 ? dispatch.reset(res.data) : dispatch.concat(res.data);
    });
  }, [offset, fetch]);
  const on_load_more = useCallback(
    () =>
      list_ref.current?.length != null &&
      set_offset(list_ref.current.length || 0),
    []
  );

  const [checked, set_checked] = useStateIfMounted(value);
  const on_finish = useCallback(() => {
    if (checked && checked.id !== value?.id) {
      onChange(checked);
    }
    dismiss();
  }, [onChange, dismiss, checked?.id, value?.id]);

  const content = useLazyList(
    list || [],
    comm => (
      <CommunitySelectorModalItem
        community={comm}
        onSelect={set_checked}
        selected={comm.id === checked?.id}
      />
    ),
    [set_checked, checked?.id],
    10,
    get_lazy_list_item_key
  );

  return (
    <DefaultModalContent title="Select a Community" closeButton={closeButton}>
      <div className="member-invite__modal__body">
        <div className="invite-users-modal stack">
          <div className="invite-users-modal__search">
            <SearchBar
              placeholder="Search by Name"
              onSubmit={onChangeSearch}
              autoSubmitDelay={750}
              pending={status.pending && offset === 0 && !!list}
              value={search}
            />
          </div>
          <div
            className="invite-users-modal__people stack__scrolling-content"
            data-empty={`${!list || !list.length}`}
          >
            {status.error && !status.pending ? (
              <DefaultErrorView error={status.error} />
            ) : list ? (
              list.length ? (
                <div className="invite-users-modal__people__list">
                  {content.map((s, i) => (
                    <LazyListSection key={i}>{s}</LazyListSection>
                  ))}
                  {total === -1 ||
                  (list.length >= total && !status.pending) ? null : (
                    <InfiniteScrollLoader
                      nextPage={on_load_more}
                      pending={status.pending}
                      error={status.error}
                    />
                  )}
                </div>
              ) : (
                <EmptyList>
                  No Groups found{search ? ` for "${search}".` : "."}
                </EmptyList>
              )
            ) : (
              <DefaultPendingView />
            )}
          </div>
        </div>
        <div className="modal__footer">
          <div />
          <ButtonWithIcon
            className="filled gray"
            icon="checked"
            side="right"
            type="submit"
            onClick={on_finish}
          >
            Save & Finish
          </ButtonWithIcon>
        </div>
      </div>
    </DefaultModalContent>
  );
};

const get_lazy_list_item_key = item => item.id;
export const CommunitySelectorModalItem: Preact.FunctionComponent<
  MaybeClass & {
    community: Community;
    onSelect: (comm: Community) => void;
    selected: boolean;
  }
> = ({ community, onSelect, selected, className }) => {
  return (
    <div
      className={`invite-users-modal__person${maybeClassName(className)}`}
      onClick={() => onSelect(community)}
    >
      <div className="invite-users-modal__person__left">
        <div className="user-card__name">{community.name}</div>
      </div>
      <div className="invite-users-modal__person__right">
        <RadioButton name="selected-community" checked={selected} label="" />
      </div>
    </div>
  );
};

export const CommunitySelectorModal: Preact.FunctionComponent<CommunitySelectorComponentProps> =
  props => {
    const [modal, set_open] = useModal({
      id: "community-select-modal",
      className: `community-select__modal modal-form${maybeClassName(
        props.className
      )}`,
      body: CommunitySelectorModalBody,
      bodyProps: props,
      showCloseButton: true,
    });

    return (
      <ButtonWithIcon
        className="site-nav__link filled gray pill"
        icon="edit"
        side="left"
        onClick={() => set_open(true)}
      >
        {props.value?.name || "Select a community"}
        {modal}
      </ButtonWithIcon>
    );
  };
