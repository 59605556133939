import * as hooks from "preact/hooks";
import { FilterSpec } from "@thrive-web/core";
import { useApiMethod, useStateRef } from "@thrive-web/ui-hooks";
import { get_guid_from_iri } from "@thrive-web/ui-utils";

export const useMemberSearch = (id?: string, search?: string) => {
  const fetchMembersReq = useApiMethod("getUsers");
  const type = hooks.useMemo(
    () => (id ? get_guid_from_iri(id)[1] : undefined),
    [id]
  );
  const [num, set_num, num_ref] = useStateRef(0);
  const force_refresh = hooks.useCallback(
    () => set_num(num_ref.current + 1),
    []
  );

  const get_members = hooks.useCallback(
    (offset: number, limit?: number) => {
      if (!id) {
        return Promise.reject("No group/community id provided.");
      }
      if (!type) {
        return Promise.reject(`Could not get record type from id '${id}'`);
      }
      let filter: FilterSpec = [
        ["=", ["this", ["^", `${type}:has_member`]], ["id", id]],
      ];
      if (search) {
        filter = [
          ...filter,
          ["match", ["this", "User:full_name"], search, "i"],
        ] as FilterSpec;
      }
      return fetchMembersReq({
        query: {
          filter,
          offset,
          limit,
          include: ["profile_picture"],
          include_count: true,
        },
      });
    },
    [id, type, search, num]
  );

  return [get_members, force_refresh] as const;
};
