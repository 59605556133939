const config = require("~/../site/env.json");
const { env } = config;

// @ts-expect-error:
window.thread = {
  config,
};
if (["local", "dev", "develop", "development"].includes(env)) {
  require("preact/debug");
}
import * as Preact from "preact";
import authWrapper from "~/auth/auth";
import api from "~/api";
import { IS_ADMIN_UI, TaxonomiesProvider } from "@thrive-web/ui-components";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";
import {
  Provider,
  Model,
  AppModel,
  initRoutingListeners,
  CONTEXTS,
} from "@thrive-web/ui-model";
import { View, ViewWrapper } from "~/view";
import { verifyAuthStateAndRoute } from "~/model";
import { EmailNotVerifiedSite } from "~/view/components";
import { INITIAL_APP_STATE } from "./initial-state";
import { initialize } from "./utils";
import "@thrive-web/ui-utils/src/tab-focus-manager";

if (
  !["true", "TRUE", "1"].includes(
    process.env.THRIVE_UI_DEBUG_LOGS_ENABLED || ""
  ) &&
  !["local", "dev", "develop", "development"].includes(env)
) {
  console.debug(`disabling logging`);
  window.console.debug = (...args) => {};
}
// initialize zendesk config
initialize();

const { Provider: IAProvider } = IS_ADMIN_UI;
// root app component, mounts the AppContexts provider, Taxonomies provider, and app View
const App: Preact.FunctionComponent<{ model: AppModel }> = ({ model }) => {
  return (
    <IAProvider value={true}>
      <Provider model={model} authWrapper={authWrapper} contexts={CONTEXTS}>
        <TaxonomiesProvider>
          <ViewWrapper>
            <View />
          </ViewWrapper>
        </TaxonomiesProvider>
      </Provider>
    </IAProvider>
  );
};

// displayed during initial auth check
const AppLoading: Preact.FunctionComponent<{ auth: boolean }> = ({ auth }) => (
  <div className="app-loading">
    <div className="app-loading__spinner">
      <img src={`${IMAGES_PREFIX}/logo_spinner.gif`} />
    </div>
    <div className="app-loading__status">
      {auth ? "Logging in" : "Authenticating"}
    </div>
  </div>
);

const AppLoadingFailed: Preact.FunctionComponent<{ error: any }> = ({
  error,
}) => {
  const str = JSON.stringify(error);
  return (
    <div className="app-loading__failed">
      <h1>The web app failed to initialize.</h1>
      <h4>Refresh the page to try again</h4>
      <pre>{str === "{}" ? error.toString() : str}</pre>
    </div>
  );
};

const container = document.getElementById("app");
if (container) {
  Preact.render(<AppLoading auth={false} />, container);

  api.setAuthWrapper(authWrapper);
  authWrapper
    // check firebase authentication
    .initialize()
    .then(async initialAuthCtx => {
      if (initialAuthCtx.firebaseUser) {
        if (initialAuthCtx.firebaseUser.emailVerified) {
          Preact.render(
            <AppLoading auth={true} />,
            container,
            container.lastElementChild || undefined
          );
        } else {
          if (window.location.pathname === "/logout") {
            await authWrapper.logout();
            window.location.reload();
          } else {
            Preact.render(<EmailNotVerifiedSite />, container);
            return;
          }
        }
      }

      // login to api (if possible) and get initial route based on auth state
      const initialRoute = (await verifyAuthStateAndRoute(
        initialAuthCtx,
        window.location.pathname
      )) as string | undefined;
      INITIAL_APP_STATE.auth = authWrapper.getAuthState();
      if (initialRoute && initialRoute !== window.location.pathname) {
        window.history.pushState({}, document.title, initialRoute);
      }
      console.debug(`initialAuthCtx: `, INITIAL_APP_STATE.auth);
      const model = Model(INITIAL_APP_STATE);

      initRoutingListeners();

      Preact.render(
        <App model={model} />,
        container,
        container.lastElementChild || undefined
      );
    })
    .catch(error => {
      console.error(`error: `, error);
      Preact.render(
        <AppLoadingFailed error={error} />,
        container,
        container.lastElementChild || undefined
      );
    });
}
