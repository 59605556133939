import { Community, User } from "@thrive-web/ui-api";
import { is_valid_email, is_valid_phone_number } from "@thrive-web/ui-common";
import {
  DropdownSelectInput,
  DropdownSelectInputDefaultTextInput,
  ErrorMessage,
  InputWithFormHelpers,
  PhoneNumberInput,
  RequestButton,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useApiRelationshipMethod,
  useAppUser,
  useDirtyForm,
  useForm,
  useRequest,
} from "@thrive-web/ui-hooks";
import { set_dirty } from "@thrive-web/ui-model";
import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { get_user_community_role } from "~/utils";
import {
  ACTIVE_COMMUNITY,
  COMMUNITY_ROLE_OPTIONS,
  get_role_label,
  MEMBER_DETAIL_CONTEXTS,
  SET_ACTIVE_COMMUNITY,
} from "~/view/components";
import { ScopeRole } from "~/view/utils";

export interface MemberCreateFormData {
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string;
  role: ScopeRole<Community>;
}

export const CommMemberCreate: Preact.FunctionComponent<{
  community: Community;
  email: string;
  onCreate: (user: User) => void;
}> = ({ email, onCreate, community }) => {
  const self = useAppUser();
  const comm = useContext(ACTIVE_COMMUNITY);
  const update_comm = useContext(SET_ACTIVE_COMMUNITY);

  const is_admin = useMemo(
    () =>
      self && comm
        ? get_user_community_role(self, comm) === "has_admin"
        : false,
    [self?.id, comm]
  );
  const create_user = useApiMethod("createUser");
  const add_to_comm = useApiMethod("addCommunityMember");
  const add_as_role = useApiRelationshipMethod("Community", "POST");
  const create_and_add = useCallback(
    (formdata: MemberCreateFormData) => {
      if (!comm) {
        return Promise.reject("No active community");
      }
      const { role = "has_member", ...attributes } = formdata;
      return create_user(undefined, { body: { data: { attributes } } }).then(
        ({ data }) => {
          if (!data) {
            return Promise.reject({
              message: "No data returned from user creation request.",
            });
          }
          return add_to_comm(comm.id, {
            body: { data: [{ id: data.id }] },
          })
            .then(({ data: new_members }) => {
              if (role !== "has_member") {
                return add_as_role(role, comm.id, {
                  // @ts-expect-error:
                  body: { data: [{ id: data.id }] },
                }).then(({ data: new_admins }) => {
                  update_comm({
                    ...comm,
                    [role]: new_admins,
                    // @ts-expect-error:
                    has_member: new_members,
                  });
                  return data;
                });
              } else {
                // @ts-expect-error:
                update_comm({ ...comm, has_member: new_members });
                return Promise.resolve(data);
              }
            })
            .then(data => {
              onCreate(data);
              return data;
            });
        }
      );
    },
    [comm?.id, add_as_role]
  );

  const initial_data = useMemo(() => ({ email }), [email]);

  return (
    <CommMemberForm
      initialData={initial_data}
      onSubmit={create_and_add}
      canChangeRole={is_admin}
    />
  );
};

export const CommMemberForm: Preact.FunctionComponent<{
  initialData: Partial<MemberCreateFormData>;
  onSubmit: (data: MemberCreateFormData) => Promise<User>;
  canChangeRole: boolean;
}> = ({ initialData, onSubmit, canChangeRole }) => {
  const existing_user = useContext(MEMBER_DETAIL_CONTEXTS.user);
  const [form_data, set_field, on_input_change] = useForm<MemberCreateFormData>(
    {
      first_name: "",
      last_name: "",
      email: "",
      role: "has_member",
      phone_number: "",
      ...initialData,
    }
  );
  useDirtyForm(form_data, "CommMemberCreate", true);

  const [on_submit_req, status] = useRequest(onSubmit);

  const on_submit = useCallback(
    e => {
      if (
        !form_data.email ||
        !form_data.last_name ||
        !form_data.first_name ||
        !form_data.role ||
        !is_valid_email(form_data.email) ||
        (!!form_data.phone_number &&
          !is_valid_phone_number(form_data.phone_number))
      ) {
        return;
      }
      e.preventDefault();
      // @ts-expect-error: already checked for empty values
      on_submit_req(form_data).then(() => {
        set_dirty(false, "CommMemberCreate");
      });
    },
    [form_data, onSubmit]
  );

  const on_change_role = useCallback(
    role => set_field("role", role),
    [set_field]
  );

  return (
    <form className="member-add__create" onSubmit={on_submit}>
      <div className="member-add__create__form">
        <div className="form__input-row">
          <div className="input__label">First Name</div>
          <InputWithFormHelpers
            onChange={on_input_change("first_name")}
            value={form_data.first_name}
            name="first_name"
            required={true}
          />
        </div>
        <div className="form__input-row">
          <div className="input__label">Last Name</div>
          <InputWithFormHelpers
            onChange={on_input_change("last_name")}
            value={form_data.last_name}
            name="last_name"
            required={true}
          />
        </div>
        <div className="form__input-row">
          <div className="input__label">Email</div>
          <InputWithFormHelpers
            type="email"
            onChange={on_input_change("email")}
            value={form_data.email}
            name="email"
            required={true}
            validate={is_valid_email}
            controlled={true}
          />
        </div>
        <div className="form__input-row">
          <div className="input__label">Phone Number</div>
          <PhoneNumberInput
            type="tel"
            onChange={on_input_change("phone_number")}
            value={form_data.phone_number}
            name="phone_number"
            required={false}
            controlled={true}
          />
        </div>
        <div className="form__input-row">
          <div className="input__label">Role</div>
          {canChangeRole ? (
            <DropdownSelectInput
              value={form_data.role}
              onSelect={on_change_role}
              getValueLabel={get_role_label}
              options={COMMUNITY_ROLE_OPTIONS}
              label="Role"
              Button={DropdownSelectInputDefaultTextInput}
            />
          ) : (
            <div className="fake-input">{get_role_label(form_data.role)}</div>
          )}
        </div>
      </div>
      <div className="modal__footer">
        <div className="modal__footer__left">
          {status.error && <ErrorMessage>{status.error.message}</ErrorMessage>}
        </div>
        <div className="modal__footer__right">
          <RequestButton
            className="filled gray"
            success={status.success}
            pending={status.pending || (!existing_user && status.success)}
            successText="Success!"
            type="submit"
            showError={false}
          >
            {!existing_user ? "Create New Member" : "Save Changes"}
          </RequestButton>
        </div>
      </div>
    </form>
  );
};
