import { set_dirty } from "@thrive-web/ui-model";
import { escape_regex_str } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { User } from "@thrive-web/ui-api";
import {
  InputWithFormHelpers,
  RequestButton,
  SvgLoadMore,
  useAsyncRenderResult,
  UserListItem,
  WithFloatingTitle,
} from "@thrive-web/ui-components";
import {
  useApiCall,
  useApiMethod,
  useRequest,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { ACTIVE_COMMUNITY } from "~/view/components";

export const CommMemberFindByEmail: Preact.FunctionComponent<{
  onFinish: (email: string, user?: User) => void;
}> = ({ onFinish }) => {
  const comm = useContext(ACTIVE_COMMUNITY);
  const list_ref = useRef<HTMLUListElement>();

  const [search, set_search] = useStateIfMounted("");
  const on_change = useCallback(e => {
    set_search(e.target.value);
  }, []);

  const [results, set_results] = useStateIfMounted<User[]>([]);
  const get_users_req = useApiMethod("getUsers");
  const get_users = useCallback(() => {
    if (!comm) {
      return Promise.reject("No active community");
    }
    if (!search) {
      return Promise.resolve([] as User[]);
    }
    return get_users_req({
      query: {
        filter: [
          ["match", ["this", "User:email"], escape_regex_str(search), "i"],
          [
            "not",
            ["=", ["this", ["^", `Community:has_member`]], ["id", comm.id]],
          ],
        ],
        include: ["profile_picture"],
        limit: 5,
      },
    }).then(({ data }) => data);
  }, [get_users_req, comm?.id, search]);
  const [search_users, status] = useRequest(get_users, true);

  useEffect(() => {
    search_users().then(list => {
      set_results(list);
    });
  }, [search, get_users]);

  // prevent layout shifting during pending state
  const height = useRef(0);
  useLayoutEffect(() => {
    if (list_ref.current) {
      height.current = results.length > 0 ? list_ref.current.clientHeight : 0;
    } else if (results.length === 0) {
      height.current = 0;
    }
  }, [status.pending, results.length]);

  const [user, set_user] = useStateIfMounted<User | null>(null);

  const [add_to_comm, add_status] = useApiCall("addCommunityMember");
  useEffect(() => {
    if (user && comm) {
      set_dirty(true, "CommunityMemberAdd");
      add_to_comm(comm.id, { body: { data: [{ id: user.id }] } }).then(res => {
        if (res && res.data) {
          set_dirty(false, "CommunityMemberAdd");
          onFinish(user.email!, user);
        }
      });
    }
  }, [user]);

  const passthrough_props = useMemo(
    () => ({ onFinish, search, user, set_user, ...add_status }),
    [
      onFinish,
      search,
      user,
      set_user,
      add_status.pending,
      add_status.success,
      add_status.error,
    ]
  );

  const content = useAsyncRenderResult(
    (result, pending, passthrough) => (
      <div className="member-add__search__results">
        {!pending && result.length > 0 ? (
          <ul className="user-list" ref={list_ref}>
            {result.map(u => (
              <UserListItem
                user={u}
                linkBox="none"
                size="sm"
                cardSubtext={u.email}
              >
                {!passthrough?.user || passthrough.user.id === u.id ? (
                  <RequestButton
                    className="filled gray"
                    onClick={() => passthrough!.set_user(u)}
                    pending={!!passthrough?.pending || !!passthrough?.success}
                    showError={true}
                    error={passthrough?.error}
                  >
                    Add to Community
                  </RequestButton>
                ) : null}
              </UserListItem>
            ))}
            <li>
              <p>
                <button
                  className="filled gray"
                  onClick={() => onFinish(passthrough!.search)}
                >
                  Create a New User
                </button>
              </p>
            </li>
          </ul>
        ) : (
          <div
            className="empty-list"
            style={
              pending && height.current
                ? { height: `${height.current}px` }
                : undefined
            }
          >
            <div className="empty-list__content">
              {pending ? (
                <div className="loading-dots">
                  <SvgLoadMore />
                </div>
              ) : !!passthrough!.search ? (
                <Preact.Fragment>
                  <p>
                    No users found for "<strong>{passthrough!.search}</strong>".
                  </p>
                  <p>
                    <button
                      className="filled gray"
                      onClick={() => onFinish(passthrough!.search)}
                    >
                      Create a New User
                    </button>
                  </p>
                </Preact.Fragment>
              ) : (
                <p className="member-add__search__prompt">
                  Search for a user by email...
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    ),
    [],
    status,
    results,
    true,
    undefined,
    undefined,
    true,
    passthrough_props
  );

  return (
    <div className="member-add__search" data-selected={!!user}>
      <div className="form__input-row">
        <WithFloatingTitle title="Email">
          <InputWithFormHelpers
            onChange={on_change}
            value={search}
            name="search-email"
            onChangeDebounce={true}
            debounceTime={500}
          />
        </WithFloatingTitle>
      </div>
      {content}
    </div>
  );
};
