import { GroupLabel } from "@thrive-web/ui-api";
import { useMemo } from "preact/hooks";
import {
  ApiMethodParameters,
  FilterSpec,
  SortSpec,
  Types,
} from "@thrive-web/core";
import { useApiFetchPaged } from "@thrive-web/ui-hooks";

export type ExpenseDateProp = "created_at" | "approved_at";

export const useExpenseSearch = (
  search?: string,
  date_start?: number,
  date_end?: number,
  sort?: SortSpec<Types["Expense"]>,
  date_prop: ExpenseDateProp = "created_at",
  approved?: boolean,
  label?: GroupLabel
) => {
  const params = useMemo<ApiMethodParameters<"GET", "Expense">>(() => {
    let filter: FilterSpec = [];
    if (search) {
      filter = [
        ...filter,
        [
          "match",
          ["this", ["/", "Expense:created_by", "User:full_name"]],
          search,
          "i",
        ],
      ];
    }
    if (date_start) {
      filter = [
        ...filter,
        [
          ">=",
          ["this", `Expense:${date_prop}`],
          new Date(date_start).toISOString(),
        ],
      ];
    }
    if (date_end) {
      filter = [
        ...filter,
        [
          "<=",
          ["this", `Expense:${date_prop}`],
          new Date(date_end).toISOString(),
        ],
      ];
    }
    if (approved != null) {
      const base = ["=", ["this", "Expense:is_approved"], true] as const;
      filter = [...filter, approved ? base : ["not", base]];
    }
    if (label != null) {
      filter = [
        ...filter,
        [
          "=",
          [
            "this",
            ["/", "Expense:posted_to", "Post:posted_to", "Group:has_label"],
          ],
          ["id", label.id],
        ],
      ];
    }

    return {
      query: {
        filter,
        sort: sort ? [sort] : undefined,
        include: ["posted_to", "created_by.User:profile_picture"],
        include_count: true,
      },
    };
  }, [search, sort, date_start, date_end, date_prop, approved, label]);

  return useApiFetchPaged("getExpenses", params);
};
