import { FilterSpec } from "@thrive-web/core";
import { remove_item_from } from "@thrive-web/ui-common";
import { maybeClassName } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { Community, Group, User } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  DefaultModalContent,
  DefaultPendingView,
  EmptyList,
  InfiniteScrollLoader,
  LazyListSection,
  RemovableListItem,
  RemovableListItemComponentProps,
  RequestButton,
  SearchBar,
  useLazyList,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useApiRequest,
  useDynamicListVariable,
  useModal,
  usePreviousValue,
  useRequest,
  useStateObject,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { ACTIVE_COMMUNITY } from "~/view/components";

const groups_search_filter = (
  search: string,
  user: User,
  comm: Community
): FilterSpec => {
  const params: FilterSpec = [
    ["not", ["=", ["this", `Group:has_member`], ["id", user.id]]],
    ["=", ["this", ["^", `Community:has_group`]], ["id", comm.id]],
  ];
  if (search) {
    return [...params, ["match", ["this", "Group:name"], search, "i"]];
  }
  return params;
};

const get_lazy_list_item_key = item => item[0].id;

// given a user, search groups and add the user to them
export const MemberGroupAdd: Preact.FunctionComponent<{
  user: User;
  onFinish: () => void;
}> = ({ user, onFinish }) => {
  const community = useContext(ACTIVE_COMMUNITY);
  const body_props = useMemo(() => ({ user, community }), [user, community]);

  const [modal, set_open] = useModal(
    {
      id: "member-group-add",
      className: "member-invite__modal member-profile-add__modal modal-form",
      showCloseButton: true,
      body: MemberGroupAddModal,
      bodyProps: body_props,
    },
    onFinish
  );

  if (!user || !community) {
    return null;
  }

  return (
    <ButtonWithIcon
      icon="add"
      side="left"
      className="filled gray"
      onClick={() => set_open(true)}
    >
      Add to Group
      {modal}
    </ButtonWithIcon>
  );
};

export const MemberGroupAddModal: Preact.FunctionComponent<
  ModalBodyProps & {
    user: User;
    community: Community;
  }
> = ({ user, community, closeButton, dismiss }) => {
  const scroll_container = useRef<HTMLDivElement>();

  const [{ search, offset, total, groups }, set_state] = useStateObject({
    search: "",
    offset: 0,
    total: -1,
    groups: null as Group[] | null,
  });
  const groups_ref = useValueRef(groups);
  const search_ref = useValueRef(search);
  const prev_search = usePreviousValue(search);
  const [new_groups, set_new_groups] = useDynamicListVariable<Group>([]);

  const on_change_search = useCallback(
    (str: string) =>
      str !== search_ref.current &&
      !(!str && !search_ref.current) &&
      set_state({ search: str, offset: 0, total: -1 }),
    [set_state]
  );

  const get_groups_req = useApiMethod("getGroups");
  const get_groups = useCallback(
    (offset: number, limit?: number) =>
      get_groups_req({
        query: {
          filter: groups_search_filter(search, user, community),
          limit,
          offset,
        },
      }),
    [community, user, search]
  );

  const [get_filtered_groups, { pending, error }] = useRequest(
    get_groups,
    true
  );
  const on_load_more = useCallback(
    () =>
      groups_ref.current?.length != null &&
      set_state({ offset: groups_ref.current?.length || 0 }),
    [set_state]
  );

  const get_filtered_groups_paged = useCallback(
    (offset: number, limit?: number) =>
      get_filtered_groups(offset, limit).then(result => {
        if (offset === 0) {
          scroll_container.current?.scrollTo({ top: 0 });
        }
        prev_search.current = search;
        set_state({
          groups:
            offset === 0
              ? result.data
              : (groups_ref.current || []).concat(result.data),
          total: (result.meta?.total_result_count as number) ?? -1,
        });
      }),
    [get_filtered_groups, set_state]
  );

  const remove_on_add = useCallback(
    (group_: Group) => {
      set_state({
        groups: groups_ref.current
          ? remove_item_from(groups_ref.current, g => g.id === group_.id)
          : groups_ref.current,
      });
      set_new_groups.add(group_);
    },
    [set_new_groups]
  );

  useEffect(() => {
    get_filtered_groups_paged(offset);
  }, [offset, get_filtered_groups_paged]);

  const list = useMemo(
    () =>
      groups
        ? groups.map(
            g =>
              [
                g,
                new_groups ? new_groups.some(g_s => g_s.id === g.id) : false,
              ] as const
          )
        : null,
    [groups, new_groups]
  );

  const content = useLazyList(
    list || [],
    ([group]) => (
      <MemberGroupAddModalItem
        group={group}
        userId={user.id}
        afterAdd={remove_on_add}
        added={!!new_groups?.find(g => g.id === group.id)}
      />
    ),
    [remove_on_add, user?.id, new_groups],
    25,
    get_lazy_list_item_key
  );

  return (
    <DefaultModalContent title="Add to Group" closeButton={closeButton}>
      <div className="member-invite__modal__body">
        <div className="invite-users-modal stack">
          <div className="invite-users-modal__search">
            <SearchBar
              placeholder="Search by Name"
              onSubmit={on_change_search}
              autoSubmitDelay={750}
              error={error}
              pending={pending && offset === 0 && !!list}
            />
          </div>
          <div
            className="invite-users-modal__people stack__scrolling-content"
            data-empty={`${!list || !list.length}`}
            ref={scroll_container}
          >
            {list ? (
              list.length ? (
                <div className="invite-users-modal__people__list">
                  {content.map((s, i) => (
                    <LazyListSection key={i}>{s}</LazyListSection>
                  ))}
                  {total === -1 || (list.length >= total && !pending) ? null : (
                    <InfiniteScrollLoader
                      nextPage={on_load_more}
                      pending={pending}
                      error={error}
                    />
                  )}
                </div>
              ) : (
                <EmptyList>
                  No Groups found{search ? ` for "${search}".` : "."}
                </EmptyList>
              )
            ) : (
              <DefaultPendingView />
            )}
          </div>
        </div>
        <div className="modal__footer">
          <div />
          <ButtonWithIcon
            className="filled gray"
            icon="checked"
            side="right"
            type="submit"
            onClick={dismiss}
          >
            Save & Finish
          </ButtonWithIcon>
        </div>
      </div>
    </DefaultModalContent>
  );
};

type GroupAddProps = MaybeClass & {
  group: Group;
  userId: string;
  afterAdd: (group: Group) => void;
  added: boolean;
};

export const MemberGroupAddModalItemBase: Preact.FunctionComponent<
  RemovableListItemComponentProps<GroupAddProps>
> = ({ group, userId, onRemoveItem, added, className }) => {
  const params = useMemo(
    () => ({ body: { data: [{ id: userId }] } }),
    [userId]
  );
  const [send_request, { pending, success, error }] = useApiRequest(
    "addGroupMember",
    group.id,
    params
  );

  const add_member = useCallback(() => {
    if (added) {
      return;
    }
    send_request()
      .then(res => ({ ...group, has_member: res.data } as Group))
      .then(onRemoveItem);
  }, [send_request, added, group, onRemoveItem]);

  return (
    <div className={`invite-users-modal__person${maybeClassName(className)}`}>
      <div className="invite-users-modal__person__left">
        <div className="user-card__name">{group.name}</div>
      </div>
      <div className="invite-users-modal__person__right">
        <RequestButton
          className={`filled ${success ? "success" : "gray"}`}
          pending={pending}
          success={success || added}
          successText="Added"
          error={error}
          showError={true}
          onClick={add_member}
        >
          Add
        </RequestButton>
      </div>
    </div>
  );
};

export const MemberGroupAddModalItem: Preact.FunctionComponent<GroupAddProps> =
  props => {
    const onRemoveItem = useCallback(
      (group: Group) => {
        props.afterAdd(group);
        return Promise.resolve();
      },
      [props.afterAdd]
    );
    return (
      <RemovableListItem<RemovableListItemComponentProps<GroupAddProps>, Group>
        {...props}
        delay={1000}
        Component={MemberGroupAddModalItemBase}
        onRemoveItem={onRemoveItem}
      />
    );
  };
