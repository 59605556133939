import * as Preact from "preact";
import { getCurrentUrl } from "preact-router";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { ScreenSize, THREAD_COMM_ID } from "@thrive-web/ui-constants";
import { EVENTS } from "@thrive-web/ui-model";
import { CONTEXTS } from "@thrive-web/ui-model";
import { IMAGES_PREFIX } from "@thrive-web/ui-constants";
import {
  Avatar,
  create_nav_links,
  create_userbar_links,
  NavMenu,
  Tooltip,
} from "@thrive-web/ui-components";
import { usePortal, useStateRef } from "@thrive-web/ui-hooks";
import {
  ACTIVE_COMMUNITY,
  CommunitySelector,
  CommunitySelectorDropdown,
  CommunitySelectorModal,
  NAV_LINKS_THREAD,
} from "~/view/components";

const SITE_HEADER_BOTTOM_CONTENT_ID = "site-header__bottom__content";

const tooltip_props = {
  mountLocal: true,
};

export const SiteHeader: Preact.FunctionComponent<{
  navLinks: NavLinkSpec[];
}> = ({ navLinks }) => {
  const auth = useContext(CONTEXTS.auth);
  const bottom_content_set = useContext(CONTEXTS.site_header_content_set);
  const window_size = useContext(CONTEXTS.window_size);
  const comm = useContext(ACTIVE_COMMUNITY);
  const nav_links = comm?.id === THREAD_COMM_ID ? NAV_LINKS_THREAD : navLinks;

  const right_content = useMemo(() => {
    if (!auth?.user) {
      return null;
    }
    if (window_size > ScreenSize.sm) {
      return <CommunitySelector Component={CommunitySelectorDropdown} />;
    }
    const links = create_nav_links(
      nav_links,
      undefined,
      "pill gray filled with-icon"
    );
    const userbar_links = create_userbar_links(
      auth.user,
      "site-nav__link pill gray filled with-icon",
      true
    );

    return (
      <NavMenu
        nav_items={[
          ...links,
          ...userbar_links,
          <div className="dropdown__divider" />,
          <div className="dropdown-menu__section-title">
            Current Community:
          </div>,
          <CommunitySelector Component={CommunitySelectorModal} />,
          <div className="dropdown__divider" />,
        ]}
      />
    );
  }, [window_size, nav_links]);

  return auth &&
    auth.user &&
    auth.userProfileBuilderComplete &&
    auth.hasGroups &&
    getCurrentUrl() !== "/guide" ? (
    <div
      className="site-header"
      id="site-header"
      data-with-content={bottom_content_set}
    >
      <header className="site-header__container">
        <div className="site-header__left">
          <div className="site-header__logo">
            <a href="/">
              <img
                className="site-header__logo__full"
                src={`${IMAGES_PREFIX}/logo_thread.svg`}
                alt="Thread Logo"
              />
              <img
                className="site-header__logo__icon"
                src={`${IMAGES_PREFIX}/logo.svg`}
                alt="Thread Logo"
              />
            </a>
          </div>
        </div>
        <div className="site-header__center" />
        <div className="site-header__right">
          {right_content}
          <div className="site-header__avatar">
            <Tooltip
              text={`Logged in as ${auth.user.full_name}`}
              popoverProps={tooltip_props}
            >
              <Avatar user={auth.user} size="sm" isLink={false} />
            </Tooltip>
          </div>
        </div>
      </header>
      <div className="fake-margins site-header__bottom">
        <div
          key={SITE_HEADER_BOTTOM_CONTENT_ID}
          className="site-header__bottom__content"
          id={SITE_HEADER_BOTTOM_CONTENT_ID}
        />
        <Avatar user={auth.user} size="md" isLink={true} />
      </div>
    </div>
  ) : null;
};

export const useSiteHeaderContent = (show: boolean = true) => {
  const dispatch = useContext(CONTEXTS.dispatch);
  const content_set = useContext(CONTEXTS.site_header_content_set);
  const content_set_ref = useRef(content_set);
  const [visible, set_visible, visible_ref] = useStateRef(false);
  const render = usePortal(SITE_HEADER_BOTTOM_CONTENT_ID, show);
  const show_ref = useRef(show);
  content_set_ref.current = content_set;
  show_ref.current = show;

  // on Unmount
  useEffect(() => {
    return () => {
      visible_ref.current = false;
      dispatch({ type: EVENTS.SET_HEADER_CONTENT, payload: false });
    };
  }, []);

  // on Mount, sort of
  useLayoutEffect(() => {
    if (!content_set && show_ref.current) {
      dispatch({ type: EVENTS.SET_HEADER_CONTENT, payload: true });
      set_visible(true);
    }
  }, [content_set]);

  useEffect(() => {
    if (show) {
      if (!content_set_ref.current) {
        dispatch({ type: EVENTS.SET_HEADER_CONTENT, payload: true });
      }
      set_visible(true);
    } else {
      if (content_set_ref.current && visible_ref.current) {
        content_set_ref.current &&
          dispatch({ type: EVENTS.SET_HEADER_CONTENT, payload: false });
      }
      set_visible(false);
    }
  }, [show]);

  return visible ? render : _ => null;
};
