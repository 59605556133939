import { Expense } from "@thrive-web/ui-api";
import {
  DefaultModalContent,
  ExpandableImage,
} from "@thrive-web/ui-components";
import { get_guid_from_iri } from "@thrive-web/ui-utils";
import * as Preact from "preact";

export const ReimbursementsReceiptModal: Preact.FunctionComponent<
  ModalBodyProps & { expense: Expense }
> = ({ closeButton, expense }) => (
  <DefaultModalContent
    title="Receipt"
    closeButton={closeButton}
    footer={
      <div className="modal__footer">
        <div className="modal__footer__right">
          <a
            href={expense.receipt_photo_url}
            download={`Receipt-${
              /*expense.number*/ get_guid_from_iri(expense.id)[0]
            }`}
            className="button filled gray"
            target="_blank"
          >
            Download Image
          </a>
        </div>
      </div>
    }
  >
    <ExpandableImage src={expense.receipt_photo_url} />
  </DefaultModalContent>
);
