import { entity_has_type } from "@thrive-web/ui-common";
import { MODAL_ANIMATION_DELAY } from "@thrive-web/ui-constants";
import { useRequest, useStateIfMounted } from "@thrive-web/ui-hooks";
import * as Preact from "preact";
import {
  DefaultModalContent,
  DropdownSelectInput,
  ErrorMessage,
  RequestButtonWithIcon,
  UserCard,
} from "@thrive-web/ui-components";
import { Community, Group, User } from "@thrive-web/ui-api";
import { useCallback, useMemo } from "preact/hooks";
import { ScopeRole, useMemberRoleUpdateRequest } from "~/view/utils";

export interface RoleSelectorProps<T extends Group | Community> {
  scope: T;
  value: ScopeRole<T>;
  onChange: (value: ScopeRole<T>) => void;
}

const group_options: ScopeRole<Group>[] = ["has_admin", "has_member"];

const opt_labels = {
  has_admin: "Admin",
  has_manager: "Manager",
  has_moderator: "Moderator",
  has_member: "Member",
} as const;

export const COMMUNITY_ROLE_OPTIONS = Object.keys(opt_labels);

export const get_role_label = v => opt_labels[v];

export const RoleSelectorModal = <T extends Group | Community>({
  closeButton,
  dismiss,
  targetUser,
  scope,
  onFinish,
  initialRole,
}: ModalBodyProps & {
  targetUser: User | null;
  scope: T | null;
  onFinish: (updated_scope: T) => void;
  initialRole: ScopeRole<T>;
}): Preact.VNode | null => {
  const [role, set_role] = useStateIfMounted<ScopeRole<T>>(initialRole);
  const save_role_req = useMemberRoleUpdateRequest(scope, targetUser, role);
  const [save_role, status] = useRequest(save_role_req);
  const onSubmit = useCallback(() => {
    if (role === initialRole) {
      dismiss();
    } else {
      save_role().then(res => {
        setTimeout(() => {
          dismiss();
          setTimeout(() => onFinish(res), MODAL_ANIMATION_DELAY);
        }, 1200);
      });
    }
  }, [save_role, onFinish, role]);

  const options = useMemo(
    () =>
      entity_has_type(scope, "Group") ? group_options : COMMUNITY_ROLE_OPTIONS,
    [scope?.id]
  );

  if (!targetUser) {
    return null;
  }

  return (
    <DefaultModalContent
      title={`Change Member Role`}
      closeButton={closeButton}
      footer={
        <div className="modal__footer">
          <div className="modal__footer__left">
            <button className="filled gray" onClick={dismiss}>
              Cancel
            </button>
          </div>
          <div className="modal__footer__right">
            <div className="footer-error">
              {status.error && !status.pending && (
                <ErrorMessage>{status.error.message}</ErrorMessage>
              )}
            </div>
            <RequestButtonWithIcon
              pending={status.pending}
              success={status.success}
              successText="Success!"
              icon="checked"
              side="left"
              className="filled gray"
              onClick={onSubmit}
            >
              Save Changes
            </RequestButtonWithIcon>
          </div>
        </div>
      }
    >
      <div className="member-list__role__content">
        <UserCard
          user={targetUser}
          avatarIsLink={false}
          nameIsLink={false}
          size="xl"
        />
        <div className="member-list__role__form">
          <p>Select a role:</p>
          <DropdownSelectInput
            value={role}
            onSelect={set_role}
            getValueLabel={get_role_label}
            options={options}
            label="Role"
          />
        </div>
      </div>
    </DefaultModalContent>
  );
};
