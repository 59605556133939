import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { Group } from "@thrive-web/ui-api";
import { MetaTypes, RelationshipKeysOf } from "@thrive-web/core";
import { ensure_id_is_iri } from "@thrive-web/ui-common";
import {
  DefaultErrorView,
  DefaultPendingView,
  DynamicListProvider,
  GROUP_DETAIL_CONTEXTS,
  GROUP_DETAIL_LIST_CONTEXTS,
  GroupDetailAbout,
  GroupGoalsPage,
  LocalProvider,
  PageBody,
  PageContent,
  passPropsToLocalContext,
} from "@thrive-web/ui-components";
import {
  useApiRequest,
  useAppUser,
  useRecordForDetailPage,
} from "@thrive-web/ui-hooks";
import {
  CommunityGroupDetailActivityPage,
  CommunityGroupDetailHeader,
  CommunityGroupDetailMembersPage,
} from "~/view/components";

export const CommunityGroupDetail: Preact.FunctionComponent<{
  group: Group;
  post_id?: string;
}> = ({ post_id, group }) => {
  passPropsToLocalContext("group", group, GROUP_DETAIL_CONTEXTS.dispatch);
  return (
    <Preact.Fragment>
      <CommunityGroupDetailActivityPage
        path="/groups/:id/activity/:post_id?"
        post_id={post_id}
      />
      <CommunityGroupDetailMembersPage path="/groups/:id/members" />
      {group.has_goals && (
        <GroupGoalsPage path="/groups/:id/goals/:goal?/:action?" />
      )}
      <GroupDetailAbout path="/groups/:id/about" />
    </Preact.Fragment>
  );
};

export const CommunityGroupDetailPage: Preact.FunctionComponent<RoutePageProps> =
  ({ matches = {} }) => {
    const user = useAppUser();
    const id = useMemo(
      () => ensure_id_is_iri(matches.id || "", "Group"),
      [matches.id]
    );
    const post_id = useMemo(
      () =>
        matches.item ? ensure_id_is_iri(matches.item || "", "Post") : undefined,
      [matches.item]
    );

    const params = useMemo(
      () => ({
        query: {
          include: [
            "has_admin",
            "has_label",
            "background_image",
            "has_member.User:profile_picture",
          ] as RelationshipKeysOf<MetaTypes["Group"]>[],
        },
      }),
      []
    );

    const [get_group, { error, pending }] = useApiRequest(
      "getGroup",
      id,
      params
    );
    const [group, fetch] = useRecordForDetailPage(id, get_group);

    const initial_ctx = useMemo(() => ({ group, fetch }), [group, fetch]);

    if (!user) {
      return null;
    }

    if (error) {
      return <DefaultErrorView error={error} />;
    }

    return (
      <PageContent id="group-detail-page" className="group-page detail-page">
        {Object.entries(GROUP_DETAIL_LIST_CONTEXTS).reduce(
          (
            children_,
            [key, spec]: EntryOf<typeof GROUP_DETAIL_LIST_CONTEXTS>
          ) => (
            <DynamicListProvider
              context={spec}
              key={key}
              {...(key === "members"
                ? {
                    initialValue: (group?.has_member || []).filter(
                      m => "email" in m
                    ),
                  }
                : {
                    fetch: fetch[key],
                  })}
            >
              {children_}
            </DynamicListProvider>
          ),
          <LocalProvider
            contexts={GROUP_DETAIL_CONTEXTS}
            initialValues={initial_ctx}
          >
            <CommunityGroupDetailHeader />
            <PageBody>
              {pending || !group ? (
                <DefaultPendingView />
              ) : (
                <CommunityGroupDetail group={group} post_id={post_id} />
              )}
            </PageBody>
          </LocalProvider>
        )}
      </PageContent>
    );
  };
