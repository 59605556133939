import {
  ApiMethodParameters,
  MetaTypes,
  RelationshipKeysOf,
} from "@thrive-web/core";
import {
  Expense,
  ExpenseCategory,
  ExpenseReason,
  User,
} from "@thrive-web/ui-api";
import { capitalize, ensure_id_is_iri, moment } from "@thrive-web/ui-common";
import { ScreenSize } from "@thrive-web/ui-constants";
import {
  useApiMethod,
  useApiRequest,
  useAppUser,
  useMedia,
  useModal,
  useRecordForDetailPage,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { CONTEXTS } from "@thrive-web/ui-model";
import { display_text } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  DefaultErrorView,
  DefaultPendingView,
  PageBody,
  PageContent,
  PageHeader,
  PageSidebar,
  SidebarSection,
  TextWithLinks,
  useAsyncRenderResult,
  UserCard,
  UserListItem,
  Icon,
  DeleteModalBody,
  DefaultModalContent,
  WithTaxonomy,
  DEFAULT_USER_FIELDS,
} from "@thrive-web/ui-components";
import { useContext, useEffect, useMemo } from "preact/hooks";
import {
  ReimbursementsReceiptModal,
  SortableTableColumn,
} from "~/view/components";
import { REIMBURSEMENT_COLUMNS } from "./ReimbursementsTable";

const ROWS: {
  [K in keyof Expense]?: SortableTableColumn<Expense, K>;
} = {
  created_by: REIMBURSEMENT_COLUMNS.created_by,
  vendor_name: REIMBURSEMENT_COLUMNS.vendor_name,
  requested_amount: REIMBURSEMENT_COLUMNS.requested_amount,
  receipt_amount: {
    label: "Receipt Total",
    renderCell: (amount: number) => {
      const whole = Math.floor(amount);
      const frac = amount - whole;
      return `$${whole.toLocaleString()}.${frac.toFixed(2).replace("0.", "")}`;
    },
  },
  created_at: REIMBURSEMENT_COLUMNS.created_at,
  purchase_date: {
    label: "Purchase Date",
    renderCell: date => moment(date).format("MM/DD/YYYY"),
  },
  distribution_method: {
    label: "Distribution Method",
    renderCell: method => capitalize(method) || "--",
  },
  has_category: {
    label: "What did you spend money on?",
    renderCell: (value: ExpenseCategory) => (
      <WithTaxonomy value={value} type="ExpenseCategory">
        {tx_value => tx_value?.title}
      </WithTaxonomy>
    ),
  },
  has_reason: {
    label: "What did you spend money for?",
    renderCell: (value: ExpenseReason) => (
      <WithTaxonomy value={value} type="ExpenseReason">
        {tx_value => tx_value?.title}
      </WithTaxonomy>
    ),
  },
};

const renderParticipants = (users: User[], pending, passthrough) => (
  <div className="expenses__detail__users__list">
    {users.map(u => (
      <UserListItem key={u.email} user={u} linkBox="all" useDiv={true} />
    ))}
  </div>
);

export const ReimbursementDetailStatusModal: Preact.FunctionComponent<
  ModalBodyProps & { onSubmit: () => Promise<void> }
> = ({ closeButton, dismiss, onSubmit, open, children }) => (
  <DefaultModalContent
    title="Update Reimbursement Status"
    closeButton={closeButton}
  >
    <DeleteModalBody
      dismiss={dismiss}
      open={open}
      deleteRecord={onSubmit}
      buttonClassName=" "
      buttonIcon="checked"
    >
      {children}
    </DeleteModalBody>
  </DefaultModalContent>
);

export const ReimbursementDetail: Preact.FunctionComponent<{
  expense: Expense;
  updateExpense: (expense: Expense) => void;
  pending?: boolean;
}> = ({ expense, pending, updateExpense }) => {
  const window_size = useContext(CONTEXTS.window_size);
  const show_sidebar = window_size >= ScreenSize.lg;

  const self = useAppUser();

  const receipt_url = useMedia<"Expense", "receipt_photo">(
    expense,
    "receipt_photo",
    1,
    window_size,
    show_sidebar ? "small" : undefined
  );

  const params = useMemo<ApiMethodParameters<"GET", "User">>(
    () => ({
      query: {
        filter: [
          [
            "=",
            ["this", ["^", "Post:has_tagged_user"]],
            ["id", expense.posted_to!.id],
          ],
        ],
        include: ["profile_picture"],
        fields: {
          User: DEFAULT_USER_FIELDS,
        },
        limit: 50,
      },
    }),
    [expense.posted_to?.id]
  );

  const [get_users, req_status] = useApiRequest("getUsers", params);
  const [users, set_users] = useStateIfMounted<User[] | null>(null);
  useEffect(() => {
    get_users().then(({ data }) => set_users(data));
  }, [expense.id]);

  const participants = useAsyncRenderResult(
    renderParticipants,
    [],
    req_status,
    users
  );

  const receipt_modal_props = useMemo(() => ({ expense }), [expense?.id]);

  const [receipt_modal, set_receipt_open] = useModal({
    id: "expense-receipt-modal",
    className: "expenses__receipt-modal",
    body: ReimbursementsReceiptModal,
    showCloseButton: true,
    bodyProps: receipt_modal_props,
  });

  const receipt = (
    <div className="expenses__detail__receipt">
      <button className="non-button" onClick={() => set_receipt_open(true)}>
        <img src={receipt_url} />
      </button>
    </div>
  );

  const update_expense = useApiMethod("updateExpense");
  const modal_props = useMemo(
    () => ({
      children: "Do you want to approve this reimbursement?",
      onSubmit: () =>
        update_expense(expense.id, {
          body: {
            data: {
              attributes: {
                is_approved: true,
              },
            },
          },
        }).then(() =>
          updateExpense({
            ...expense,
            is_approved: true,
            approved_by: self!,
            approved_at: new Date().toISOString(),
          })
        ),
    }),
    [expense.id, expense.is_approved, self?.id, updateExpense]
  );
  const [modal, set_open] = useModal({
    id: "update-expense-status",
    className: "modal-form expenses__detail__status__modal",
    body: ReimbursementDetailStatusModal,
    showCloseButton: true,
    bodyProps: modal_props,
  });

  const approved_at = useMemo(
    () =>
      expense.approved_at
        ? moment(expense.approved_at).format("MM/DD/YYYY")
        : undefined,
    [expense.approved_at]
  );

  const status_text = expense.is_approved ? "approved" : "submitted";
  const status = (
    <Preact.Fragment>
      <div className="expenses__detail__status">
        <div className="section-title">Status</div>
        <button
          className={`filled gray expenses__detail__status__button${
            !expense.is_approved ? "" : " success static"
          }`}
          onClick={!expense.is_approved ? () => set_open(true) : undefined}
          tabIndex={expense.is_approved ? -1 : undefined}
        >
          {capitalize(status_text)}
          {expense.is_approved ? (
            <Icon name="checked" />
          ) : (
            <span className="plain-link blue">Edit</span>
          )}
        </button>
      </div>
      {expense.is_approved && expense.approved_by && (
        <div className="expenses__detail__status__approver">
          <div className="section-title">Approved By</div>
          <UserCard user={expense.approved_by} nameIsLink={true}>
            {approved_at}
          </UserCard>
        </div>
      )}
    </Preact.Fragment>
  );

  return (
    <PageBody>
      <div className="expenses__detail">
        {!show_sidebar && (
          <Card
            className={`expenses__detail__status__card expenses__detail__status--${status_text}`}
          >
            {status}
          </Card>
        )}
        <Card>
          <dl className="expenses__detail__table">
            {(Object.keys(ROWS) as (keyof Expense)[]).map(k => (
              <Preact.Fragment key={k}>
                <dt>{ROWS[k]!.label}</dt>
                <dd>
                  {
                    // @ts-expect-error:
                    ROWS[k]!.renderCell(expense[k], expense)
                  }
                </dd>
              </Preact.Fragment>
            ))}
          </dl>
        </Card>
        {expense.details && (
          <Card>
            <div className="expenses__detail__table__label">Details</div>
            <p>
              <TextWithLinks>
                {display_text(expense.details) || ""}
              </TextWithLinks>
            </p>
          </Card>
        )}
        {
          // @ts-expect-error:
          expense.touchpoint?.description && (
            <Card>
              <div className="section-title">Touchpoint Summary</div>
              <p>
                {
                  // @ts-expect-error:
                  expense.touchpoint?.description
                }
              </p>
            </Card>
          )
        }
        {
          // @ts-expect-error:
          expense.posted_to?.body && (
            <Card>
              <div className="section-title">Post Body</div>
              <p>
                {
                  // @ts-expect-error:
                  expense.posted_to?.body
                }
              </p>
            </Card>
          )
        }
        {!show_sidebar && (
          <Preact.Fragment>
            <Card>
              <div className="section-title">Receipt Photo</div>
              {receipt}
            </Card>
            <Card>
              <div className="section-title">Participants</div>
              {participants}
            </Card>
          </Preact.Fragment>
        )}
        {pending && <DefaultPendingView />}
      </div>
      {show_sidebar && (
        <PageSidebar>
          <SidebarSection
            className={`card expenses__detail__status--${status_text}`}
            name="Status"
          >
            {status}
          </SidebarSection>
          <SidebarSection className="card" name="Receipt Photo">
            {receipt}
          </SidebarSection>
          <SidebarSection className="card" name="Participants">
            {participants}
          </SidebarSection>
        </PageSidebar>
      )}
      {modal}
      {receipt_modal}
    </PageBody>
  );
};

export const ReimbursementsDetailPage: Preact.FunctionComponent<RoutePageProps> =
  ({ matches = {} }) => {
    const breadcrumbs = useMemo<BreadcrumbItem[]>(
      () => [
        { label: "Reimbursements", href: "/reimbursements" },
        { label: matches.id || "" },
      ],
      [matches.id]
    );
    const id = useMemo(
      () => ensure_id_is_iri(matches.id || "", "Expense"),
      [matches.id]
    );

    const params = useMemo(
      () => ({
        query: {
          include: [
            "created_by.User:profile_picture",
            "has_category",
            "has_reason",
            "touchpoint",
            "posted_to",
            "approved_by",
            "receipt_photo",
          ] as RelationshipKeysOf<MetaTypes["Expense"]>[],
        },
      }),
      []
    );

    const [get_expense, { error, pending }] = useApiRequest(
      "getExpense",
      id,
      params
    );
    const [expense, , set_expense] = useRecordForDetailPage(id, get_expense);

    if (error) {
      return <DefaultErrorView error={error} />;
    }

    return (
      <PageContent
        id="community-expenses"
        data-page="expense-list"
        className="detail-page expense-page no-separate-header has-sidebar"
      >
        <PageHeader breadcrumb={<Breadcrumb items={breadcrumbs} />} />
        {!!expense ? (
          <ReimbursementDetail
            pending={pending}
            expense={expense}
            updateExpense={set_expense}
          />
        ) : (
          <PageBody>
            <DefaultPendingView />
          </PageBody>
        )}
      </PageContent>
    );
  };
