import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { User } from "@thrive-web/ui-api";
import { capitalize, moment } from "@thrive-web/ui-common";
import {
  Avatar,
  AvatarLoading,
  Breadcrumb,
  ButtonWithIcon,
  DefaultDropdownButtonDiv,
  DefaultModalContent,
  DeleteModalBody,
  DropdownMenu,
  LinkWithIcon,
  PageHeader,
  SendSkeletonInviteButton,
  SendSkeletonLinkButton,
  TabLinks,
  UserCard,
} from "@thrive-web/ui-components";
import { useApiFetch, useModal, useStateIfMounted } from "@thrive-web/ui-hooks";
import {
  display_text,
  get_url_for_entity,
  get_user_name,
  maybe_route,
} from "@thrive-web/ui-utils";
import authWrapper from "~/auth/auth";
import {
  ACTIVE_COMMUNITY,
  MEMBER_DETAIL_CONTEXTS,
  useMemberRemove,
  CommunityMemberDetailEdit,
} from "~/view/components";
const { api } = require("~/../site/env.json");

const createUserTabLinks = (user: User): NavLinkSpec[] => {
  if (!user || !user.id) {
    return [];
  }
  const url = get_url_for_entity(user);
  const links: NavLinkSpec[] = [
    {
      href: url,
      icon: "info-solid-circle",
      text: `About ${capitalize(get_user_name(user, ""))}`,
      activePathMatch: `${url}`,
    },
    {
      href: `${url}/groups`,
      icon: "groups",
      text: "Groups",
      activePathMatch: `${url}/groups/:action?`,
    },
    {
      href: `${url}/connections`,
      icon: "people",
      text: "People",
      activePathMatch: `${url}/connections/:action?`,
    },
  ];
  return links;
};

export const CommunityMemberDetailDeleteModal: Preact.FunctionComponent<
  ModalBodyProps & {
    deleteRecord: () => Promise<void>;
    afterDelete: () => void;
  }
> = ({ closeButton, deleteRecord, ...props }) => {
  return (
    <DefaultModalContent
      title="Delete Unregistered User"
      closeButton={closeButton}
    >
      <DeleteModalBody deleteRecord={deleteRecord} {...props}>
        Are you sure you want to delete this user?
      </DeleteModalBody>
    </DefaultModalContent>
  );
};

export const CommunityMemberDetailResetPwModal: Preact.FunctionComponent<
  ModalBodyProps & {
    makeRequest: () => Promise<void>;
    user: User;
  }
> = ({ closeButton, makeRequest, user, ...props }) => {
  return (
    <DefaultModalContent title="Prompt Reset Password">
      <DeleteModalBody deleteRecord={makeRequest} hideWarning={true} {...props}>
        <UserCard
          user={user}
          size="lg"
          nameIsLink={false}
          avatarIsLink={false}
        />
        <p>Prompt {user.full_name} to reset their password?</p>
      </DeleteModalBody>
    </DefaultModalContent>
  );
};

// todo: Add password reset button

export const CommunityMemberDetailHeader: Preact.FunctionComponent = () => {
  const user = useContext(MEMBER_DETAIL_CONTEXTS.user);
  const comm = useContext(ACTIVE_COMMUNITY);

  /*  const onCloseAddGroup = useCallback(() => {
    if (window.location.hash === "#edit") {
      window.history.replaceState(undefined, "", window.location.pathname);
    }
  }, []);

  const [editModal, setEditModalOpen] = useGroupEditModal(group!, onCloseEdit);
  const openEditModal = useCallback(() => setEditModalOpen(true), [
    setEditModalOpen,
  ]);

  useEffect(() => {
    if (window.location.hash === "#invite-member") {
      openEditModal();
    }
  }, []);*/
  const tab_links = useMemo(
    () => (user ? createUserTabLinks(user) : []),
    [user]
  );

  const [remove_open, set_remove_open] = useStateIfMounted<User | null>(null);
  const after_remove = useCallback(() => {
    //todo: stay and see non-member view, or go back to members list?
  }, []);

  const [on_click_remove, remove_modal] = useMemberRemove(
    comm,
    remove_open,
    set_remove_open,
    after_remove
  );

  const delete_user = useApiFetch("deleteUser", user?.id);
  const delete_props = useMemo(
    () => ({
      deleteRecord: delete_user,
      afterDelete: () => maybe_route("/people"),
    }),
    [delete_user]
  );
  const [delete_modal, set_delete_open] = useModal({
    id: "member-delete-modal",
    className: "card card-stack modal-form",
    body: CommunityMemberDetailDeleteModal,
    showCloseButton: true,
    bodyProps: delete_props,
  });

  const reset_pw = useCallback(
    () =>
      user?.email
        ? authWrapper.forgotPassword(user.email)
        : Promise.reject("No email provided"),
    [user?.email]
  );

  const reset_props = useMemo(
    () => ({
      makeRequest: reset_pw,
      user,
    }),
    [user, reset_pw]
  );
  const [reset_modal, set_reset_open] = useModal({
    id: "member-reset-modal",
    className: "card card-stack modal-form",
    body: CommunityMemberDetailResetPwModal,
    showCloseButton: true,
    bodyProps: reset_props,
  });

  const breadcrumbs = useMemo(
    () => [
      { label: "Members", href: "/people" },
      { label: user?.full_name || "" },
    ],
    [user?.full_name]
  );

  if (!user) {
    return null;
  }

  return (
    <PageHeader
      breadcrumb={<Breadcrumb items={breadcrumbs} />}
      title={
        user ? (
          <h1>{display_text(user.full_name)}</h1>
        ) : (
          <div className="loading-item__shaded loading-item__name loading-item__header" />
        )
      }
      subtitle={
        user ? (
          `Joined ${moment(user.created_at).format("MMMM, YYYY")}`
        ) : (
          <div className="loading-item__shaded loading-item__text" />
        )
      }
      withTabmenu={true}
      icon={
        user ? (
          <div className="user-avatar" data-size="xl">
            <Avatar user={user} size="xl" isLink={false} expandable={true} />
          </div>
        ) : (
          <AvatarLoading size="xl" />
        )
      }
      button={
        <div className="page-header__action-menu">
          <DropdownMenu
            listClassName="card pill-card"
            button={
              <DefaultDropdownButtonDiv
                icon="more"
                className="button filled gray all-gray"
              />
            }
            items={[
              !!user.firebase_uuid ? (
                <LinkWithIcon
                  icon="logo"
                  side="left"
                  className="button pill filled gray"
                  target="_blank"
                  href={`${api.mainSiteUrl}${get_url_for_entity(user)}`}
                >
                  View Profile in Thread App
                </LinkWithIcon>
              ) : (
                <SendSkeletonInviteButton user={user} />
              ),
              !user.firebase_uuid ? (
                <SendSkeletonLinkButton user={user} />
              ) : null,
              !user.firebase_uuid ? <CommunityMemberDetailEdit /> : null,
              !!user.firebase_uuid ? (
                <ButtonWithIcon
                  icon="jump-back"
                  side="left"
                  className="filled pill gray"
                  onClick={() => set_reset_open(true)}
                >
                  Prompt Reset Password
                </ButtonWithIcon>
              ) : null,
              <ButtonWithIcon
                icon="remove-user"
                side="left"
                className="filled pill negative"
                onClick={
                  !!user.firebase_uuid
                    ? () => on_click_remove(user)
                    : () => set_delete_open(true)
                }
              >
                {!!user.firebase_uuid
                  ? `Remove ${user.first_name} from Community`
                  : "Delete Unregistered User"}
              </ButtonWithIcon>,
            ]}
          />
        </div>
      }
    >
      <TabLinks links={tab_links} />
      {!!user.firebase_uuid ? remove_modal : delete_modal}
      {!!user.firebase_uuid && reset_modal}
    </PageHeader>
  );
};
