export const NAV_LINKS: NavLinkSpec[] = [
  { href: "/", text: "Home", icon: "home", activePathMatch: "/" },
  {
    href: "/groups",
    text: "Groups",
    icon: "family",
    activePathMatch: "/groups/:id?/:tab?/:action?",
  },
  {
    href: "/people",
    text: "Members",
    icon: "people",
    activePathMatch: "/people/:id?/:tab?/:action?",
  },
  {
    href: "/settings",
    text: "Settings",
    icon: "settings",
    activePathMatch: "/settings",
  },
];

export const NAV_LINKS_THREAD: NavLinkSpec[] = [
  { href: "/", text: "Home", icon: "home", activePathMatch: "/" },
  {
    href: "/groups",
    text: "Groups",
    icon: "family",
    activePathMatch: "/groups/:id?/:tab?/:action?",
  },
  {
    href: "/people",
    text: "Members",
    icon: "people",
    activePathMatch: "/people/:id?/:tab?/:action?",
  },
  {
    href: "/reimbursements",
    text: "Reimbursements",
    icon: "expense",
    activePathMatch: "/reimbursements/:id?/:tab?/:action?",
  },
  {
    href: "/settings",
    text: "Settings",
    icon: "settings",
    activePathMatch: "/settings",
  },
];
