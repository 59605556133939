import * as Preact from "preact";
import { User } from "@thrive-web/ui-api";
import { ContextSpec, LocalDispatch } from "@thrive-web/ui-components";

type UserLocalCtx = {
  user: User | null;
  fetch: () => Promise<void>;
};

export const MEMBER_USER = Preact.createContext<null | User>(null);
export const MEMBER_DISPATCH = Preact.createContext<
  LocalDispatch<UserLocalCtx>
>(() => {});
export const MEMBER_FETCH = Preact.createContext<() => Promise<void>>(() =>
  Promise.resolve()
);

export const MEMBER_DETAIL_CONTEXTS: ContextSpec<UserLocalCtx> = {
  user: MEMBER_USER,
  dispatch: MEMBER_DISPATCH,
  fetch: MEMBER_FETCH,
};
