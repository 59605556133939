import { Group, User } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  DefaultDropdownButtonDiv,
  DefaultModalContent,
  DeleteModalBody,
  DropdownMenu,
  LinkWithIcon,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useModal,
  useRenderPropsFunction,
} from "@thrive-web/ui-hooks";
import { get_url_for_entity } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { StateUpdater, useCallback, useMemo } from "preact/hooks";
import { MemberListItem } from "~/view/components";

export const GroupListItem: Preact.FunctionComponent<{
  group: Group;
  onClickRemoveMember?: (group: Group) => void;
  onClickRemove?: (group: Group) => void;
  onClickArchive?: (group: Group) => void;
  onClickChangeRole?: (group: Group) => void;
  member?: User;
}> = ({
  group,
  member,
  onClickRemove,
  onClickArchive,
  onClickRemoveMember,
  onClickChangeRole,
}) => {
  const status = useMemo(() => {
    if (member) {
      return group.has_admin?.find(u => u.id === member.id)
        ? "Admin"
        : "Member";
    }
    return group.is_archived ? "Inactive" : "Active";
  }, [group]);
  return (
    <MemberListItem
      icon={
        <div
          className="group-dot"
          data-size="md"
          style={{ backgroundColor: group.background_color }}
        />
      }
      title={group.name || ""}
      entity={group}
      status={status}
      actions={
        <DropdownMenu
          listClassName="card pill-card"
          button={
            <DefaultDropdownButtonDiv
              icon="more"
              className="button filled transparent all-gray"
            />
          }
          items={[
            !member ? (
              <LinkWithIcon
                icon="edit"
                side="left"
                className="filled pill button gray"
                href={`${get_url_for_entity(group)}#edit`}
              >
                Edit Group
              </LinkWithIcon>
            ) : onClickChangeRole ? (
              <ButtonWithIcon
                icon="star"
                side="left"
                className="filled pill gray"
                onClick={() => onClickChangeRole(group)}
              >
                Change Role
              </ButtonWithIcon>
            ) : null,
            /*<ButtonWithIcon
              icon="trash"
              side="left"
              className="filled pill negative"
              onClick={() => onClickRemove(group)}
            >
              Remove Group
            </ButtonWithIcon>,*/
            onClickArchive ? (
              <ButtonWithIcon
                icon="lock"
                side="left"
                className="filled pill negative"
                onClick={() => onClickArchive(group)}
              >
                {group.is_archived ? "Unarchive" : "Archive"} Group
              </ButtonWithIcon>
            ) : null,
            onClickRemoveMember ? (
              <ButtonWithIcon
                icon="remove-user"
                side="left"
                className="filled pill negative"
                onClick={() => onClickRemoveMember(group)}
              >
                Remove from Group
              </ButtonWithIcon>
            ) : null,
          ]}
        />
      }
    />
  );
};

export const useGroupRemove = (
  fromId: string,
  targetGroup: Group | null,
  setTargetGroup: StateUpdater<Group | null>,
  onRemove?: (id: string) => void
) => {
  const removeGroupReq = useApiMethod("removeCommunityGroup");
  const removeGroup = useCallback(() => {
    if (!targetGroup) {
      return Promise.resolve();
    }
    return removeGroupReq(fromId, {
      body: { data: [{ id: targetGroup.id }] },
    });
  }, [removeGroupReq, fromId, targetGroup]);

  const afterRemove = useCallback(() => {
    setTargetGroup(null);
    targetGroup && onRemove && onRemove(targetGroup.id);
  }, [targetGroup, setTargetGroup, onRemove]);
  const GroupRemoveModalBody = useRenderPropsFunction<ModalBodyProps>(
    ({ closeButton, ...props }) =>
      targetGroup ? (
        <DefaultModalContent title={`Remove Group`} closeButton={closeButton}>
          <DeleteModalBody
            deleteRecord={removeGroup}
            afterDelete={afterRemove}
            {...props}
          >
            Are you sure you want to remove this group from the community?
          </DeleteModalBody>
        </DefaultModalContent>
      ) : null,
    "GroupRemoveModal-Bound",
    [self, removeGroup, targetGroup, afterRemove]
  );

  const [removeGroupModal, openRemoveGroupModal] = useModal({
    id: "remove-group-modal",
    innerClassName: "card card-stack modal-form member-list__remove__modal",
    body: GroupRemoveModalBody,
    giveTabFocus: true,
    dismissOnClickBackdrop: true,
  });

  const onClickRemove = useCallback(
    (user: User) => {
      setTargetGroup(user);
      openRemoveGroupModal(true);
    },
    [setTargetGroup, openRemoveGroupModal]
  );

  return [onClickRemove, removeGroupModal] as const;
};

export const useGroupArchive = (
  targetGroup: Group | null,
  setTargetGroup: StateUpdater<Group | null>,
  onArchive?: (group: Group) => void
) => {
  const archiveGroupReq = useApiMethod("updateGroup");
  const archiveGroup = useCallback(() => {
    if (!targetGroup) {
      return Promise.resolve();
    }
    return archiveGroupReq(targetGroup.id, {
      body: { data: { attributes: { is_archived: !targetGroup.is_archived } } },
    });
  }, [archiveGroupReq, targetGroup]);

  const afterArchive = useCallback(() => {
    setTargetGroup(null);
    targetGroup &&
      onArchive?.({ ...targetGroup, is_archived: !targetGroup.is_archived });
  }, [targetGroup, setTargetGroup, onArchive]);
  const GroupArchiveModalBody = useRenderPropsFunction<ModalBodyProps>(
    ({ closeButton, ...props }) =>
      targetGroup ? (
        <DefaultModalContent
          title={`${targetGroup.is_archived ? "Unarchive" : "Archive"} Group`}
          closeButton={closeButton}
        >
          <DeleteModalBody
            deleteRecord={archiveGroup}
            afterDelete={afterArchive}
            hideWarning={true}
            {...props}
          >
            Are you sure you want to {targetGroup.is_archived ? "un" : ""}
            archive this group?{" "}
            {!targetGroup.is_archived
              ? "Group members will lose access to all of the group's posts and content."
              : ""}
          </DeleteModalBody>
        </DefaultModalContent>
      ) : null,
    "GroupArchiveModal-Bound",
    [self, archiveGroup, targetGroup, afterArchive]
  );

  const [archiveGroupModal, openArchiveGroupModal] = useModal({
    id: "archive-group-modal",
    innerClassName: "card card-stack modal-form member-list__remove__modal",
    body: GroupArchiveModalBody,
    giveTabFocus: true,
    dismissOnClickBackdrop: true,
  });

  const onClickArchive = useCallback(
    (user: User) => {
      setTargetGroup(user);
      openArchiveGroupModal(true);
    },
    [setTargetGroup, openArchiveGroupModal]
  );

  return [onClickArchive, archiveGroupModal] as const;
};
