import { SortSpec, Types } from "@thrive-web/core";
import {
  Expense,
  GroupLabel,
  parse_sort,
  stringify_sort,
} from "@thrive-web/ui-api";
import { ensure_id_is_iri } from "@thrive-web/ui-common";
import {
  ButtonWithIcon,
  NotFoundPage,
  PageBody,
  PageContent,
  PageHeader,
  SearchBar,
  useRenderDynamicListWithPagedFetch,
} from "@thrive-web/ui-components";
import { THREAD_COMM_ID } from "@thrive-web/ui-constants";
import {
  useDynamicListVariable,
  useQueryParams,
  useStateIfMounted,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { get_guid_from_iri } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import {
  ACTIVE_COMMUNITY,
  useExpenseSearch,
  ReimbursementsTable,
  ReimbursementsTableFilters,
  ExpenseDateProp,
  GenerateReportModal,
} from "~/view/components";

type PageQueryParams = {
  date_range?: string;
  date_prop?: ExpenseDateProp;
  date_to?: number;
  date_from?: number;
  sort: string;
  approved?: boolean;
  label?: GroupLabel;
};

const transform_params = (params): PageQueryParams => ({
  ...params,
  date_prop:
    !!params.date_prop && params.approved === "false"
      ? undefined
      : params.date_prop,
  date_to: isNaN(parseInt(params.date_to))
    ? undefined
    : parseInt(params.date_to),
  date_from: isNaN(parseInt(params.date_from))
    ? undefined
    : parseInt(params.date_from),
  approved:
    params.approved === "true"
      ? true
      : params.approved === "false"
      ? false
      : undefined,
  label: params.label_id
    ? {
        id: ensure_id_is_iri(params.label_id, "CommunityGroupLabel"),
        label: params.label,
      }
    : undefined,
});

export const ReimbursementsPage: Preact.FunctionComponent<RoutePageProps> =
  () => {
    const comm = useContext(ACTIVE_COMMUNITY);

    const [query, set_query] =
      useQueryParams<PageQueryParams>(transform_params);
    const query_ref = useValueRef(query);

    const {
      date_range: date_preset = "all",
      date_prop = "created_at",
      date_from,
      date_to,
      sort: sort_str = "-created_at",
      approved,
      label,
    } = query;
    const label_ref = useValueRef<GroupLabel | undefined>(label);

    const initial_date = useMemo(
      () => ({
        from: date_from ? new Date(date_from) : undefined,
        to: date_to ? new Date(date_to) : undefined,
      }),
      []
    );
    const sort = useMemo(
      () => parse_sort(sort_str as string) as SortSpec<Types["Expense"]>,
      [sort_str]
    );

    const set_query_params = useCallback((new_params: ObjectOf<any>) => {
      set_query({
        ...query_ref.current,
        label_id: label_ref.current?.id,
        // @ts-expect-error:
        label: label_ref.current?.label,
        ...new_params,
      });
    }, []);
    const [search, set_search] = useStateIfMounted("");
    const [date, set_date] =
      useStateIfMounted<Partial<DateRange>>(initial_date);

    const set_label = useCallback((value?: GroupLabel) => {
      set_query_params({
        label_id: value?.id ? get_guid_from_iri(value.id)[0] : undefined,
        label: value?.label,
      });
    }, []);

    const set_approved = useCallback(
      (value?: boolean) => set_query_params({ approved: value }),
      []
    );
    const set_date_prop = useCallback(
      (value?: string) =>
        set_query_params({
          date_prop: !!value && approved === false ? undefined : value,
        }),
      [approved]
    );

    const on_change_date = useCallback(
      (value: Partial<DateRange>, key: string) => {
        set_date(value);
        set_query_params({
          date_range: key,
          date_to: value.to?.getTime(),
          date_from: value.from?.getTime(),
        });
      },
      []
    );

    // TODO: when status set to Submitted, force date_type to be created_at

    const [filters_open, set_filters_open] = useStateIfMounted(false);

    const [list, dispatch] = useDynamicListVariable<Expense>(null);

    const fetch_expenses = useExpenseSearch(
      search,
      date.from?.getTime(),
      date.to?.getTime(),
      sort,
      date_prop,
      approved,
      label
    );

    const passthrough_props = useMemo(
      () => ({
        sort,
        onSort: (new_sort: SortSpec<Types["Expense"]>) =>
          set_query_params(stringify_sort({ sort: [new_sort] })),
      }),
      [sort]
    );

    const content = useRenderDynamicListWithPagedFetch(
      list,
      dispatch,
      (result, load_more_elem, pending, passthrough) => (
        <ReimbursementsTable
          // @ts-expect-error:
          items={result}
          loadMoreElem={load_more_elem}
          pending={pending}
          {...passthrough}
        />
      ),
      [],
      fetch_expenses,
      passthrough_props,
      { limit: 40, keepViewOnUpdate: true }
    );

    if (comm?.id !== THREAD_COMM_ID) {
      return <NotFoundPage />;
    }

    return (
      <PageContent
        id="community-expenses"
        data-page="expense-list"
        className="list-page expense-page no-separate-header"
      >
        <PageHeader
          title={<h1>Reimbursements</h1>}
          button={
            <GenerateReportModal
              type="expense"
              date={date}
              label={label}
              status={approved}
              dateType={date_prop}
            />
          }
        >
          <SearchBar
            placeholder="Search by Creator"
            onSubmit={set_search}
            value={search}
          />
          <ButtonWithIcon
            className="filled gray all-gray"
            icon={filters_open ? "subtract" : "add"}
            side="left"
            onClick={() => set_filters_open(!filters_open)}
          >
            Filters
          </ButtonWithIcon>
        </PageHeader>
        <PageBody>
          <ReimbursementsTableFilters
            open={filters_open}
            datePreset={date_preset}
            dateProperty={date_prop}
            setDateProperty={set_date_prop}
            date={date}
            setDate={on_change_date}
            approved={approved}
            setApproved={set_approved}
            label={label}
            setLabel={set_label}
          />
          {content}
        </PageBody>
      </PageContent>
    );
  };
