import * as Preact from "preact";
import { useCallback, useContext, useEffect, useMemo } from "preact/hooks";
import type { FilterClause } from "@swymbase/sparql-rest";
import { FilterSpec } from "@thrive-web/core";
import { Group, GroupLabel, User } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  Card,
  DefaultModalContent,
  DivWithIcon,
  EmptyList,
  FloatingTitle,
  InputWithDropdown,
  LazyListSection,
  PageBody,
  PageContent,
  PageHeader,
  PageSidebar,
  SearchBar,
  useLazyList,
  useRenderDynamicListWithPagedFetch,
} from "@thrive-web/ui-components";
import { ScreenSize } from "@thrive-web/ui-constants";
import {
  useApiMethod,
  useDynamicListVariable,
  useModal,
  useStateIfMounted,
  useValueRef,
} from "@thrive-web/ui-hooks";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  ACTIVE_COMMUNITY,
  CommunityGroupCreate,
  compareGroupLabels,
  getGroupLabelText,
  GroupListItem,
  MemberListLoading,
  renderGroupLabelTag,
  useGroupArchive,
  useGroupRemove,
  useGroupSearch,
} from "~/view/components";

export const CommunityGroupsList: Preact.FunctionComponent<{
  groups: Group[];
  loadMoreElem?: Preact.VNode | null;
  onClickRemove: () => void;
  onClickArchive: () => void;
  isFiltered?: boolean;
}> = ({ groups, loadMoreElem, onClickRemove, onClickArchive, isFiltered }) => {
  const content = useLazyList(
    groups,
    group => (
      <GroupListItem
        group={group}
        onClickRemove={onClickRemove}
        onClickArchive={onClickArchive}
      />
    ),
    [],
    20
  );

  if (!groups.length) {
    return (
      <EmptyList className="member-list__empty">
        No Groups in this community
        {isFiltered
          ? " that match the search criteria. Try a different search, or create"
          : ". Create"}{" "}
        a Group by clicking Create Group.
      </EmptyList>
    );
  }

  return (
    <Card className="member-list community__member-list group-list">
      {content.map((s, i) => (
        <LazyListSection key={i}>{s}</LazyListSection>
      ))}
      {loadMoreElem && <div className="load-more">{loadMoreElem}</div>}
    </Card>
  );
};

export const CommunityGroupsFiltersModal: Preact.FunctionComponent<ModalBodyProps> =
  ({ closeButton, dismiss, children }) => (
    <DefaultModalContent
      title="Search Filters"
      closeButton={closeButton}
      footer={
        <div className="modal__footer">
          <div className="modal__footer__left" />
          <div className="modal__footer__right">
            <ButtonWithIcon
              icon="checked"
              className="filled gray"
              onClick={dismiss}
            >
              Done
            </ButtonWithIcon>
          </div>
        </div>
      }
    >
      <div className="group-label__modal__body">
        <div className="form__input-row">{children}</div>
      </div>
    </DefaultModalContent>
  );

export const CommunityGroupsFilters: Preact.FunctionComponent<{
  onChangeFilters: (f: FilterClause[]) => void;
}> = ({ onChangeFilters }) => {
  const comm = useContext(ACTIVE_COMMUNITY);
  const window_size = useContext(CONTEXTS.window_size);

  const [labels, set_labels, labels_ref] = useDynamicListVariable<GroupLabel>(
    []
  );

  const get_labels_req = useApiMethod("getGroupLabels");
  const get_labels = useCallback(
    (search: string | undefined, offset: number, limit?: number) => {
      if (!comm) {
        return Promise.reject("No active community");
      }
      let filter: FilterSpec = [
        ["=", ["this", "CommunityGroupLabel:community"], ["id", comm.id]],
      ];
      if (search) {
        filter = [
          ...filter,
          ["match", ["this", "CommunityGroupLabel:label"], search, "i"],
        ] as FilterSpec;
      }
      return get_labels_req({
        query: {
          filter,
          offset,
          limit,
          include_count: true,
        },
      });
    },
    []
  );

  const on_remove_label = useCallback(
    (opt: GroupLabel) => set_labels.remove(o => o.id === opt.id),
    []
  );
  const filter_opts = useCallback(
    (opt: GroupLabel) => compareGroupLabels(opt, labels_ref.current),
    []
  );

  useEffect(() => {
    onChangeFilters(
      labels
        ? labels.map(l => ["=", ["this", "Group:has_label"], ["id", l.id]])
        : []
    );
  }, [labels]);

  const input = (
    <InputWithDropdown
      className="tag-input"
      allowMultiple={true}
      async={true}
      onChange={set_labels.add}
      onRemoveValue={on_remove_label}
      selected={labels}
      getTextFromSelected={getGroupLabelText}
      renderSelected={renderGroupLabelTag}
      placeholder="Filter By Label"
      getOptions={get_labels}
      filterOption={filter_opts}
    >
      <FloatingTitle>Labels</FloatingTitle>
    </InputWithDropdown>
  );

  const bodyProps = useMemo(() => ({ children: input }), [input]);
  const [modal, set_open] = useModal({
    id: "community-group-filters",
    className: "group-label__modal",
    body: CommunityGroupsFiltersModal,
    showCloseButton: true,
    bodyProps,
  });

  return window_size <= ScreenSize.md ? (
    <DivWithIcon
      icon="add"
      side="left"
      className="plain-link blue"
      onClick={() => set_open(true)}
    >
      Filters
      {labels?.length ? ` (${labels.length})` : ""}
      {modal}
    </DivWithIcon>
  ) : (
    <Card className="group-list__sidebar__filters">
      <div className="form__input-row">{input}</div>
    </Card>
  );
};

export const CommunityGroups: Preact.FunctionComponent<RoutePageProps> = () => {
  const comm = useContext(ACTIVE_COMMUNITY);
  const [search, set_search] = useStateIfMounted("");
  const [list, dispatch] = useDynamicListVariable<Group>(null);
  const [other_filters, set_other_filters] = useStateIfMounted<FilterClause[]>(
    []
  );
  const no_filters = useValueRef(other_filters.length === 0);
  const on_change_filters = (f: FilterClause[]) => {
    if (f.length === 0 && no_filters.current) {
      return;
    }
    set_other_filters(f);
  };

  const [fetchGroups, force_refresh] = useGroupSearch(
    comm?.id,
    search,
    other_filters
  );

  const addCommGroup = useApiMethod("addCommunityGroup");
  const onAddGroup = useCallback(
    (group: Group) => {
      if (!comm?.id) {
        return Promise.reject("No community id provided");
      }
      return addCommGroup(comm.id, { body: { data: [{ id: group.id }] } }).then(
        res => {
          force_refresh();
          return res;
        }
      );
    },
    [force_refresh, comm?.id]
  );

  const [delete_target, set_delete_target] = useStateIfMounted<User | null>(
    null
  );
  const onRemove = useCallback(
    id => dispatch.remove(m => m.id === id),
    [dispatch]
  );
  const [onClickRemove, removeGroupModal] = useGroupRemove(
    comm?.id || "",
    delete_target,
    set_delete_target,
    onRemove
  );

  const onArchive = useCallback(
    group => dispatch.update(m => m.id === group.id, group),
    [dispatch]
  );
  const [onClickArchive, archiveGroupModal] = useGroupArchive(
    delete_target,
    set_delete_target,
    onArchive
  );

  const passthrough_props = useMemo(
    () => ({
      onClickRemove,
      onClickArchive,
      isFiltered: other_filters.length > 0 || !!search,
    }),
    [onClickRemove, onClickArchive, other_filters.length > 0, !!search]
  );
  const content = useRenderDynamicListWithPagedFetch(
    list,
    dispatch,
    (result, load_more_elem, _, passthrough) => (
      <CommunityGroupsList
        // @ts-expect-error:
        groups={result}
        loadMoreElem={load_more_elem}
        {...passthrough}
      />
    ),
    [],
    fetchGroups,
    passthrough_props,
    { PendingView: MemberListLoading, limit: 20 }
  );

  if (!comm) {
    return null;
  }
  return (
    <PageContent
      id="community-groups"
      data-page="community-groups-list"
      className="list-page community-page no-separate-header has-sidebar"
    >
      <PageHeader
        title={<h1>Groups</h1>}
        button={<CommunityGroupCreate record={comm} onAdd={onAddGroup} />}
      >
        <SearchBar placeholder="Search" onSubmit={set_search} value={search} />
      </PageHeader>
      <PageBody className="group-list__body">
        {content}
        <PageSidebar className="group-list__sidebar group-list__filters">
          <CommunityGroupsFilters onChangeFilters={on_change_filters} />
        </PageSidebar>
        {removeGroupModal}
        {archiveGroupModal}
      </PageBody>
    </PageContent>
  );
};
