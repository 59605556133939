import * as Preact from "preact";
import { useContext, useEffect, useMemo } from "preact/hooks";
import { media_url } from "@thrive-web/core";
import { Community } from "@thrive-web/ui-api";
import {
  Card,
  PageBody,
  PageContent,
  PageHeader,
} from "@thrive-web/ui-components";
import { useApiFetch, useMedia } from "@thrive-web/ui-hooks";
import { CONTEXTS } from "@thrive-web/ui-model";
import { display_text } from "@thrive-web/ui-utils";
import { ACTIVE_COMMUNITY, SET_ACTIVE_COMMUNITY } from "~/view/components";

const params = {
  query: {
    include: ["cover_image", "avatar_image"],
  },
} as const;
export const Home: Preact.FunctionComponent<{ community: Community }> = ({
  community,
}) => {
  const set_comm = useContext(SET_ACTIVE_COMMUNITY);
  const get_comm = useApiFetch("getCommunity", community.id, params);
  useEffect(() => {
    get_comm()
      .then(({ data }) => {
        set_comm(data);
      })
      .catch(() => {});
  }, []);

  /*  const get_touchpoints_req = useApiMethod("getTouchpoints");
  const get_engagement_req = useApiMethod("getUsers");
  const [touchpoints, get_touchpoints, t_status] = useControlledRequest(
    get_touchpoints_req,
    ({ meta }) => meta?.total_result_count as number | undefined
  );
  const [eng_users, get_engagement, e_status] = useControlledRequest(
    get_engagement_req,
    ({ meta }) => meta?.total_result_count as number | undefined
  );

  useEffect(() => {
    get_touchpoints(community_touchpoint_count_query(community.id));
    get_engagement(community_engagement_query(community.id));
  }, [community.id]);*/

  const group_count = community.has_group?.length || 0;
  const member_count = community.has_member?.length || 0;
  /*
  const engagement_percent =
    eng_users != null
      ? member_count > 0
        ? Math.round(100 * (eng_users / member_count))
        : 0
      : undefined;

  const touchpoints_count = useAsyncRenderResult(
    result => <Preact.Fragment>{result || 0}</Preact.Fragment>,
    [],
    t_status,
    touchpoints != null ? `${touchpoints.toLocaleString()}` : undefined,
    false,
    undefined,
    undefined,
    false
  );
  const engagement_value = useAsyncRenderResult(
    result => <Preact.Fragment>{result || "--"}%</Preact.Fragment>,
    [],
    e_status,
    engagement_percent != null
      ? `${engagement_percent.toLocaleString()}`
      : undefined,
    false,
    undefined,
    undefined,
    false
  );
*/

  const cards = [
    // { value: touchpoints_count, label: "Touchpoints" },
    // { value: engagement_value, label: "Engagement" },
    { value: group_count.toLocaleString(), label: "Groups" },
    { value: member_count.toLocaleString(), label: "Members" },
  ];

  return (
    <PageContent id="home-page" className="home-page no-separate-header">
      <CommunityBanner community={community} />
      <PageBody>
        <div className="home-page__cards">
          {cards.map(c => (
            <Card key={c.label} className="home-page__card">
              <h1 className="home-page__card__value">{c.value}</h1>
              <h6 className="home-page__card__label">{c.label}</h6>
            </Card>
          ))}
        </div>
      </PageBody>
    </PageContent>
  );
};

export const CommunityBanner: Preact.FunctionComponent<{
  community: Community;
}> = ({ community }) => {
  const window_size = useContext(CONTEXTS.window_size);
  const cover_url = useMedia<"Community", "cover_image">(
    community,
    "cover_image",
    1,
    window_size
  );
  const avatar_url = useMemo(
    () =>
      media_url<"Community", "avatar_image">(
        community,
        "avatar_image",
        "small"
      ),
    [community]
  );

  return (
    <PageHeader className="banner-header">
      <div
        className="community-page__banner page-header__banner"
        style={{
          backgroundImage: `url(${cover_url})`,
        }}
      >
        <div className="page-header__title">
          <img className="avatar community-page__avatar" src={avatar_url} />
          <span>{display_text(community.name)}</span>
        </div>
        <div className="page-header__subtitle">
          {display_text(community.purpose)}
        </div>
      </div>
    </PageHeader>
  );
};

export const HomePage: Preact.FunctionComponent<RoutePageProps> = () => {
  const comm = useContext(ACTIVE_COMMUNITY);
  return comm ? <Home community={comm} /> : null;
};
