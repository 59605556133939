import * as Preact from "preact";
import { forwardRef } from "preact/compat";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "preact/hooks";
import { FilterSpec } from "@thrive-web/core";
import { GroupLabel } from "@thrive-web/ui-api";
import {
  DropdownSelectInput,
  DropdownSelectInputDefaultTextInput,
  FloatingTitle,
  Icon,
  InputWithDropdown,
} from "@thrive-web/ui-components";
import {
  useApiMethod,
  useStateIfMounted,
  useTimer,
} from "@thrive-web/ui-hooks";
import { CONTEXTS } from "@thrive-web/ui-model";
import {
  ACTIVE_COMMUNITY,
  ExpenseDateProp,
  getGroupLabelText,
  ReimbursementsDateFilter,
} from "~/view/components";

const EXPENSE_STATUS_OPTS = [
  { value: undefined, label: "All" },
  { value: false, label: "Submitted" },
  { value: true, label: "Approved" },
];
const filter_opts = () => true;
export const ReimbursementLabelFilter: Preact.FunctionComponent<{
  value?: GroupLabel;
  onChange: (label?: GroupLabel) => void;
}> = ({ value, onChange }) => {
  const comm = useContext(ACTIVE_COMMUNITY);
  const get_labels_req = useApiMethod("getGroupLabels");
  const get_labels = useCallback(
    (search: string | undefined, offset: number, limit?: number) => {
      if (!comm) {
        return Promise.reject("No active community");
      }
      let filter: FilterSpec = [
        ["=", ["this", "CommunityGroupLabel:community"], ["id", comm.id]],
      ];
      if (search) {
        filter = [
          ...filter,
          ["match", ["this", "CommunityGroupLabel:label"], search, "i"],
        ] as FilterSpec;
      }
      return get_labels_req({
        query: {
          filter,
          offset,
          limit,
          include_count: true,
        },
      });
    },
    []
  );

  return (
    <InputWithDropdown<GroupLabel, false, true, false>
      async={true}
      onChange={onChange}
      value={value}
      getTextFromSelected={getGroupLabelText}
      placeholder="Filter By Label"
      getOptions={get_labels}
      filterOption={filter_opts}
    >
      <Icon className="select__caret" name="disclosure" />
      <FloatingTitle>Label</FloatingTitle>
    </InputWithDropdown>
  );
};

const get_status_text = opt => opt.label;
const button_props = { placeholder: "Filter By Status", label: "Status" };
const StatusButton: Preact.FunctionComponent<
  MaybeClass &
    OptionsListControlProps<HTMLDivElement> & {
      children: string | Preact.VNode;
    }
> = forwardRef<
  HTMLDivElement,
  MaybeClass &
    OptionsListControlProps<HTMLDivElement> & {
      value?: string;
      children: string | Preact.VNode;
    }
>(({ className, children, value, ...props }, ref) => (
  <DropdownSelectInputDefaultTextInput
    {...props}
    ref={ref}
    value={value === "All" ? "" : value}
  >
    {children}
  </DropdownSelectInputDefaultTextInput>
));
const open_delay = 200;

export const ReimbursementsTableFilters: Preact.FunctionComponent<{
  open: boolean;
  datePreset: string;
  dateProperty: ExpenseDateProp;
  setDateProperty: (value: ExpenseDateProp) => void;
  date?: Partial<DateRange>;
  setDate: (date: Partial<DateRange>, key: string) => void;
  approved?: boolean;
  setApproved: (status?: boolean) => void;
  label?: GroupLabel;
  setLabel: (label?: GroupLabel) => void;
}> = ({
  open,
  datePreset,
  dateProperty,
  date,
  approved,
  label,
  setDateProperty,
  setDate,
  setApproved,
  setLabel,
}) => {
  useContext(CONTEXTS.window_size);
  const filters_div = useRef<HTMLDivElement>();
  const [overflow, set_overflow] = useStateIfMounted(!open);
  const [toggle_overflow, cancel_toggle] = useTimer(
    () => set_overflow(false),
    open_delay
  );
  useEffect(() => {
    if (open) {
      toggle_overflow();
    } else {
      cancel_toggle();
      set_overflow(true);
    }
  }, [open]);

  const on_change_approved = useCallback(
    opt => setApproved(opt.value),
    [setApproved]
  );
  const approved_val = useMemo(
    () =>
      EXPENSE_STATUS_OPTS.find(o => o.value === approved) ??
      EXPENSE_STATUS_OPTS[0],
    [approved]
  );

  return (
    <div
      className="expenses__filters__container"
      style={{
        height: `${!open ? 0 : filters_div.current?.offsetHeight ?? 0}px`,
        overflow: overflow ? "hidden" : "visible",
      }}
    >
      <div className="expenses__filters" ref={filters_div}>
        <ReimbursementsDateFilter
          preset={datePreset}
          property={dateProperty}
          value={date}
          onChange={setDate}
          onChangeProperty={setDateProperty}
          disabled={approved === false}
        />
        <DropdownSelectInput
          className="expenses__status-filter"
          options={EXPENSE_STATUS_OPTS}
          onSelect={on_change_approved}
          label="Status"
          getValueLabel={get_status_text}
          Button={StatusButton}
          buttonProps={button_props}
          value={approved_val}
        />
        <ReimbursementLabelFilter value={label} onChange={setLabel} />
        <a href="/reimbursements" className="plain-link blue">
          Reset Filters
        </a>
      </div>
    </div>
  );
};
