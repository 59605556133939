import { SortSpec } from "@thrive-web/core";
import { DefaultPendingView, Icon } from "@thrive-web/ui-components";
import { useCallbackRef, useSingleClickDetection } from "@thrive-web/ui-hooks";
import { maybe_route, maybeClassName } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { useRef } from "preact/hooks";

export interface SortableTableColumn<T extends object, K extends keyof T> {
  label: string | Preact.VNode;
  sortable?: boolean;
  renderCell: (
    value: T[K],
    item: T
  ) => Preact.VNode | string | number | null | undefined;
  cancelRowLink?: boolean;
}

const opposite_dir = (dir: "asc" | "desc") => (dir === "asc" ? "desc" : "asc");

export interface SortableTableProps<T extends object> extends MaybeClass {
  columns: {
    [K in keyof T]?: SortableTableColumn<T, K>;
  };
  items: T[];
  getRowId: (item: T) => string | number;
  getRowLink?: (item: T) => string;
  sort?: SortSpec<T>;
  onSort: (sort: SortSpec<T>) => void;
  loadMoreElem?: Preact.VNode | null;
  pending?: boolean;
  offset: number;
  emptyView?: Preact.VNode | null;
}

export const SortableTable = <T extends object>({
  columns,
  items,
  sort,
  onSort,
  getRowId,
  getRowLink,
  loadMoreElem,
  className,
  pending,
  offset,
  emptyView,
}: Preact.RenderableProps<SortableTableProps<T>>): Preact.VNode | null => {
  const cancel_link = useRef(false);
  const link_target = useRef<T | null>();
  const on_click_row = useCallbackRef(
    e => {
      if (cancel_link.current || !link_target.current || !getRowLink) {
        cancel_link.current = false;
        link_target.current = null;
        return;
      }
      maybe_route(getRowLink(link_target.current));
    },
    [getRowLink]
  );

  const on_cancel_click_row = useCallbackRef(e => {
    cancel_link.current = false;
    link_target.current = null;
  }, []);

  const on_start_click_row = useCallbackRef(e => {
    if (!e.cancelRowLink) {
      link_target.current = e.linkTarget;
    }
  }, []);

  const listeners = useSingleClickDetection(
    on_click_row,
    on_start_click_row,
    on_cancel_click_row
  );

  return (
    <div className="table__container">
      <table
        className={`sortable-table${
          pending ? " sortable-table__loading" : ""
        }${maybeClassName(className)}`}
        cellSpacing={0}
      >
        <thead>
          <tr>
            {(
              Object.entries(columns) as [
                keyof T,
                SortableTableColumn<T, keyof T>
              ][]
            ).map(([key, c]) => {
              if (!c.sortable) {
                return (
                  <th key={key} data-column={key}>
                    <div className="sortable-table__header__label">
                      {c.label}
                    </div>
                  </th>
                );
              }

              const is_sort = sort && sort.by === key;
              return (
                <th
                  key={key}
                  data-column={key}
                  data-sortable={true}
                  onClick={
                    pending
                      ? undefined
                      : () =>
                          // @ts-expect-error:
                          onSort({
                            by: key,
                            dir:
                              sort && is_sort ? opposite_dir(sort.dir) : "asc",
                          })
                  }
                >
                  <div className="sortable-table__header__cell">
                    <div className="sortable-table__header__label">
                      {c.label}
                    </div>
                    <Icon
                      name="disclosure"
                      data-badge="conceal"
                      data-sort={is_sort ? sort!.dir : undefined}
                    />
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {items.map(r => (
            <tr
              key={getRowId(r)}
              data-link={!!getRowLink}
              onMouseDownCapture={e => {
                // @ts-expect-error:
                e.linkTarget = r;
              }}
              {...(getRowLink ? listeners : {})}
            >
              {(Object.keys(columns) as (keyof T)[]).map(k => (
                <td
                  data-column={k}
                  data-sortable={columns[k]!.sortable}
                  onMouseDownCapture={
                    getRowLink && columns[k]!.cancelRowLink
                      ? e => {
                          // @ts-expect-error:
                          e.cancelRowLink = true;
                          cancel_link.current = true;
                        }
                      : undefined
                  }
                  data-cancel-link={getRowLink && columns[k]!.cancelRowLink}
                >
                  {columns[k]!.renderCell(r[k], r)}
                </td>
              ))}
            </tr>
          ))}
          {loadMoreElem && (
            <tr key="sortable-table-load-more">
              <td colSpan={Object.keys(columns).length}>
                <div className="sortable-table__load-more">{loadMoreElem}</div>
              </td>
            </tr>
          )}
          {emptyView && items.length === 0 && (
            <tr>
              <td colSpan={Object.keys(columns).length}>{emptyView}</td>
            </tr>
          )}
        </tbody>

        {pending && offset === 0 && (
          <tbody className="sortable-table__reloading">
            <div>
              <DefaultPendingView />
            </div>
          </tbody>
        )}
      </table>
    </div>
  );
};
