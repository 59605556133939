import * as Preact from "preact";
import { useMemo } from "preact/hooks";
import { RecordType } from "@thrive-web/ui-api";
import { AvatarLoading, EntityLink } from "@thrive-web/ui-components";
import { maybeClassName } from "@thrive-web/ui-utils";

export const MemberListItem: Preact.FunctionComponent<
  {
    icon: Preact.VNode;
    title: string | Preact.VNode;
    entity?: RecordType;
    status?: string;
    actions?: Preact.VNode;
  } & MaybeClass
> = ({ icon, title, entity, status, actions, className }) => {
  const Tag = entity ? EntityLink : "div";

  return (
    // @ts-expect-error:
    <Tag
      {...(entity ? { entity } : {})}
      className={`member-list__item${maybeClassName(className)}`}
    >
      <div className="member-list__item__content">
        <div className="member-list__item__left">
          <div className="member-list__item__icon">{icon}</div>
          <div className="member-list__item__title">{title}</div>
        </div>
        {(actions || status) && (
          <div className="member-list__item__right" onClick={() => {}}>
            {status && (
              <div className="member-list__item__status">{status}</div>
            )}
            {actions && (
              <div
                className="member-list__item__actions"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {actions}
              </div>
            )}
          </div>
        )}
      </div>
    </Tag>
  );
};

export const MemberListLoading: Preact.FunctionComponent = () => {
  const items = useMemo(() => new Array(5).fill(0), []);
  return (
    <ul className="card member-list loading-item__list">
      <div className="lazy-list-section">
        {items.map((_, i) => (
          <MemberListLoadingItem key={i} />
        ))}
      </div>
    </ul>
  );
};

export const MemberListLoadingItem: Preact.FunctionComponent = () => {
  const width = useMemo(() => `${Math.round(Math.random() * 8) + 8}rem`, []);
  return (
    <MemberListItem
      className="loading-item"
      icon={<AvatarLoading className="loading-item__shaded--dark" size="md" />}
      title={
        <div
          className="user-list__item__name loading-item__text loading-item__shaded loading-item__shaded--dark"
          style={{ width }}
        />
      }
    />
  );
};
