import * as Preact from "preact";
import { useCallback, useMemo } from "preact/hooks";
import {
  DynamicListCtxSpec,
  DynamicListDispatchDefault,
  DynamicListProvider,
  DynamicListStatusDefault,
  THRIVE_GROUPS,
} from "@thrive-web/ui-components";
import { Community } from "@thrive-web/ui-api";
import { useApiMethod, useAppUser } from "@thrive-web/ui-hooks";
import { IMAGES_PREFIX, THREAD_COMM_ID } from "@thrive-web/ui-constants";
import { createNamedContext } from "@thrive-web/ui-utils";
import { mngd_communities_filter } from "~/utils";
import { useCommunitySelector } from "~/view/utils/hooks/admin";

export const MNGD_COMMUNITIES: Preact.Context<
  DynamicListCtxSpec<Community, true, [boolean?]>
> = Preact.createContext({
  list: null,
  dispatch: DynamicListDispatchDefault,
  status: DynamicListStatusDefault,
});

export const ACTIVE_COMMUNITY: Preact.Context<Community | null> =
  createNamedContext(null, "ActiveCommunity");

export const SET_ACTIVE_COMMUNITY: Preact.Context<
  (community: Community | null) => void
> = createNamedContext(() => {}, "SetActiveCommunity");

export const WithGlobalData: Preact.FunctionComponent = ({ children }) => {
  const user = useAppUser();

  const fetchCommunities = useApiMethod("getCommunities");
  const fetchManagedCommunities = useCallback(() => {
    if (!user) {
      return Promise.resolve({ data: null });
    }
    return fetchCommunities({
      query: {
        filter: mngd_communities_filter(user),
        include_count: true,
        include: ["cover_image", "avatar_image"],
      },
    });
  }, [fetchCommunities, user?.id]);

  const [active_community, set_active_community] = useCommunitySelector(user);

  const ACProvider = ACTIVE_COMMUNITY.Provider;
  const SCProvider = SET_ACTIVE_COMMUNITY.Provider;
  const TGProvider = THRIVE_GROUPS.Provider;

  const thread_value = useMemo(() => {
    const groups =
      active_community?.id === THREAD_COMM_ID
        ? active_community.has_group?.slice() || null
        : null;
    return { list: groups, fetch: () => Promise.resolve(groups) };
  }, [active_community?.has_group]);

  if (user && !active_community) {
    return (
      <div className="app-loading">
        <div className="app-loading__spinner">
          <img src={`${IMAGES_PREFIX}/logo_spinner.gif`} />
        </div>
        <div className="app-loading__status">Loading</div>
      </div>
    );
  }

  if (user) {
    return (
      <DynamicListProvider
        context={MNGD_COMMUNITIES}
        fetch={fetchManagedCommunities}
      >
        <ACProvider value={active_community}>
          <TGProvider value={thread_value}>
            <SCProvider value={set_active_community}>{children}</SCProvider>
          </TGProvider>
        </ACProvider>
      </DynamicListProvider>
    );
  } else {
    return <Preact.Fragment>{children}</Preact.Fragment>;
  }
};
