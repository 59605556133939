import * as Preact from "preact";
import { useCallback, useContext, useMemo } from "preact/hooks";
import { User } from "@thrive-web/ui-api";
import {
  ButtonWithIcon,
  Carousel,
  DefaultModalContent,
} from "@thrive-web/ui-components";
import { useModal, useStateIfMounted } from "@thrive-web/ui-hooks";
import {
  ACTIVE_COMMUNITY,
  CommMemberCreate,
  CommMemberFindByEmail,
  CommMemberSuccess,
} from "~/view/components";

export const CommunityMemberAddModal: Preact.FunctionComponent<
  ModalBodyProps & {
    afterAdd: (user: User) => void;
  }
> = ({ afterAdd, closeButton, dismiss }) => {
  const comm = useContext(ACTIVE_COMMUNITY);
  const [page, set_page] = useStateIfMounted<"search" | "create" | "success">(
    "search"
  );
  const [email, set_email] = useStateIfMounted("");
  const [user, set_user] = useStateIfMounted<User | null>(null);

  const onFinishSearch = useCallback(
    (email_: string, user_?: User) => {
      if (!user_) {
        set_email(email_);
        set_page("create");
      } else {
        set_user(user_);
        set_page("success");
      }
    },
    [set_user]
  );

  const onFinishCreate = useCallback(
    (user_: User) => {
      set_user(user_);
      set_page("success");
    },
    [set_user]
  );

  if (!comm) {
    return null;
  }

  const pages = {
    search: <CommMemberFindByEmail onFinish={onFinishSearch} />,
    create: (
      <CommMemberCreate
        onCreate={onFinishCreate}
        email={email}
        community={comm}
      />
    ),
    success: <CommMemberSuccess user={user} />,
  };

  return (
    <DefaultModalContent title="Add Community Member" closeButton={closeButton}>
      <Carousel page={page} items={pages} />
    </DefaultModalContent>
  );
};

export const CommunityMemberAdd: Preact.FunctionComponent<{
  afterAdd: (user: User) => void;
}> = ({ afterAdd }) => {
  const bodyProps = useMemo(
    () => ({
      afterAdd,
    }),
    [afterAdd]
  );

  const [modal, set_open] = useModal({
    body: CommunityMemberAddModal,
    id: "member-add",
    className: "member-add__modal modal-form",
    showCloseButton: true,
    bodyProps,
  });

  return (
    <ButtonWithIcon
      icon="add"
      side="left"
      className="filled gray"
      onClick={() => set_open(true)}
    >
      Add Member
      {modal}
    </ButtonWithIcon>
  );
};
