import { SortSpec, Types } from "@thrive-web/core";
import { Expense, User } from "@thrive-web/ui-api";
import { moment } from "@thrive-web/ui-common";
import { cache_record, get_url_for_entity } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { useCallback } from "preact/hooks";
import { SortableTable, SortableTableColumn } from "~/view/components";

const get_id = r => r.id;

export const REIMBURSEMENT_COLUMNS: {
  [K in keyof Expense]?: SortableTableColumn<Expense, K>;
} = {
  created_by: {
    label: "Creator",
    sortable: false,
    cancelRowLink: true,
    renderCell: (user: User) => (
      <a className="plain-link" href={get_url_for_entity(user)}>
        {user.full_name}
      </a>
    ),
  },
  vendor_name: {
    label: "Vendor",
    sortable: true,
    renderCell: v => v || "",
  },
  requested_amount: {
    label: "Amount Requested",
    sortable: true,
    renderCell: (amount: number) => {
      const whole = Math.floor(amount);
      const frac = amount - whole;
      return `$${whole.toLocaleString()}.${frac.toFixed(2).replace("0.", "")}`;
    },
  },
  created_at: {
    label: "Submitted Date",
    sortable: true,
    renderCell: (date: string) => moment(date).format("MM/DD/YYYY"),
  },
  approved_at: {
    label: "Approved Date",
    sortable: true,
    renderCell: (date?: string) =>
      date ? moment(date).format("MM/DD/YYYY") : undefined,
  },
  is_approved: {
    label: "Status",
    sortable: true,
    renderCell: (value: boolean) => (value ? "Approved" : "Submitted"),
  },
};

export const ReimbursementsTable: Preact.FunctionComponent<{
  items: Expense[];
  sort?: SortSpec<Types["Expense"]>;
  onSort: (sort: SortSpec<Types["Expense"]>) => void;
  loadMoreElem?: Preact.VNode | null;
  pending?: boolean;
  total?: number;
  offset: number;
}> = ({ total, ...props }) => {
  const get_row_url = useCallback((rec: Expense) => {
    cache_record(rec, true);
    return get_url_for_entity(rec);
  }, []);

  return (
    <SortableTable
      className="expenses__table"
      columns={REIMBURSEMENT_COLUMNS}
      getRowId={get_id}
      getRowLink={get_row_url}
      {...props}
      emptyView={
        <div className="sortable-table__empty">No reimbursements found</div>
      }
    />
  );
  /*return <Preact.Fragment>
    {
      <div className="page-tab__section__title">
        {total} reimbursements
      </div>
    }
    <SortableTable columns={columns} getRowId={get_id} {...props} />
  </Preact.Fragment>;*/
};
