import { make_displayable_error } from "@thrive-web/ui-common";
import * as firebase from "firebase/app";
import "firebase/auth";
import {
  map_json_doc_to_record,
  map_json_list_doc_to_record_list,
} from "@thrive-web/core";
import api from "~/api";
import authWrapper from "~/auth/auth";
import {
  ANON_ONLY_PAGES,
  DEFAULT_APP_LOCATION,
  LOGIN_LOCATION,
  PUBLIC_PAGES,
  REGISTER_PAGES,
  SETUP_PAGES,
  AppAuthCtx,
} from "@thrive-web/ui-model";
import {
  get_stored_community_id,
  mngd_communities_filter,
  store_current_community_id,
} from "~/utils";

const NO_COMMS_ERROR: DisplayableError = {
  code: "auth/no-mngd-communities",
  message: "Invalid email or password",
};

const zendeskAuthFn = cb => {
  api.zendeskSupportLogin().then((response: any) => {
    if (response.jwt) {
      console.log(`Succesfully obtained Zendesk Support JWT from Thread API.`);
      cb(response.jwt);
    } else {
      console.warn(
        `Failed to get Zendesk Support JWT from Thread API:`,
        response
      );
    }
  });
};

export const verifyAuthStateAndRoute = async (
  auth: AppAuthCtx,
  url: string
): Promise<string | void> => {
  const { user, firebaseUser } = auth;

  // 1. No firebase user
  if (!firebaseUser) {
    if (![...ANON_ONLY_PAGES, ...PUBLIC_PAGES].includes(url)) {
      return LOGIN_LOCATION;
    }
    return;
  }
  const { emailVerified, uid } = firebaseUser;

  // 2. Firebase user without verified email
  if (!emailVerified) {
    if (![...REGISTER_PAGES, ...PUBLIC_PAGES].includes(url)) {
      window.location.pathname = "/";
    }
    return;
  }

  let _user;

  // 3. API user is missing or (somehow) OBE
  if (!user || uid !== user.firebase_uuid) {
    let needs_to_finish_registration = false;
    let invalid_credentials = false;
    api.setAuthToken(await authWrapper.getToken());
    // call /self
    _user = await api
      .registered()
      .then(async response => {
        const res_data = map_json_doc_to_record(response);
        // Check if user is admin/manager of at least one community
        // (data in response is not used elsewhere)
        const communities = await api
          .getCommunities({
            query: {
              filter: mngd_communities_filter(res_data),
              include_count: true,
              limit: 1,
              fields: {
                Community: ["has_admin", "has_manager"],
              },
            },
          })
          .then(data => {
            if (data.meta?.total_result_count) {
              const list = map_json_list_doc_to_record_list(data);
              if (!get_stored_community_id(res_data.id)) {
                store_current_community_id(res_data.id, list[0].id);
              }
              return list;
            }
          })
          .catch(err => {
            console.error(`Failed to fetch managed communities on login:`, err);
            return err;
          });
        if (!communities) {
          return NO_COMMS_ERROR;
        }

        authWrapper.setApiUser(res_data, false);
        return res_data;
      })
      .catch(errorResponse => {
        // if response is 403, user needs to finish registering
        if (errorResponse.status === 403) {
          needs_to_finish_registration = true;
        } else if (errorResponse.status === 401) {
          invalid_credentials = true;
        } else {
          console.error(
            `Failed to fetch app user with firebase token: `,
            errorResponse
          );
          return make_displayable_error(errorResponse);
        }
      });

    // legacy code; this should only happen if the account was created using the OLD
    // signup flow
    if (needs_to_finish_registration) {
      await firebase
        .auth()
        .currentUser?.delete()
        .then(() => {
          window.location.pathname = "/register/failed";
        });
      return "/logout";
    }
    if (invalid_credentials) {
      authWrapper.logout();
      return;
    }
    if (_user && _user.code && _user.message) {
      return url !== "/login" ? "/logout" : Promise.reject(_user);
    }
    if (!_user) {
      return "/logout";
    }
    auth = authWrapper.getAuthState();
  } else {
    _user = user;
  }
  if (window["zEGetAuthJwt"] !== zendeskAuthFn) {
    window["zEGetAuthJwt"] = zendeskAuthFn;
  }

  if ([...ANON_ONLY_PAGES, ...SETUP_PAGES].includes(url)) {
    return DEFAULT_APP_LOCATION;
  }
};

export const onAuthChange = async (auth: AppAuthCtx) =>
  verifyAuthStateAndRoute(auth, window.location.pathname);
export const onRouteChange = async (url: string) =>
  verifyAuthStateAndRoute(authWrapper.getAuthState(), url);
