import { format_day_range, maybeClassName } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { forwardRef } from "preact/compat";
import { useCallback, useMemo, useRef } from "preact/hooks";
import { moment } from "@thrive-web/ui-common";
import {
  DateRangePicker,
  DateRangePickerControlProps,
  DefaultModalContent,
  DropdownMenu,
  DropdownSelectInput,
  Icon,
  WithFloatingTitle,
} from "@thrive-web/ui-components";
import {
  useModal,
  useRenderPropsFunction,
  useStateIfMounted,
} from "@thrive-web/ui-hooks";
import { ExpenseDateProp } from "~/view/components";

const no_filter = { from: undefined, to: undefined };
const day = 1000 * 60 * 60 * 24;
export const EXPENSE_DATE_FILTER_OPTIONS: readonly {
  label: string;
  key: string;
  value: () => Partial<DateRange>;
}[] = [
  {
    label: "All Time",
    key: "all",
    value: () => no_filter,
  },
  {
    label: "Last 7 days",
    key: "last_7_days",
    value: () => ({ from: new Date(Date.now() - 7 * day) }),
  },
  {
    label: "Last Week (Mon - Sun)",
    key: "last_week",
    value: () => {
      const last_sun =
        moment().isoWeekday("Sunday").startOf("day").valueOf() - 6 * day - 1;
      return { from: new Date(last_sun - 7 * day + 1), to: new Date(last_sun) };
    },
  },
  {
    label: "Last 30 days",
    key: "past_month",
    value: () => ({ from: new Date(Date.now() - 30 * day) }),
  },
  {
    label: "This year",
    key: "this_year",
    value: () => ({ from: new Date(moment().startOf("year").valueOf()) }),
  },
] as const;

export const EXPENSE_DATE_PROP_OPTIONS: ObjectOf<InputOption<ExpenseDateProp>> =
  {
    created_at: {
      label: "Submitted Date",
      value: "created_at",
    },
    approved_at: {
      label: "Approved Date",
      value: "approved_at",
    },
  };
const EXPENSE_DATE_PROP_OPTIONS_ARR = Object.values(EXPENSE_DATE_PROP_OPTIONS);

const popover_props: Partial<PopoverProps> = {
  defaultOffset: "left",
};

export const ReimbursementsDatePicker: Preact.FunctionComponent<{
  value: Partial<DateRange>;
  preset: string;
  onChange: (value: Partial<DateRange>, key: string) => void;
}> = ({ value, onChange, preset }) => {
  const initial = useMemo(
    () => EXPENSE_DATE_FILTER_OPTIONS.find(o => o.key === preset),
    []
  );
  const [label, set_label] = useStateIfMounted<string | undefined>(
    initial?.label
  );

  const on_select_custom = useCallback(
    ({ to, from }: Partial<DateRange>) =>
      onChange(
        {
          from: from
            ? new Date(moment(from).startOf("day").valueOf())
            : undefined,
          to: to ? new Date(moment(to).endOf("day").valueOf()) : undefined,
        },
        "custom"
      ),
    [onChange]
  );

  const picker_button = useRef<HTMLButtonElement>();

  const items = useMemo(
    () =>
      EXPENSE_DATE_FILTER_OPTIONS.map(opt => {
        return (
          <div
            key={opt.label}
            className="dropdown-menu__item"
            data-active={`${label === opt.label}`}
            onClick={() => {
              onChange(opt.value(), opt.key);
              set_label(opt.label);
            }}
          >
            {opt.label}
          </div>
        );
      }).concat([
        <div className="dropdown__divider" />,
        <div
          className="dropdown-menu__item"
          data-active={`${!label}`}
          onClick={() => {
            set_label("");
            setTimeout(() => {
              picker_button.current?.click();
              picker_button.current?.focus();
            }, 100);
          }}
        >
          Custom
        </div>,
      ]),
    [set_label, label, onChange]
  );
  const range = useMemo(
    () => (value && !label ? format_day_range(value) : undefined),
    [!!label, value]
  );

  const picker_trigger = useRenderPropsFunction(
    ({
      value: _,
      onChange: __,
      className,
      ...controlProps
    }: DateRangePickerControlProps) => (
      <button
        className={`${maybeClassName(className)}`}
        ref={picker_button}
        {...controlProps}
      />
    ),
    "DateRangePicker-Trigger",
    [picker_button]
  );

  return (
    <div className="expenses__date-filter__container">
      <DropdownMenu
        className="expenses__date-filter"
        items={items}
        listClassName="expenses__date-filter__list card"
        button={
          <button
            className="fake-input input__no-title"
            data-badge="disclosure"
          >
            {label || range}
          </button>
        }
      />
      <DateRangePicker
        start={value.from}
        end={value.to}
        onChange={on_select_custom}
        popoverProps={popover_props}
        allowSingleDay={true}
      >
        {picker_trigger}
      </DateRangePicker>
    </div>
  );
};

const get_label = o => o.label;
const DropdownSelectInputFakeInput: Preact.FunctionComponent<
  MaybeClass &
    OptionsListControlProps<HTMLButtonElement> & {
      children: string | Preact.VNode;
    }
> = forwardRef<
  HTMLButtonElement,
  MaybeClass &
    OptionsListControlProps<HTMLButtonElement> & {
      value?: string;
      children: string | Preact.VNode;
    }
>(({ className, children, value, ...props }, ref) => (
  <button
    {...props}
    ref={ref}
    className={`fake-input input__no-title${maybeClassName(className)}`}
    data-badge="disclosure"
  >
    {value || children}
  </button>
));
export const ReimbursementsDateFilterModal: Preact.FunctionComponent<
  ModalBodyProps & {
    value?: Partial<DateRange>;
    onChange: (value: Partial<DateRange>, key: string) => void;
    property: ExpenseDateProp;
    onChangeProperty: (value: ExpenseDateProp) => void;
    preset: string;
    disabled?: boolean;
  }
> = ({
  closeButton,
  property,
  onChangeProperty,
  preset,
  value = no_filter,
  onChange,
  disabled,
}) => {
  const on_change_prop = useCallback(
    (opt: InputOption<ExpenseDateProp>) => onChangeProperty(opt.value),
    [onChangeProperty]
  );

  return (
    <DefaultModalContent title="Filter By Date" closeButton={closeButton}>
      <DropdownSelectInput
        className="expenses__date-prop-filter"
        options={EXPENSE_DATE_PROP_OPTIONS_ARR}
        onSelect={on_change_prop}
        label="Property"
        getValueLabel={get_label}
        Button={DropdownSelectInputFakeInput}
        value={EXPENSE_DATE_PROP_OPTIONS[property]}
        disabled={disabled}
      />

      <ReimbursementsDatePicker
        value={value}
        onChange={onChange}
        preset={preset}
      />
    </DefaultModalContent>
  );
};

export const ReimbursementsDateFilter: Preact.FunctionComponent<{
  value?: Partial<DateRange>;
  onChange: (value: Partial<DateRange>, key: string) => void;
  property: ExpenseDateProp;
  onChangeProperty: (value: ExpenseDateProp) => void;
  preset: string;
  disabled?: boolean;
}> = ({
  property,
  onChangeProperty,
  preset,
  value = no_filter,
  onChange,
  disabled,
}) => {
  const modal_props = useMemo(
    () => ({
      property,
      onChangeProperty,
      preset,
      value,
      onChange,
      disabled,
    }),
    [property, onChangeProperty, preset, value, onChange, disabled]
  );

  const [modal, set_open] = useModal({
    id: "expense-filter-date-modal",
    className: "expenses__date-filter__modal",
    body: ReimbursementsDateFilterModal,
    showCloseButton: true,
    bodyProps: modal_props,
  });

  const range = useMemo(
    () =>
      value && preset === "custom"
        ? format_day_range(value)
        : EXPENSE_DATE_FILTER_OPTIONS.find(o => o.key === preset)?.label,
    [preset, value]
  );

  return (
    <WithFloatingTitle
      className="dropdown-input"
      title={`Date (${get_label(EXPENSE_DATE_PROP_OPTIONS[property])})`}
    >
      {modal}
      <input
        className="button fake-input"
        data-placeholder={preset === "all"}
        onClick={() => set_open(true)}
        readOnly={true}
        value={preset === "all" ? "Filter By Date" : range}
      />
      <Icon className="select__caret" name="disclosure" />
    </WithFloatingTitle>
  );
};
