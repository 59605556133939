import { User } from "@thrive-web/ui-api";
import {
  Avatar,
  SendSkeletonInviteButton,
  SendSkeletonLinkButton,
  SkeletonLinkProvider,
} from "@thrive-web/ui-components";
import { get_url_for_entity } from "@thrive-web/ui-utils";
import * as Preact from "preact";
import { useContext } from "preact/hooks";
import { get_user_community_role } from "~/utils";
import { ACTIVE_COMMUNITY, get_role_label } from "~/view/components";

export const CommMemberSuccess: Preact.FunctionComponent<{
  user: User | null;
}> = ({ user }) => {
  const comm = useContext(ACTIVE_COMMUNITY);
  if (!user || !comm) {
    return null;
  }
  return (
    <Preact.Fragment>
      <div className="member-add__success">
        <Avatar user={user} size="xl" isLink={true} />
        <h1>{user.full_name}</h1>
        <h5>{user.email}</h5>
        <p>{get_role_label(get_user_community_role(user, comm))}</p>
      </div>
      <div className="modal__footer">
        {user.firebase_uuid ? (
          <div className="modal__footer__right">
            <a
              href={`${get_url_for_entity(user)}#invite-group`}
              className="button filled gray"
            >
              Add to Group
            </a>
          </div>
        ) : (
          <div className="modal__footer__center">
            <SkeletonLinkProvider user={user}>
              <SendSkeletonLinkButton user={user} icon={false} />
              <SendSkeletonInviteButton user={user} icon={false} />
            </SkeletonLinkProvider>
            <a
              href={`${get_url_for_entity(user)}#invite-group`}
              className="button filled gray pill"
            >
              Add to Group
            </a>
          </div>
        )}
      </div>
    </Preact.Fragment>
  );
};
