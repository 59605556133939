import * as Preact from "preact";
import { useContext } from "preact/hooks";
import { NavSidebar } from "@thrive-web/ui-components";
import { THREAD_COMM_ID } from "@thrive-web/ui-constants";
import { ACTIVE_COMMUNITY, NAV_LINKS_THREAD } from "~/view/components";

export const NavSidebarAdmin: Preact.FunctionComponent<{
  navLinks: NavLinkSpec[];
}> = ({ navLinks }) => {
  const comm = useContext(ACTIVE_COMMUNITY);
  return (
    <NavSidebar
      navLinks={comm?.id === THREAD_COMM_ID ? NAV_LINKS_THREAD : navLinks}
    />
  );
};
